import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { LanguageService } from './service/language-service/language.service';
import { RatingService } from './service/rating-service/rating.service';
import { StoryService } from './service/story-service/story.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { environment } from '../environments/environment';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { PurchaseService } from './service/purchase-service/purchase.service';
import { NotificationService } from './service/notification-service/notification.service';
import { AuthService } from './service/auth-service/auth.service';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { TrainingService } from './service/training-service/training.service';
import { UtilsService } from './service/utils-service/utils.service';
import { StorageService } from './service/storage-service/storage.service';
import { WordService } from '@services/word-service/word.service';
import { ScreensizeService } from '@services/screensize-service/screensize.service';
import { Facebook } from '@awesome-cordova-plugins/facebook';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { ArticlePage } from '@pages/article/article.page';
import { ResetPasswordPage } from '@pages/reset-password/reset-password.page';
import { interval, timer } from 'rxjs';
import Bugsnag from '@bugsnag/js'
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { ThemeService } from '@services/theme-service/theme.service';
import { TrainingPage } from '@pages/training/training.page';
import { Storage } from '@ionic/storage';
import { ProfilePage } from '@pages/profile/profile/profile.page';
import { CreditCardPurchasePage } from '@pages/credit-card-purchase/credit-card-purchase.page';
import * as firebase from 'firebase/app';
import "firebase/remote-config";
import { OnboardingService } from '@services/onboarding-service/onboarding.service';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  signedIn: boolean;
  user: any;
  greeting: string;
  storyTitle: any;
  public navi = navigator;
  connection: string;

  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public languageService: LanguageService,
    public ratingService: RatingService,
    public storyService: StoryService,
    public screenOrientation: ScreenOrientation,
    public mobileAccessibility: MobileAccessibility,
    public firebaseAnalytics: FirebaseAnalytics,
    public purchaseService: PurchaseService,
    public notificationService: NotificationService,
    public authService: AuthService,
    private appsflyer: Appsflyer,
    public trainingService: TrainingService,
    public utilsService: UtilsService,
    private storageService: StorageService,
    private wordService: WordService,
    private screensizeService: ScreensizeService,
    private deeplinks: Deeplinks,
    private navController: NavController,
    private alertController: AlertController,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private themeService: ThemeService,
    public storage: Storage,
    public onboardingService: OnboardingService,
    public zone: NgZone
  ) { }

  ngOnInit(): void {
    this.initializeApp();
  }

  async initializeApp(): Promise<void> {
    this.themeService.initTheme();
    this.platform.ready().then(async () => {
      if (this.utilsService.isWebVersion) {
        this.purchaseService.getCountryIP();
      }
      this.authService.getUser().then(async () => {
        if (this.authService.anonymousUserId == 'undefined' || this.authService.anonymousUserId == null) await this.authService.getUserID();
        await this.purchaseService.checkPremium(this.authService.anonymousUserId, true).then((res) => {
          if (this.purchaseService.voucherUser) {
            this.purchaseService.makeUserPremium({})
          }
        });
        this.initPlatform();
        this.initDeeplinks();
        this.initStorage();
        this.initBugsnag();
      });
      this.utilsService.watchDate();
      this.screensizeService.onResize(this.platform.width());
      setTimeout(() => {
        this.notificationService.OneSignalInit();
      }, 2000);
      this.authService.newAccount = false

      if (this.utilsService.isWebVersion) {
        interval(30000).subscribe(() => {
          this.purchaseService.getRevenueCatSubscription();
        })
      }

      interval(600000).subscribe(() => {
        this.authService.checkUserDevice().then((res) => {
          console.log("validate", res)
          if (res.user?.stripeSubscriptionStatus == 'active' || res.user?.stripeSubscriptionStatus == 'trialing') {
            console.log("activate")
            this.purchaseService.makeUserPremium({})
          }
        }).catch((error => {
          console.log("eror", error)
          if (error.status == 400) {
            console.log("log out user")

            this.authService.logout();
            this.showForcedLogoutAlert();
          }
        }));
      })

      //firebase experiments
      if (!environment.single && !this.utilsService.isWebVersion) {
        let LangsterFB;
        if (this.platform.is('ios')) {
          LangsterFB = firebase.initializeApp({
            apiKey: "AIzaSyCKs3YfOy-xEAKcts3pg00SHg3BYi0BBTo",
            appId: "1:273038796504:ios:971938108b0c5d0ba019ad",
            projectId: "langster-production",
            databaseURL: "https://langster-production.firebaseio.com",
            storageBucket: "langster-production.appspot.com"
          }, 'Langster iOS');
        } else {
          LangsterFB = firebase.initializeApp({
            apiKey: "AIzaSyCnrQnLVCnJKA7Mx_0IK3kq5rrYsg64H_E",
            appId: "1:273038796504:android:76e40832adbb91f4a019ad",
            projectId: "langster-production",
            databaseURL: "https://langster-production.firebaseio.com",
            storageBucket: "langster-production.appspot.com"
          }, 'Langster Android');
        }
        console.log(LangsterFB)
        this.utilsService.remoteConfig = firebase.remoteConfig(LangsterFB)
        this.utilsService.remoteConfig.settings.minimumFetchIntervalMillis = 300000;
      }

      document.addEventListener('pause', () => {
        console.log('LISTENER: App is in the background 🤩');
      });

      document.addEventListener('resume', () => {
        console.log('LISTENER: App is in the foreground 🥸');
        this.trainingService.getTrainingSummary();
      });

      document.addEventListener("offline", () => {
        console.log("DEBUG: Offline", this.connection + this.utilsService.disconnected)
      });
      document.addEventListener("online", () => {
        if (this.utilsService.disconnected) {
          window.location.reload();
          this.utilsService.disconnected = false;
        }
      });

    });
  }

  async initPlatform(): Promise<void> {
    const platforms = this.platform.platforms();
    switch (true) {
      case platforms.includes('cordova'):
        await this.initNative();
        break;
      case platforms.includes('mobileweb'):
      case platforms.includes('desktop'):
        this.initWeb();
        break;
    }
  }

  initNative(): Promise<void> {
    this.firebaseAnalytics.setEnabled(true);
    Facebook.setAdvertiserTrackingEnabled(true).then(res => { }).catch(err => { });

    if (this.mobileAccessibility) this.mobileAccessibility.usePreferredTextZoom(false); // Deactivates custom font size
    this.screenOrientation.lock('portrait');
    return new Promise((resolve, reject) => {
      this.purchaseService.configurePurchasing().then(async () => {
        this.initAppsFlyer();
      }).catch(() => {
        this.initAppsFlyer();
      });
    });

  }

  initAppsFlyer(): void {
    if (this.purchaseService.user_EEA_perm && this.onboardingService.isActive) return
    window.plugins.appsFlyer?.setConsentData(AppsFlyerConsent.forNonGDPRUser());
    this.appsflyer.initSdk({
      devKey: environment.appsflyer.devKey,
      isDebug: true,
      appId: environment.appsflyer.appId,
      collectAndroidID: true,
      waitForATTUserAuthorization: 10
    }).then(async (data) => {
      this.appsflyer.getAppsFlyerUID().then(d => {
        this.purchaseService.setRevenuecatAppsflyerAttributes();
      })
    })

  }

  initWeb(): void {
    this.purchaseService.configurePurchasing();
    this.trackPageEvents();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }

  async initStorage() {
    this.ratingService.checkAppStarts();

    if (environment.single && environment.language == 'de' && !(await this.storageService.getIsReadleDataImported())) {
      await this.storageService.importReadleData();
      timer(100).subscribe(async () => {
        this.purchaseService.restorePurchase(false);
      });
    }

    await this.storageService.transferFromLocalStorage();
    this.languageService.checkLanguage().then(async (languageCode) => {

      this.wordService.loadWordSettings();
      this.storageService.sendRevenueCatAttributes();

      if (this.authService.token) {
        // 1. Validate token
        this.authService.validateToken().then(async (user) => {

          // 2. Load complete user data
          user = await this.authService.loadCompleteUserData(this.languageService.origin);
          this.authService.setUser(user);

          let openedStories = await this.storage.get('LangsterOpenedStories_' + this.languageService.origin);
          if (openedStories) openedStories = JSON.parse(openedStories);
          else openedStories = [];

          // 3. Check if user data needs to be imported from local storage
          if (user.data?.length == 0 && (this.storageService.favorites?.length > 0 || this.storageService.learnedStories?.length > 0 || openedStories.length > 0)) {


            if (!user.data || user.data?.length == 0 || (user.data && !user.data[0].training)) {
              if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
            }

            await this.authService.loadUserData();
            await this.storageService.initUserDataFromServer();
            return;
          }

          const convertedTrainingData = await this.storageService.getConvertedTrainingData();
          if (convertedTrainingData.length > 0) {
            if (!user.data || user.data?.length == 0 || (user.data && (!user.data[0].training || user.data[0].training?.length == 0))) {
              if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
            }
          }

          // Exsisting user that already have stored data on server and need to store the current state of training data
          if (!user.data || user.data?.length == 0 || (user.data && (!user.data.find(el => el.language == this.languageService.origin).training || user.data.find(el => el.language == this.languageService.origin).training?.length == 0))) {
            await this.storageService.getConvertedTrainingData();
          }
          await this.storageService.storeInitUserDataOnServer();
          if (!this.authService.user.trainingMigrationStart || this.authService.user.trainingMigrationStart == null) {
            setTimeout(async () => {
              await this.trainingService.migrateTrainingData();
            }, 50);
          }
          // 4. Use Data from Server
          this.storageService.initUserDataFromServer();

        }).catch(() => {
          // user token not valid -> comsider loggin out and display message to user
          console.log("USER_TOKEN_NOT_VALID")
        })
      }
      if (this.authService.user) this.firebaseAnalytics.setUserProperty("loggedin", 'true');
      else this.firebaseAnalytics.setUserProperty("loggedin", 'false');
    });

    this.ratingService.hasUserRated();
    this.purchaseService.hasUserShared();
    this.purchaseService.hasUserSharedUnlock();
    this.utilsService.checkAppearance();
    this.storageService.initUserSettings();
  }

  initDeeplinks() {
    console.log("initDeeplinks", JSON.stringify(this.deeplinks))
    this.deeplinks.route({
      '/article/:id': ArticlePage,
      '/article/:language/:id': ArticlePage,
      '/reset-password': ResetPasswordPage,
      '/training': TrainingPage,
      '/profile': ProfilePage,
      '/start-learning': CreditCardPurchasePage,
    }).subscribe(match => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      console.log("initDeeplinks", JSON.stringify(match.$link))

      if (match.$link.path == '/reset-password') {
        this.navController.navigateForward('/reset-password?token=${match.$args.token}');
        return;
      }
      if (match.$link.path == '/training') {
        this.navController.navigateForward('/training');
        return;
      }
      if (match.$link.path == '/profile') {
        this.navController.navigateForward('/profile');
        return;
      }
      if (match.$link.path == '/start-learning') {
        this.navController.navigateForward('/start-learning');
        return;
      }

      this.notificationService.nextStoryFree = true;
      this.navController.navigateForward(match.$link.path)
    }, nomatch => {
      // nomatch.$link - the full link data
      console.error('Got a deeplink that didn\'t match', nomatch);
    });
  }

  async showForcedLogoutAlert() {
    if (this.authService.isForcedLogoutModalShown) return;
    this.authService.isForcedLogoutModalShown = true;
    this.alertController.create({
      backdropDismiss: false,
      header: this.languageService.label.txt_settings_logout,
      message: this.languageService.label.txt_device_limit_reached_forced_logout_title,
      buttons: [
        {
          text: this.languageService.label.txt_device_limit_reached_logout_success_confirm,
          handler: () => {
            this.authService.isForcedLogoutModalShown = false;
            if (this.utilsService.isWebVersion) {
              // this.router.navigate(['/login']);
              // redirect to https://www.storytelling-app.com/login
              const lang = this.languageService.target.toLocaleLowerCase();

              switch (lang) {
                case 'fr':
                  window.location.href = 'https://langster.org/fr/';
                  break;
                case 'de':
                  window.location.href = 'https://langster.org/de/';
                  break;
                case 'en':
                  window.location.href = 'https://langster.org/en/';
                  break;
                case 'es':
                  window.location.href = 'https://langster.org/es/';
                  break;
                case 'ko':
                  window.location.href = 'https://langster.org/ko/';
                  break;
                case 'ja':
                  window.location.href = 'https://langster.org/ja/';
                  break;
                case 'uk':
                  window.location.href = 'https://langster.org/uk/';
                  break;
                case 'zh-hant':
                  window.location.href = 'https://langster.org/zh-hant/';
                  break;
                case 'zh-hans':
                  window.location.href = 'https://langster.org/zh-hans/';
                  break;
                default:
                  window.location.href = 'https://langster.org/en/';
              }
            }
          }
        }
      ]
    }).then(alert => {
      alert.present();
    })
  }

  initBugsnag() {
    Bugsnag.setUser(this.authService.anonymousUserId);
  }

  trackPageEvents() {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        if (item.url.includes('article/') && !item.url.includes('/memorize')) {
          const storyId = item.url.split('/');
          this.storyService.getStory(storyId[2]).subscribe(storyData => {
            this.storyTitle = storyData.titles[0].text;
            item.url = '/article/' + this.storyTitle.replace(/\s+/g, '-');
          });
        }
        setTimeout(() => {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
            external_ID: this.authService.anonymousUserId
          };
          this.gtmService.pushTag(gtmTag);
        }, 3000);
        this.storyTitle = "";
      }
    });
  }

}