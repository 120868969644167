import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
//import { AmplifyService } from 'aws-amplify-angular';
import { Userdata } from '../../../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import OneSignal from 'onesignal-cordova-plugin';
import { Platform } from '@ionic/angular';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { Device } from '@capacitor/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { v4 as uuidv4 } from 'uuid';
import { OpenedStories } from '../../interfaces/story-opened';

import {
  SignInWithApple,
  AppleSignInResponse,
  AppleSignInErrorResponse,
  ASAuthorizationAppleIDRequest
} from '@awesome-cordova-plugins/sign-in-with-apple/ngx';

const STORAGE_KEY_TOKEN = 'langster-token';
const STORAGE_KEY_USER = 'langster-user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public is_logged_in: boolean;
  public anonymousUser: any;
  public userdata: Userdata = {} as Userdata;
  public anonymousUserId: string | undefined;
  public signupMethod: string;
  public first_medium: string = '';
  public activeReport: boolean = false;
  public newAccount = false;
  public tmpErrToken: string;
  public tempEmail: string;
  // anonymous userId

  public user: any;
  public token: string;
  public isForcedLogoutModalShown: boolean = false;
  public webShopRegistration = false;
  public osTags: any;
  public fromChoosePlan: Boolean = false;
  public openedStoryList: OpenedStories[] = [];
  public couponDeepLink = false;
  public secondaryEmail = '';
  public createAccount = false;

  constructor(
    private storage: Storage,
    private http: HttpClient,
    private platform: Platform,
    private fb: Facebook,
    private firebaseAnalytics: FirebaseAnalytics,
    private googlePlus: GooglePlus,
    private signInWithApple: SignInWithApple,
    private gtmService: GoogleTagManagerService,
  ) {
    this.signupMethod = "social login";
  }

  async getUser() {
    await this.loadTokenFromStorage();
    await this.loadUserFromStorage();
    if (this.user) {
      return;
    }

    const fromStorage = await this.storage.get('langster-user-id');
    if (fromStorage) {
      this.anonymousUserId = fromStorage;
    } else {
      try {
        this.anonymousUserId = uuidv4();
        this.storage.set('langster-user-id', this.anonymousUserId);
      } catch (error) {
        return;
      }
    }

    if (this.platform.is("cordova")) {
      OneSignal.login(this.anonymousUserId)
    }

    return;
  }

  validateToken(): Promise<any> {
    return new Promise(async (resolve, reject) => {

      let deviceInfo;

      try {
        deviceInfo = await Device.getInfo();
      } catch (error) {
        console.log("Error getting device info", error);
      }

      this.http.post(`${environment.api}/user/token`, {
        deviceInfo: deviceInfo,
      }, { headers: this.authHeader }).subscribe((data) => {
        resolve(data);
      }, (err) => {
        reject(err);
      })
    })
  }

  loadUserData(): Promise<any> {
    return new Promise((result, reject) => {
      this.http.get(`${environment.api}/user/${this.user.userId}`, { headers: this.authHeader }).subscribe((data: any) => {
        this.user = data;
        console.log("load data opened", JSON.stringify(this.user))
        result(data);
      }, (err) => {
        reject(err);
      })
    })
  }

  loadCompleteUserData(language: string): Promise<any> {
    return new Promise((result, reject) => {
      if (!this.user?.userId) return reject("No user");
      var allTraining: boolean;
      if (this.user.trainingMigrated === true) {
        allTraining = false;
      } else {
        allTraining = true
      }
      this.http.get(`${environment.api}/user/${this.user.userId}?all=${allTraining}&language=${language}`, { headers: this.authHeader }).subscribe((data: any) => {
        this.user = data;
        result(data);
      }, (err) => {
        reject(err);
      })
    })
  }

  addOpenedStory(language: string, storyId: any) {
    const date = new Date();

    this.storage.get(`LangsterOpenedStories_${language}`).then((val) => {
      if (!storyId || !language) {
        console.log("error recording opened story")
        return;
      }
      const storiesOpenedData: OpenedStories = {
        "stories": [storyId],
        "date": date
      }
      if (val) {// has a openstories data
        this.openedStoryList = JSON.parse(val);
        console.log("opened1", this.openedStoryList)

        const openedStoriesObj = this.openedStoryList.find(el => {
          const eldate = new Date(el.date)
          return eldate.getFullYear() === date.getFullYear() &&
            eldate.getMonth() === date.getMonth() &&
            eldate.getDate() === date.getDate();
        });
        if (openedStoriesObj) {
          if (openedStoriesObj?.stories?.includes(storyId)) {
            return
          } else {
            openedStoriesObj?.stories?.push(storyId)
          }
        } else {
          this.openedStoryList.push(storiesOpenedData)
        }
      }
      else {
        this.openedStoryList = [{
          "stories": [storyId], "date": date
        }]
      }
      this.storage.set(`LangsterOpenedStories_${language}`, JSON.stringify(this.openedStoryList));
      console.log("opened2", this.openedStoryList)
      // this.storage.set(`LangsterOpenedStories_${language}`, null);
    })

  }


  async storeLearnersLanguage(code: string) {
  }

  public async getUserID() {
    await this.getUser();
    if (this.user?.userId) return this.user.userId;
    return this.anonymousUserId;
  }

  public setUserdata(obj) {
    this.userdata = obj;
  }

  public setToken(token: string) {
    this.token = token;
    this.storage.set(STORAGE_KEY_TOKEN, token);
  }

  public setUser(user: any) {
    console.log(user)
    this.user = user;
    this.storage.set(STORAGE_KEY_USER, JSON.stringify(user));
  }

  public setAnonymousUserId(id: string) {
    this.anonymousUserId = id;
    this.storage.set('langster-user-id', id);
  }

  public loadTokenFromStorage() {
    return new Promise((resolve, reject) => {
      this.storage.get(STORAGE_KEY_TOKEN).then((token) => {
        if (token) {
          this.token = token;
          resolve(this.token)
        } else resolve(undefined);
      });
    })
  }

  public loadUserFromStorage() {
    return new Promise((resolve, reject) => {
      this.storage.get(STORAGE_KEY_USER).then((user) => {
        if (user) {
          this.user = JSON.parse(user);
          resolve(this.user)
        } else resolve(undefined);
      });
    })
  }

  public loadUserFromServer(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api}/user/${id}`, {
        headers: this.authHeader
      }).subscribe(user => {
        resolve(user);
      }, err => {
        reject(err);
      })
    });
  }

  async logout() {

    let deviceInfo;

    try {
      deviceInfo = await Device.getInfo();
    } catch (error) {
      console.log("Error getting device info", error);
    }
    this.firebaseAnalytics.setUserProperty("loggedin", 'false');
    this.http.post(`${environment.api}/user/logout`, {
      user: this.user,
      deviceInfo: deviceInfo
    }, { headers: this.authHeader }).toPromise().catch((err) => {
      console.log("Error logging out", err);
      this.firebaseAnalytics.setUserProperty("loggedin", 'true');
    })

    this.user = null;
    this.token = '';
    this.storage.remove(STORAGE_KEY_TOKEN);
    this.storage.remove(STORAGE_KEY_USER);
    this.storage.remove("langster-user-id");
    this.anonymousUserId = null;
  }

  loginWithFacebook(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fb.login(['email']).then(async (res: any) => {
        this.loginWithFacebookToken(res.authResponse.accessToken).then(data => {
          this.signupMethod = "facebook-sso";
          resolve(data);
        }).catch(error => {
          reject(error);
        });
      }).catch(e => console.log('Error logging into Facebook', JSON.stringify(e)));
    });
  }

  loginWithFacebookToken(token: string): Promise<any> {
    return new Promise(async (resolve, reject) => {

      let deviceInfo: any = await Device.getInfo();
      deviceInfo = encodeURIComponent(JSON.stringify(deviceInfo));

      this.http.get<any>(`${environment.api}/auth/facebook/token?access_token=${token}&userId=${this.anonymousUserId}&deviceInfo=${deviceInfo}`).subscribe((data) => {
        if (data.token) this.setToken(data.token);
        if (data.user) {
          this.setUser(data.user);
          this.setAnonymousUserId(data.userId);
        }
        resolve(data);
      }, (error) => {
        reject(error);
      });
    });
  }

  loginWithGoogle() {
    return new Promise((resolve, reject) => {
      this.googlePlus.login({
        'webClientId': environment.googleAuthWebClientId
      }).then(res => {
        this.loginWithGoogleToken(res.idToken).then(data => {
          this.signupMethod = "google-sso";
          resolve(data)
        }).catch((error => {
          reject(error)
        }));
      }).catch(err => {
        console.log("DEBUG_AUTH_ERROR", JSON.stringify(err));
        reject()
      });
    })
  }

  loginWithGoogleToken(token: string) {
    return new Promise(async (resolve, reject) => {

      let deviceInfo: any = await Device.getInfo();
      deviceInfo = encodeURIComponent(JSON.stringify(deviceInfo));

      const urlExtention = environment.single ? `&slaOrigin=${environment.language}` : "";

      const header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      })
      this.http.get<any>(`${environment.api}/auth/google/token?access_token=${token}&userId=${this.anonymousUserId}&deviceInfo=${deviceInfo}${urlExtention}`, {
        headers: header
      }).subscribe((data) => {
        console.log("registered:", data);
        if (data.token) this.setToken(data.token);
        if (data.user) {
          this.setUser(data.user);
          this.setAnonymousUserId(data.userId);
        }
        resolve(data);
      }, (error) => {
        console.log(JSON.stringify(error));
        reject(error);
      });
    })
  }

  loginWithApple() {
    return new Promise(async (resolve, reject) => {

      let deviceInfo: any = await Device.getInfo();

      this.signInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
        ]
      })
        .then((res: AppleSignInResponse) => {
          // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
          //TODO:get the user ID from looking up the privaterelayid
          console.log("loginWithApple", JSON.stringify(res), this.anonymousUserId);
          this.http.post<any>(`${environment.api}/auth/apple`, {
            provider: 'apple',
            response: res,
            userId: this.anonymousUserId,
            deviceInfo: deviceInfo,
            slaOrigin: environment.single ? environment.language : undefined
          }).subscribe(data => {
            if (data.token) this.setToken(data.token);
            if (data.user) {
              this.setUser(data.user);
              this.setAnonymousUserId(data.userId);
            }
            this.signupMethod = "apple-sso";
            resolve(data);
          }, (err) => {
            reject(err)
          })

        })
        .catch((error: AppleSignInErrorResponse) => {
          reject();
        });
    });
  }

  storeUserData(userData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // rejcet when no user is logged in
      if (!this.user || !this.token) return resolve({});
      // send user data to server
      this.http.put<any>(`${environment.api}/user`, userData, {
        headers: this.authHeader
      }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    })
  }

  storeUserLanguageData(userData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // rejcet when no user is logged in
      if (!this.user || !this.token) return reject("No token available");
      // send user data to server
      this.http.put<any>(`${environment.api}/user/data`, userData, {
        headers: this.authHeader
      }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    })
  }

  successfullLogin(data: any) {
    this.setToken(data.token);
    this.setUser(data.user);
    this.setAnonymousUserId(data.anonymousUserId);
  }

  removeAccount() {
    return new Promise((resolve, reject) => {
      this.http.delete<any>(`${environment.api}/user`, {
        headers: this.authHeader
      }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    })
  }

  async checkUserDevice(): Promise<any> {

    return new Promise(async (resolve, reject) => {
      if (!this.token) return resolve({});

      let deviceInfo = await Device.getInfo();

      this.http.post<any>(`${environment.api}/user/devices/validate`, {
        deviceInfo: deviceInfo
      }, { headers: this.authHeader }).subscribe((data) => {
        resolve(data)
      }, (error) => {
        reject(error)
        return;
      });
    })
  }

  async addUserDevice(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.token) return resolve({});

      let deviceInfo = await Device.getInfo();

      this.http.post<any>(`${environment.api}/user/devices/add`, {
        deviceInfo: deviceInfo
      }, { headers: this.authHeader }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        reject(error)
      });
    })
  }

  async removeOldestDevice(tmpToken: string): Promise<any> {

    return new Promise((resolve, reject) => {
      const header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${tmpToken ? tmpToken : this.tmpErrToken}`,
      });

      this.http.post<any>(`${environment.api}/user/devices/removeOldest`, {}, { headers: header }).subscribe((data) => {
        resolve(data);
      }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  get authHeader() {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token ? this.token : environment.token}`,
    });
  }

  NewUserCheck() {
    setTimeout(() => { //wait for the user data
      //get date of when user was created
      const createdDate = new Date(this.user.createdAt);
      //get current date
      const date = new Date();
      //calc if the time difference is more than or within 10 minutes
      const timeDiff = Math.abs(createdDate.getTime() - date.getTime());
      if (timeDiff <= 600000) {
        //if it is within 10 minutes then the account was recently created, we sent the account created event to GA4
        console.log("apple new account", date);
        this.newAccount = true;
      } else console.log("apple old account", date);
    }, 300)
  }

  trackAccountCreated() {
    if (this.platform.is('cordova')) {
      if (this.user.email) OneSignal.User.addEmail(this.user.email);
      if (this.user.userId) OneSignal.login(this.user.userId);
    }
    //if first med already assigned, means this function was already called and prevents double sending of GA
    if (this.first_medium == 'web' || this.first_medium == 'app') return
    if (!this.platform.is('cordova')) {
      this.first_medium = 'web';
    } else {
      this.first_medium = 'app';
    }
    setTimeout(() => { //wait for the user data
      //get date of when user was created
      const createdDate = new Date(this.user.createdAt);
      //get current date
      const date = new Date();
      //calc if the time difference is more than or within 10 minutes
      const timeDiff = Math.abs(createdDate.getTime() - date.getTime());
      if (timeDiff <= 600000) {
        //if it is within 10 minutes then the account was recently created, we sent the account created event to GA4
        if (!this.platform.is("cordova")) {
          console.log(this.user);
          const gtmTag = {
            event: 'account_created',
            category: 'signup completed',
            action: this.signupMethod,
          }
          this.gtmService.pushTag(gtmTag);
        }
        else this.firebaseAnalytics.logEvent("account_created", { method: this.signupMethod });
        this.newAccount = true;
        console.log("apple trackAccountCreated", date, this.newAccount);
      }
    }, 300)
  }
}
