import { Injectable } from '@angular/core';
import { TrainingStack, TrainingWord, TrainingDeck, TrainingDeckSummary, DeckCard, TrainingDifficulty } from '../../interfaces/training';
import { Storage } from '@ionic/storage';
import { AuthService } from '../auth-service/auth.service';
import { PurchaseService } from '../purchase-service/purchase.service';
import Word from '@interfaces/word';
import { LanguageService } from '@services/language-service/language.service';
import { Dialogue, WordResultMap } from '@interfaces/story';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { WordService } from '@services/word-service/word.service';
import { MemorizeService } from '@services/memorize-service/memorize.service';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  public stack: TrainingStack = {
    words: []
  } as TrainingStack;
  public deck: TrainingDeck = {
    cards: []
  } as TrainingDeck;
  public deckOverview: DeckCard[] = []; // Used for the overview page
  public deckMemOverview: DeckCard[] = []; // Used for the overview page
  public deckSummary: TrainingDeckSummary;
  public deckID: string;
  public DeckDue: DeckCard[] = [];
  public overviewLoading: boolean = true;
  public dueExerciseTotal: number;

  public stackToday: TrainingWord[] = [];
  public stackFuture: TrainingWord[] = [];

  public stackOverview: TrainingWord[] = []; // Used for the overview page
  public overviewWords: any = [];
  public overviewTitle = '';
  public overviewDifficulty: string = 'easy';

  public stackNumberOfNew = 0;
  public stackNumberOfHard = 0;
  public stackNumberOfGood = 0;
  public stackNumberOfEasy = 0;
  public stackNumberOfDone = 0;

  public page = 1;
  public Mempage = 1;
  public lastPage = false;
  public summaryTimeStamp: Date;

  public trainingWord: TrainingWord = {} as TrainingWord;
  public trainingCard: DeckCard = {} as DeckCard;
  public wordData: Word = {} as Word;
  public evaluated = false;
  public cleaned = false;
  public loadingDue = false;
  public showTrainingHelp = false;
  public SRSPointsModal = false;
  public toggleTrainingKeywordsObserver: Subject<any> = new Subject<any>();
  public addTrainingObserver: Subject<any> = new Subject<any>();
  public triggeredMigrate = false;
  public gettingMemDeck = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    public storage: Storage,
    public authService: AuthService,
    public purchaseService: PurchaseService,
    private languageService: LanguageService,
    private wordService: WordService,
    private memorizeService: MemorizeService,
    private onboardingService: OnboardingService,
    private http: HttpClient
  ) {
    this.languageService.watchOrigin.subscribe((origin) => {
      this.resetStack();
      this.getStack();
    })
  }

  async getStack(): Promise<void> {
    if (!this.authService.user) {
      await this.storage.get(`LANGSTER_TRAINING_STACK_${this.languageService.origin}`).then((val) => {
        if (val == null) {
          this.resetStack();
          return;
        }
        this.stack = JSON.parse(val);
        this.sortStack(this.stack);
      });
    } else {
      if (!this.languageService.origin) return
      await this.http.get<any>(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/asWordIdList`, {
        headers: this.authService.authHeader
      }).subscribe(async (res: string[]) => {
        // this.stack = res;
        this.stack = {
          words: []
        } as TrainingStack;
        this.stack.words = res.map((wordId) => {
          return {
            id: wordId,
            show: new Date(), // Dummy data, current date
            _id: wordId
          };
        });
      });
    }
    return;
  }

  public resetStack(): void {
    this.stack = {
      words: []
    } as TrainingStack;

    this.stackToday = [];
    this.stackFuture = [];
    this.stackNumberOfNew = 0;
    this.stackNumberOfDone = 0;
    this.stackNumberOfEasy = 0;
    this.stackNumberOfGood = 0;
    this.stackNumberOfHard = 0;
    this.deckOverview = []; // Used for the overview page
    this.deckMemOverview = [];
    this.deckSummary = null;
    this.deckID = null;
    this.DeckDue = [];
  }

  public prepareTrainingWordForServer(trainingWord: TrainingWord) {
    const data = {};
    data['position'] = trainingWord.position || 0;
    data['show'] = trainingWord.show;
    data['word'] = trainingWord.word?._id;
    data['storyId'] = trainingWord.storyId;
    data['totalPosition'] = trainingWord.totalPosition || 0;
    if (trainingWord.difficulty) data['difficulty'] = this.convertDifficultyToNumber(trainingWord.difficulty);
    return data;
  }

  private sortStack(stack: TrainingStack): void {
    let empties = []
    this.stackToday = []
    for (let index = 0; index < stack.words?.length; index++) {
      const element = stack.words[index];
      if (this.checkDates(element.show) || element.difficulty == 'hard') {
        this.stackToday.push(element);
      } else {
        this.stackFuture.push(element);
      }
      this.countStack(element);
      this.stackNumberOfNew = this.stackToday.length;
      if (!element.word) {
        empties.push(element._id)
        this.cleaned = true;
      }
    }

    if (empties.length > 0) this.removeTrainingEmptiesDataFromServer(empties)
    return;
  }

  public convertDifficultyToNumber(difficulty: string): number {
    switch (difficulty) {
      case TrainingDifficulty.easy:
        return 1;
      case TrainingDifficulty.good:
        return 2;
      case TrainingDifficulty.hard:
        return 3;
      case TrainingDifficulty.done:
        return 4;
    }
  }

  public convertNumberToDifficulty(difficulty: number): string {
    switch (difficulty) {
      case 1:
        return TrainingDifficulty.easy;
      case 2:
        return TrainingDifficulty.good;
      case 3:
        return TrainingDifficulty.hard;
      case 4:
        return TrainingDifficulty.done;
    }
  }


  public async addToStack(id: string, sentence?: WordResultMap[], position?: number, storyId?: string, word?: Word, totalPosition?: number, updateServer = true, dialogueContent?: Dialogue, dialogueIndex?: number): Promise<TrainingWord> {

    const trainingWord = {} as TrainingWord;
    trainingWord.id = id;
    if (sentence) trainingWord.sentence = sentence;
    if (position) trainingWord.position = position || -1;
    if (storyId) trainingWord.storyId = storyId;
    if (word) trainingWord.word = word;
    if (totalPosition) trainingWord.totalPosition = totalPosition || -1;
    if (dialogueContent) trainingWord.dialogue = dialogueContent;
    if (dialogueIndex) trainingWord.contentIndex = dialogueIndex;
    if (dialogueIndex == 0) trainingWord.contentIndex = 0;
    const inStack = this.existsInStack(id);
    if (inStack > 0) {
      this.stack.words.splice(<number>inStack - 1, 1);
      if (!this.authService.user) {
        this.reduceStack(trainingWord);
        this.saveStack();
      } else {
        this.deleteDeckCard(id);
      }
      this.removeFromStackToday(trainingWord);

      if (this.authService.token && updateServer) {
        this.removeTrainingDataFromServer(trainingWord.id);
      }

      this.getStack();
      return trainingWord;
    }

    trainingWord.show = new Date();
    console.log(trainingWord.word)
    if (this.authService.user) {
      try {
        if (dialogueContent != null && dialogueIndex != null) {
          this.createDialogueDeckCard(trainingWord.id, trainingWord.storyId, this.languageService.origin, position, totalPosition, dialogueIndex);
        } else if (dialogueContent == null && dialogueIndex != null) {
          this.createShortStoryDeckCard(trainingWord.id, storyId, this.languageService.origin, position, totalPosition, dialogueIndex);
        } else {
          this.createStoryDeckCard(trainingWord.id, storyId, this.languageService.origin, position, totalPosition);
        }
        this.deckSummary.due++;
      } catch (error) {
        await this.getTrainingDeckfromServer();
        try {
          if (dialogueContent != null && dialogueIndex != null) {
            this.createDialogueDeckCard(trainingWord.id, trainingWord.storyId, this.languageService.origin, position, totalPosition, dialogueIndex);
          } else if (dialogueContent == null && dialogueIndex != null) {
            this.createShortStoryDeckCard(trainingWord.id, storyId, this.languageService.origin, position, totalPosition, dialogueIndex);
          } else {
            this.createStoryDeckCard(trainingWord.id, storyId, this.languageService.origin, position, totalPosition);
          }
        } catch (error) {
          console.error("Error creating deck card:", error);
          alert('error adding to training, please restart and try again')
        }
      }
      this.getStack();
    } else {
      trainingWord.show = new Date();
      this.stack.words.push(trainingWord);
      this.stackToday.push(trainingWord);
      this.stackNumberOfNew++;
      this.saveStack();
      return trainingWord;
    }

    return trainingWord;
  }

  public removeFromStack(trainingWord) {
    let posInStack = this.stack.words.findIndex(word => word.id === trainingWord.id);
    console.log("pos in stack: " + posInStack)
    this.stack.words.splice(posInStack, 1);

    posInStack = this.stackToday.findIndex(word => word.id === trainingWord.id);
    console.log("pos in today: " + posInStack)
    if (posInStack > -1) this.stackToday.splice(posInStack, 1);

    posInStack = this.stackFuture.findIndex(word => word.id === trainingWord.id);
    if (posInStack > -1) this.stackFuture.splice(posInStack, 1);
    console.log("pos in fut: " + posInStack)
    this.saveStack();

  }

  public getRandomWord(): TrainingWord {
    const word = this.stackToday.shift();
    console.log("stack today", word)
    return word;
  }
  public getRandomCard(): DeckCard {
    const word = this.DeckDue.shift();
    console.log("card today", this.DeckDue, word)
    if (word == undefined || word == null) {
      alert("An error occurred during the training, please try contact support at support@langster.org")
      return null;
    }
    return word;
  }

  public countStack(trainingWord: TrainingWord): void {
    switch (trainingWord.difficulty) {
      case 'hard':
        this.stackNumberOfHard++;
        this.stackNumberOfNew++;
        break;
      case 'good':
        this.stackNumberOfGood++;
        break;
      case 'easy':
        this.stackNumberOfEasy++;
        break;
      case 'again':
        this.stackNumberOfDone++;
        break;
      default:
        this.stackNumberOfNew++;
        break;
    }
  }

  public reduceStack(trainingWord: TrainingWord): void {
    switch (trainingWord.difficulty) {
      case 'hard':
        if (this.stackNumberOfHard > 0) this.stackNumberOfHard--;
        break;
      case 'good':
        if (this.stackNumberOfGood > 0) this.stackNumberOfGood--;
        break;
      case 'easy':
        if (this.stackNumberOfEasy > 0) this.stackNumberOfEasy--;
        break;
      case 'again':
        if (this.stackNumberOfDone > 0) this.stackNumberOfDone--;
        break;
      default:
        if (this.stackNumberOfNew > 0) this.stackNumberOfNew--;
        break;
    }
  }

  private removeFromStackToday(trainingWord: TrainingWord): void {
    for (let i = 0; i < this.stackToday.length; i++) {
      if (this.stackToday[i].id == trainingWord.id) {
        this.stackToday.splice(i, 1);
        this.stackNumberOfNew = this.stackToday.length;
        break;
      }
    }
  }

  public saveStack(reloadData = false, newData?: any[]): void {
    const newStack = {} as TrainingStack;
    newStack.userId = <any>this.authService.getUserID;
    newStack.words = this.stack.words;

    if (newData) {
      newStack.words = newData;
    }

    if (this.trainingWord && this.trainingWord.id && !this.evaluated) newStack.words.push(this.trainingWord);

    this.storage.set(`LANGSTER_TRAINING_STACK_${this.languageService.origin}`, JSON.stringify(newStack)).then(() => {
      if (reloadData) {
        this.stackToday = [];
        this.stackFuture = [];
        this.stackNumberOfDone = 0;
        this.stackNumberOfEasy = 0;
        this.stackNumberOfGood = 0;
        this.stackNumberOfHard = 0;
        this.stackNumberOfNew = 0;
        this.trainingWord = {} as TrainingWord;
        this.wordData = {} as Word;
        this.getStack();
      }
    });
    return;
  }

  public addToStackTodayFuture(trainingWord: TrainingWord, difficulty: string, days: number, addToToday: boolean): void {
    if (trainingWord.difficulty == 'hard') this.stackNumberOfHard--;

    if (trainingWord.difficulty == 'easy' && difficulty == 'good') this.stackNumberOfEasy--;
    if (trainingWord.difficulty == 'easy' && difficulty == 'hard') this.stackNumberOfEasy--;
    if (trainingWord.difficulty == 'easy' && difficulty == 'again') this.stackNumberOfEasy--;
    if (trainingWord.difficulty == 'easy' && difficulty == 'easy') this.stackNumberOfEasy--;

    if (trainingWord.difficulty == 'good' && difficulty == 'hard') this.stackNumberOfGood--;
    if (trainingWord.difficulty == 'good' && difficulty == 'easy') this.stackNumberOfGood--;
    if (trainingWord.difficulty == 'good' && difficulty == 'again') this.stackNumberOfGood--;
    if (trainingWord.difficulty == 'good' && difficulty == 'good') this.stackNumberOfGood--;

    if (trainingWord.difficulty == 'again' && difficulty == 'hard') this.stackNumberOfDone--;
    if (trainingWord.difficulty == 'again' && difficulty == 'easy') this.stackNumberOfDone--;
    if (trainingWord.difficulty == 'again' && difficulty == 'good') this.stackNumberOfDone--;
    if (trainingWord.difficulty == 'again' && difficulty == 'again') this.stackNumberOfDone--;

    trainingWord.difficulty = difficulty;
    if (addToToday) {
      this.stackToday.push(trainingWord);
      this.stackNumberOfHard++;
    }
    else {
      trainingWord.show = this.addDays(new Date(), days);
      this.stackFuture.push(trainingWord);
      if (this.stackNumberOfNew > 0) this.stackNumberOfNew--;
      this.countStack(trainingWord);
    }

    // if (this.authService.token) {
    //   const data = this.prepareTrainingWordForServer(trainingWord);
    //   this.updateTrainingDataOnServer(data);
    // }

    this.saveStack();
  }

  private addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private checkDates(someDate): boolean {
    const dateToCheck = new Date(someDate);
    const today = new Date();
    return (this.isToday(someDate) || (dateToCheck < today)); // true when date is today or in the past
  }

  private isToday(someDate): boolean {
    someDate = new Date(someDate);
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  public existsInStack(id: string): boolean | number {
    let found = false;
    let position = 0;
    for (let i = 0; i < this.stack.words?.length; i++) {
      if (this.authService.user) {
        if (this.stack.words[i]?._id == id) {
          found = true;
          position = i;
          break;
        }
      } else {
        if (this.stack.words[i]?.word?._id == id) {
          found = true;
          position = i;
          break;
        }
      }
    }
    if (found) {
      return position + 1;
    }
    else return found;
  }


  public deleteTrainingDeckfromServer(deckID: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${deckID}`, {
        headers: this.authService.authHeader
      }).subscribe((res) => {
        console.log("training data deletdd", res, this.deckID)
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public createTrainingDeck(lang: string) {
    console.log("create deck", this.deckID, lang)
    if (!this.authService.user || this.deckID) return;
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/training/${this.authService.user.userId}/${lang}/decks`, {
        "name": "default", // Name of the deck, required
        "description": "Default deck" // Description of the deck, optional
      }, {
        headers: this.authService.authHeader
      }).subscribe((res) => {
        console.log("default deck", res)
        this.deckID = res as string;
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  public async getTrainingDeckfromServer() {
    if (!this.authService.user || !this.languageService.origin) return;
    if (this.deckID) return;
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks`, {
        headers: this.authService.authHeader
      }).subscribe(async (res) => {
        if (res.length == 0) {
          const langData = this.authService.user.data.find((el: any) => el.language == this.languageService.origin);
          console.log('no deck found', langData?.training?.length, this.authService.user)

          if (this.authService.user.trainingMigrationEnd || langData?.training?.length == 0 || langData?.length == 0 || langData == null) {
            if (!this.triggeredMigrate && !this.onboardingService.isActive) await this.createTrainingDeck(this.languageService.origin);
          } else if (langData?.training?.length > 0 && this.authService.user.trainingMigrated == false) {
            setTimeout(async () => {
              if (!this.triggeredMigrate && !this.onboardingService.isActive) await this.migrateTrainingData()
            }, 100);
          }
        } else {
          if (res.length > 1) {
            let deck1: any, deck2: any;
            this.deckID = res[0]._id;
            deck1 = await this.getTrainingDeckAll()
            this.deckID = res[1]._id;
            deck2 = await this.getTrainingDeckAll()
            console.log('compare deck', JSON.stringify(deck1), JSON.stringify(deck2))
            if (deck1.length > deck2.length) {
              this.deckID = res[0]._id;
              this.deleteTrainingDeckfromServer(res[1]._id)
            } else {
              this.deckID = res[1]._id;
              this.deleteTrainingDeckfromServer(res[0]._id)
            }
          } else {
            this.deckID = res[0]._id;
          }
          this.getTrainingSummary();
          if (!this.deckID && !this.triggeredMigrate) {
            setTimeout(async () => {
              await this.migrateTrainingData()
            }, 200);
            return;
          }
        }
        resolve(res);
      }, (err) => {

        reject(err);
      });
    })
  }

  public getTrainingDeckAll() {
    if (!this.authService.user) return;
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${this.deckID}/cards?all=true`, {
        headers: this.authService.authHeader
      }).subscribe((res) => {
        this.deck = res
        console.log("training data all", this.deck)
        resolve(res);
      }, async (err) => {
        if (!this.deckID) {
          console.log("getTrainingDeckfromServer getTrainingDeckAll")
          await this.getTrainingDeckfromServer();
          this.getTrainingDeckAll();
          return
        }
        reject(err);
      });
    })
  }

  public async getTrainingSummary() {
    if (!this.authService.user) return;
    if (!this.deckID) {
      console.log("getTrainingDeckfromServer summary")
      await this.getTrainingDeckfromServer();
      return
    }
    const dateNow = new Date();
    if (this.summaryTimeStamp && dateNow.getTime() - this.summaryTimeStamp.getTime() < 1000 * 60) {
      console.log("getTrainingSummary too soon")
      return;
    }
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${this.deckID}/summary`, {
        headers: this.authService.authHeader
      }).subscribe((res) => {
        this.summaryTimeStamp = new Date();
        this.deckSummary = res
        console.log(this.deckSummary)
        resolve(res);
      }, async (err) => {
        console.log("getTrainingDeckfromServer summary error")
        await this.getTrainingDeckfromServer();
        reject(err);
      });
    })
  }

  public getTrainingDeckToday() {
    return new Promise(async (resolve, reject) => {
      if (!this.authService.user || this.loadingDue) return;
      if (!this.deckID || !this.languageService.origin) {
        setTimeout(async () => {
          await this.getTrainingDeckfromServer();
          this.getTrainingDeckToday();
        }, 2000);
        return
      }
      this.loadingDue = true;
      this.http.get<any>(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${this.deckID}/cards?pageSize=10`, {
        headers: this.authService.authHeader
      }).subscribe((res) => {
        if (res.data.length == 0) {
          this.loadingDue = false;
          setTimeout(() => {
            this.summaryTimeStamp = null;
            this.getTrainingSummary();
          }, 200);
          return;
        }
        this.DeckDue = [];
        res.data.forEach(element => {
          if (this.trainingCard && element.wordId == this.trainingCard.wordId) {
            console.log("element", element.word, this.trainingCard.word, res.data)
            //prevent the current shown word from being fetched again
            return
          }
          this.DeckDue.push(element)
        });
        this.loadingDue = false;
        console.log("training data for today", res.data, this.DeckDue)
        this.dueExerciseTotal = res.total;
        resolve(res);
      }, async (err) => {
        if (!this.deckID) {
          console.log("getTrainingDeckfromServer getTrainingDeckToday")
          await this.getTrainingDeckfromServer();
          this.getTrainingDeckToday();
          reject(err);
        }
      });
    })
  }

  public getMemorizeDeckByCategory(category: string, ev?: { target: { complete: () => void; }; }) {
    console.log('getMemorizeDeckByCategory')
    if (!this.authService.user || !category) return;
    if (!this.deckID || !this.languageService.origin) {
      setTimeout(async () => {
        await this.getMemorizeDeckByCategory(category);
      }, 1000);
      return
    }
    return new Promise((resolve, reject) => {
      console.log('getMemorizeDeckByCategory', this.Mempage)
      this.gettingMemDeck = true;
      this.http.get<any>(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${this.deckID}/cards?category=${category}&page=${this.Mempage}`, {
        headers: this.authService.authHeader
      }).subscribe((res) => {
        if (ev) ev.target.complete();
        this.deckMemOverview = []
        res.data.forEach(async element => {
          if (!element.word && element.wordId) element.word = await this.wordService.getWordData(element.wordId);
          if (!element.wordId) return;
          if (this.memorizeService.trainingWord && element.wordId == this.memorizeService.trainingWord.wordId) {
            return
          }
          element.word.inTraining = true;
          this.deckMemOverview.push(element)
        });
        this.gettingMemDeck = false;
        console.log("training data", res.data.length, this.Mempage)
        if (res.data.length == 20) this.Mempage++;
        if (res.data.length < 20) {
          this.lastPage = true;
        }
        console.log("training data", res.data, res.data.length, this.deckMemOverview, this.lastPage, this.Mempage)
        this.overviewLoading = false;
        resolve(res);
      }, async (err) => {
        this.overviewLoading = false;
        await this.getTrainingDeckfromServer();
        this.getMemorizeDeckByCategory(category);
        reject(err);
      });
    })
  }

  public getTrainingDeckByCategory(category: string, ev?: { target: { complete: () => void; }; }) {
    if (!this.authService.user || !category) return;
    if (!this.deckID || !this.languageService.origin) {
      setTimeout(async () => {
        await this.getTrainingDeckByCategory(category);
      }, 1000);
      return
    }
    if (this.page == 1) this.overviewLoading = true;
    if (this.deckSummary[category] == 0) this.overviewLoading = false;
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${this.deckID}/cards?category=${category}&page=${this.page}`, {
        headers: this.authService.authHeader
      }).subscribe((res) => {
        if (ev) ev.target.complete();
        if (this.page == 1) this.deckOverview = []
        res.data.forEach(async element => {
          if (!element.word && element.wordId) element.word = await this.wordService.getWordData(element.wordId);
          if (!element.wordId) return;
          element.word.inTraining = true;
          this.deckOverview.push(element)
        });
        if (res.data.length == 20) this.page++;
        if (res.data.length < 20) {
          this.page = 1;
          this.lastPage = true;
        }
        this.overviewLoading = false;
        resolve(res);
      }, async (err) => {
        this.overviewLoading = false;
        await this.getTrainingDeckfromServer();
        this.getTrainingDeckByCategory(category);
        reject(err);
      });
    })
  }
  public createDialogueDeckCard(wordID: string, storyID: string, lang: string = this.languageService.origin, position: number, totalPosition: number, contentPosition: number) {
    if (!this.authService.user || !lang) return;
    if (!this.deckID) {
      setTimeout(async () => {
        await this.createDialogueDeckCard(wordID, storyID, lang, position, totalPosition, contentPosition);
      }, 1000);
      return
    }
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/training/${this.authService.user.userId}/${lang}/decks/${this.deckID}/cards`, {
        "wordId": wordID, // Id of the word to learn
        "dialogueId": storyID, // Id of the story to learn
        "contentType": 'Dialogue', // Type of the content to learn, one of Story, ShortStory, Dialogue
        "position": position, // Position in sentence
        "totalPosition": totalPosition, // Position in the content type
        "contentPosition": contentPosition
      },
        {
          headers: this.authService.authHeader
        }).subscribe((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    })
  }

  public createShortStoryDeckCard(wordID: string, storyID: string, lang: string = this.languageService.origin, position: number, totalPosition: number, contentPosition: number) {
    if (!this.authService.user) return;
    if (!this.deckID) {
      setTimeout(async () => {
        await this.createShortStoryDeckCard(wordID, storyID, lang, position, totalPosition, contentPosition);
      }, 1000);
      return
    }
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/training/${this.authService.user.userId}/${lang}/decks/${this.deckID}/cards`, {
        "wordId": wordID, // Id of the word to learn
        "shortStoryId": storyID, // Id of the story to learn
        "contentType": 'ShortStory', // Type of the content to learn, one of Story, ShortStory, Dialogue
        "position": position, // Position in sentence
        "totalPosition": totalPosition, // Position in the content type
        "contentPosition": contentPosition
      },
        {
          headers: this.authService.authHeader
        }).subscribe((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    })
  }

  public createStoryDeckCard(wordID: string, storyID: string, lang: string = this.languageService.origin, position: number, totalPosition: number,) {
    if (!this.authService.user) return;
    if (!this.deckID) {
      setTimeout(async () => {
        await this.createStoryDeckCard(wordID, storyID, lang, position, totalPosition);
      }, 1000);
      return
    }
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/training/${this.authService.user.userId}/${lang}/decks/${this.deckID}/cards`, {
        "wordId": wordID, // Id of the word to learn
        "storyId": storyID, // Id of the story to learn
        "contentType": 'Story', // Type of the content to learn, one of Story, ShortStory, Dialogue
        "position": position, // Position in sentence
        "totalPosition": totalPosition, // Position in the content type
      },
        {
          headers: this.authService.authHeader
        }).subscribe((res) => {
          console.log("save card", res)
          resolve(res);
        }, (err) => {
          reject(err);
        });
    })
  }
  // public createDeckCard(wordID: string, storyID: string, lang: string = this.languageService.origin) {
  //   console.log("create deck card", wordID, storyID, lang)
  //   if (!this.authService.user) return;
  //   if (!this.deckID) {
  //     setTimeout(async () => {
  //       await this.createDeckCard(wordID, storyID, lang);
  //     }, 1000);
  //     return
  //   }
  //   return new Promise((resolve, reject) => {
  //     this.http.post(`${environment.api}/training/${this.authService.user.userId}/${lang}/decks/${this.deckID}/cards`, {
  //       "wordId": wordID, // Id of the word to learn
  //       "storyId": storyID, // Id of the story to learn
  //       "contentType": 'Story', // Type of the content to learn, one of Story, ShortStory, Dialogue
  //       "position": 0, // Position in sentence
  //       "totalPosition": 0, // Position in the content type
  //     },
  //       {
  //         headers: this.authService.authHeader
  //       }).subscribe((res) => {
  //         this.getTrainingSummary()
  //         const temp = this.deck.cards.findIndex(card => card.wordId == wordID)
  //         if (temp) {
  //           console.log("card already exists", temp)
  //           return;
  //         }
  //         console.log("save card", res)
  //         resolve(res);
  //       }, (err) => {
  //         reject(err);
  //       });
  //   })
  // }

  public updateDeckCard(wordID: string, score: string) {
    if (!this.authService.user || !wordID) return;

    return new Promise((resolve, reject) => {
      this.http.put(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${this.deckID}/cards/${wordID}`, {
        "score": score // Id of the story to learn
      },
        {
          headers: this.authService.authHeader
        }).subscribe((res) => {
          resolve(res);
        }, (err) => {
          console.log("update card error", err)
          reject(err);
        });
    })
  }

  public deleteDeckCard(wordID: string) {
    if (!this.authService.user || !wordID) return;
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${this.deckID}/cards/${wordID}`,
        {
          headers: this.authService.authHeader
        }).subscribe((res) => {
          console.log("delete card", res)
          this.summaryTimeStamp = null;//bypass the cooldown to fetch newest summary
          this.getTrainingSummary()
          resolve(res);
        }, (err) => {
          reject(err);
        });
    })
  }

  public deleteDeckCategory(category: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.api}/training/${this.authService.user.userId}/${this.languageService.origin}/decks/${this.deckID}/cards?category=${category}`,
        {
          headers: this.authService.authHeader
        }).subscribe((res) => {
          console.log("delete deck", res)
          resolve(res);
        }, (err) => {
          reject(err);
        });
    })
  }

  public async migrateTrainingData() {
    this.storage.get('trainingMigrated').then(val => {
      if (val != null) return;
    });;
    if (this.authService.user.trainingMigrationStart || this.authService.user.trainingMigrated || this.triggeredMigrate) return;
    return new Promise<void>((resolve, reject) => {
      this.triggeredMigrate = true;
      this.storage.set('trainingMigrated', true);
      this.http.post(`${environment.api}/training/${this.authService.user.userId}/migrate`, {}, {
        headers: this.authService.authHeader
      }).subscribe(() => {
        console.log("migrated", JSON.stringify(this.authService.user.data));
        if (this.authService.user.trainingMigrated == false && window.location.href.includes('training')) this.purchaseService.loading = true
        this.waitForMigrationToEnd();
        resolve();
      }, (err) => {
        reject(err);
      });
    });

  }
  async waitForMigrationToEnd() {
    return new Promise((resolve) => {
      const intervalCheck = setInterval(async () => {
        console.log("check end migration", this.authService.user.trainingMigrationEnd?.length);
        if (window.location.href.includes('training') && this.purchaseService.loading == false) this.purchaseService.loading = true
        await this.authService.loadCompleteUserData(this.languageService.origin);
        this.authService.setUser(this.authService.user);
        if (this.authService.user.trainingMigrationEnd?.length > 0) {
          if (this.authService.user.trainingMigrated) {
            await this.getTrainingDeckfromServer();
            setTimeout(async () => {
              this.summaryTimeStamp = null;//bypass the cooldown to fetch newest summary
              this.getTrainingSummary();
              this.purchaseService.loading = false;
            }, 200)
          } else {
            alert("An error occurred during migration to the new system, please try contact support at support@langster.org")
          }
          this.triggeredMigrate = false;
          clearInterval(intervalCheck);
          resolve(true);
        }
      }, 500);
    });
  }
  public removeTrainingDataFromServer(id: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/user/training/remove`, {
        language: this.languageService.origin,
        id: id
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public updateTrainingDataOnServer(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/user/training/update`, {
        language: this.languageService.origin,
        training: data
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public addTrainingBatchDataToServer(data: any[]) {
    return new Promise((resolve, reject) => {

      if (!this.authService.token) return reject();

      this.http.post(`${environment.api}/user/training/addBatch`, {
        language: this.languageService.origin,
        training: data
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public removeTrainingBatchDataFromServer(ids: any[]) {
    return new Promise((resolve, reject) => {
      if (ids.length == 0) return reject();
      this.http.post(`${environment.api}/user/training/removeBatch`, {
        language: this.languageService.origin,
        training: ids
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public removeTrainingEmptiesDataFromServer(_ids: any[]) {
    return new Promise((resolve, reject) => {
      if (_ids.length == 0) return reject();
      this.http.post(`${environment.api}/user/training/removeBatchObjects`, {
        language: this.languageService.origin,
        training: _ids
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

}
