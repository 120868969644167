<ion-content scroll-y="false" style="position: relative" [ngClass]="{'left-sided': isLandscape}">

  <div *ngIf="showProgressbar && onboardingService.isActive" class="loader-wrapper" id="progress">
    <div id="progress-content">
      <div class="loader-progress-wrapper" style="position: relative;width: fit-content;margin: 0 auto;">
        <div class="progress-status">{{this.progressbarStatus}} %</div>
        <circle-progress [radius]="52" [space]="-10" [percent]="this.progressbarStatus" [outerStrokeWidth]="10"
          [outerStrokeColor]="'var(--ion-color-primary)'" [outerStrokeGradientStopColor]="'#53a9ff'"
          [innerStrokeColor]="'#6467BA20'" [innerStrokeWidth]="10" [showTitle]="false" [showUnits]="false"
          [showSubtitle]="false" [lazy]="false" [animation]="true" [startFromZero]="false"
          [animationDuration]="300"></circle-progress>
      </div>
      <div class="label">
        {{languageService.label.txt_onboarding_loader_text}}
      </div>
    </div>
    <div class="userReviews" id="progress-content-review" *ngIf="!isLandscape">
      <div class="heading" [innerHTML]="languageService.label.txt_review_headline_web"></div>
      <div class="review excludeReview">
        <div><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span><span
            [innerHTML]="languageService.label.txt_review_web1"></span><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span>
        </div>
        <div class="reviewStars"><ion-icon name='star'> </ion-icon><ion-icon name='star'></ion-icon><ion-icon
            name='star'></ion-icon><ion-icon name='star'></ion-icon><ion-icon name='star'></ion-icon></div>
      </div>
      <div class="review">
        <div><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span><span
            [innerHTML]="languageService.label.txt_review_web2"></span><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span>
        </div>
        <div class="reviewStars"><ion-icon name='star'> </ion-icon><ion-icon name='star'></ion-icon><ion-icon
            name='star'></ion-icon><ion-icon name='star'></ion-icon><ion-icon name='star'></ion-icon></div>
      </div>
      <div class="review excludeReview">
        <div><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span><span
            [innerHTML]="languageService.label.txt_review_web3"></span><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span>
        </div>
        <div class="reviewStars"><ion-icon name='star'> </ion-icon><ion-icon name='star'></ion-icon><ion-icon
            name='star'></ion-icon><ion-icon name='star'></ion-icon><ion-icon name='star'></ion-icon></div>
      </div>
      <div class="review">
        <div><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span><span
            [innerHTML]="languageService.label.txt_review_web4"></span><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span>
        </div>
        <div class="reviewStars"><ion-icon name='star'> </ion-icon><ion-icon name='star'></ion-icon><ion-icon
            name='star'></ion-icon><ion-icon name='star'></ion-icon><ion-icon class="emptyStar" name='star'></ion-icon>
        </div>
      </div>
      <div class="review">
        <div><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span><span
            [innerHTML]="languageService.label.txt_review_web5"></span><span
            *ngIf="languageService.target != 'ko' &&languageService.target != 'ja' &&languageService.target.toLocaleLowerCase() != 'zh-hans' &&languageService.target.toLocaleLowerCase() != 'zh-hant'">"</span>
        </div>
        <div class="reviewStars"><ion-icon name='star'> </ion-icon><ion-icon name='star'></ion-icon><ion-icon
            name='star'></ion-icon><ion-icon name='star'></ion-icon><ion-icon name='star'></ion-icon></div>
      </div>
    </div>
    <div id="progress-success-content" class="hide">
      <div>
        <h3>{{languageService.label.txt_onboarding_loader_ready_headline}}</h3>
        <p *ngIf="!environment.single" [innerHTML]="languageService.label.txt_onboarding_loader_ready_text"></p>
        <p *ngIf="environment.single" [innerHTML]="languageService.label.txt_onboarding_loader_ready_text_readle"></p>
        <button (click)="dissmissProgressOverlay()">{{languageService.label.txt_onboarding_loader_ready_cta}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="!onboardingService.isTutorial" class="overlayFinal hide"> </div>
  <div *ngIf="!onboardingService.isTutorial && onboardingService.isActive" id="progressFinal">
    <div id="progress-success-content-final" class="hide">
      <div>
        <h3>{{languageService.label.txt_onboarding_loader_ready_headline_final}}</h3>
        <p [innerHTML]="languageService.label.txt_onboarding_loader_ready_text_final"></p>
        <button (click)="endTutorial()">{{languageService.label.txt_onboarding_loader_ready_cta}}</button>
      </div>
    </div>
  </div>



  <div class="article-header">
    <div *ngIf="!this.storageService.sampleMode" class="back-icon" (click)="back()"
      [ngClass]="{'webapp-back': isLandscape}">
      <ion-icon style="vertical-align: middle" name="chevron-back" *ngIf="!isLandscape"></ion-icon>
      <ion-icon name="arrow-back-outline" *ngIf="isLandscape"></ion-icon>
    </div>

    <div style="display: flex; align-items: center;" *ngIf="!isLandscape">
      <ng-container *ngIf="!storageService.sampleMode">
        <div class="btn-mark-as-learned" [ngClass]="{'btn-mark-as-learned-active': isLearned }"
          (click)="toggleLearned()">
          <ion-icon class="icon" name="checkmark"></ion-icon>
          <span class="text">{{this.languageService.label.btn_mark_as_learned}}</span>
        </div>
        <div class="btn-settings" (click)="openSettings()">
          <ion-icon class="icon" name="options-outline"></ion-icon>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="onboardingService.isActive" id="progress-overlay"></div>

  <div [ngClass]="{'wide-screen': isLandscape}">

    <!--
  <div [class]="'final-tooltip ' + this.languageService.target" *ngIf="finalTooltip" (click)="showShopScreen()">
    <img src="assets/onboarding/onboarding_btn_buy_{{languageService.target}}.svg" alt="">
  </div>
  -->
    <div class="final-tooltip final" *ngIf="finalTooltip" [ngClass]="{'webapp-final': isLandscape}"
      (click)="goToNextScreen()">
      <div class="overlay-next-btn-container">
        <!-- <img src="assets/icon/btn-next-bg.svg" class="btn-bg" alt=""> -->
        <!-- <img src="assets/icon/icn-next.svg" class="next-icn" alt=""> -->
        <!-- <span class="next-btn-label"> -->
        {{ this.languageService?.label.txt_introduction_final }}
        <!-- </span> -->
      </div>
    </div>

    <div class="overlay-footer hide" [ngClass]="{'webapp-onboard': isLandscape}">
      <div class="overlay-prev-btn prev-btn" (click)="overlayController($event, true)">
        <div class="overlay-prev-btn-container">
          <!-- <img src="assets/icon/btn-prev-bg.svg" class="btn-bg" alt=""> -->
          <!-- <img src="assets/icon/icn-prev.svg" class="prev-icn" alt=""> -->
          <span class="prev-btn-label">
            {{ this.languageService?.label.txt_introduction_back }}
          </span>
        </div>
      </div>
      <div class="overlay-next-btn final-tooltip" [ngClass]="{'webapp-final': isLandscape}"
        (click)="overlayController($event, false)">
        <div class="overlay-next-btn-container">
          <!-- <img src="assets/icon/btn-next-bg.svg" class="btn-bg" alt=""> -->
          <!-- <img src="assets/icon/icn-next.svg" class="next-icn" alt=""> -->
          <span class="next-btn-label">
            {{ this.languageService?.label.txt_introduction_next }}
          </span>
        </div>
      </div>
    </div>

    <div class="overlay-skip-btn hide" (click)="hideOverlay()">
      {{this.languageService?.label.txt_introduction_skip}}
    </div>
    <div class="overlay-steps hide" [innerHTML]="stepLabelReplacement">
    </div>
    <div class="overlay hide" (click)="overlayController($event, false)">
      <div class="story-tabbar-container">
        <div class="story-tabbar-wrapper">
          <div class="overlay-keyword-label hide" id="semgent-keyword-label-overlay" style="color: black!important">
            {{this.languageService?.label.btn_keywords}}
          </div>
          <div class="overlay-keyword-label hide" id="semgent-grammar-label-overlay" style="color: black!important">
            {{this.languageService?.label.btn_grammar}}
          </div>
          <div class="overlay-keyword-label hide" id="semgent-quiz-label-overlay" style="color: black!important">
            {{this.languageService?.label.txt_quiz}}
          </div>
        </div>
      </div>


      <div class="overlay-sentence hide" [ngClass]="{'webapp-sentence': isLandscape}"></div>
      <div class="introduction-bubble hide">
        <div class="triangle"><img src="assets/icon/box-arrow.svg" alt="" /></div>
        <div class="current-step">{{tooltipNumber}}</div>
        <div class="text" id="introduction-bubble-text">{{this.languageService.label.txt_introduction_select_word}}
        </div>
      </div>

    </div>

    <div *ngIf="!isLandscape" class="toolbar active" id="toolbar"
      [ngClass]="{'onboarding': this.isAudiobarLeft, 'hide': onboardingService.isTutorial, 'hide': this.storyService.quizTab}">

      <img *ngIf="!story.free && !this.purchaseService.userPremium" class="premium_lock"
        src="../../../assets/icon/premium.png" alt="premium_lock" />

      <div class="toolbar-element" id="toolbar-element-story"
        [ngClass]="{'audio-disabled': !audioReady || isPlaySentenceActive}">
        <div style="display: flex; align-items: center; margin-right: 10px;" (click)="toggleStoryAudio()">
          <div class="toolbar-play">
            <ion-icon *ngIf="!isStoryAudioActive" name="play"></ion-icon>
            <ion-icon *ngIf="isStoryAudioActive" name="pause"></ion-icon>
          </div>
          <span style="white-space:nowrap;"
            *ngIf="!this.isOnboarding">{{this.languageService.label.btn_story_audio}}</span>
        </div>


        <div class="toolbar-play" style="line-height:34px;font-size:12px;margin-right:0px;" id="audioSpeedBtn"
          (click)="audioSpeedToggle()">
          {{this.audioSpeed}}x
        </div>

        <div class="toolbar-play" id="audioStoryStop"
          (click)="storyaudioStop(true, true); this.isStoryAudioActive = false;">
          <ion-icon name="stop"></ion-icon>
        </div>

        <div class="toolbar-play" style="margin-left:5px;margin-right:0px;opacity: 0.5;" id="repeatBtn"
          (click)="toggleRepeat()" *ngIf="!onboardingService.isActive">
          <ion-icon name="repeat-outline"></ion-icon>
        </div>
      </div>


      <div class="toolbar-element toolbar-element-sentence" (click)="playSentence()"
        *ngIf="isShowToolbarElementSentence && userDidOpenArticleBefore && !onboardingService.isTutorial">
        <div class="toolbar-play">
          <ion-icon *ngIf="!isPlaySentenceActive" name="play"></ion-icon>
          <ion-icon *ngIf="isPlaySentenceActive" name="stop"></ion-icon>
        </div>
        <span style="white-space:nowrap;">{{this.languageService.label.btn_story_sentence_audio}}</span>
      </div>
    </div>

    <div *ngIf="isLandscape" class="toolbar active webapp-toolbar" id="toolbar"
      [ngClass]="{'onboarding': this.isAudiobarLeft}">

      <img *ngIf="!story.free && !this.purchaseService.userPremium" class="premium_lock"
        src="../../../assets/icon/premium.png" alt="premium_lock" />

      <div class="toolbar-element" id="toolbar-element-story" [ngClass]="{ 'audio-disabled': !audioReady}">
        <div class="toolbar-btn" (click)="toggleStoryAudio()" class="audioToggle">
          {{this.languageService.label.btn_story_audio}}
          <ion-icon *ngIf="!isStoryAudioActive" name="play"></ion-icon>
          <ion-icon *ngIf="isStoryAudioActive" name="pause"></ion-icon>
        </div>
        <div class="toolbar-btn" id="audioStoryStop"
          (click)="storyaudioStop(true, true); this.isStoryAudioActive = false;">
          <ion-icon name="stop"></ion-icon>
        </div>
        <div class="toolbar-btn" id="audioSentence"
          *ngIf="userDidOpenArticleBefore && !this.onboardingService.isTutorial" class="audioToggle"
          (click)="playSentence()">
          {{this.languageService.label.btn_story_sentence_audio}}
          <ion-icon *ngIf="!isPlaySentenceActive" name="play"></ion-icon>
          <ion-icon *ngIf="isPlaySentenceActive" name="stop"></ion-icon>
        </div>
        <div class="toolbar-btn" id="audioWord" *ngIf="userDidOpenArticleBefore && !this.onboardingService.isTutorial"
          class="audioToggle" (click)="playWordAudio()">
          {{this.languageService.label.btn_story_word_audio}}
          <ion-icon *ngIf="!isWordAudioActive" name="play"></ion-icon>
          <ion-icon *ngIf="isWordAudioActive" name="stop"></ion-icon>
        </div>
        <div class="toolbar-btn" style="line-height:34px;" id="audioSpeedBtn" (click)="audioSpeedToggle()">
          {{this.audioSpeed}}x
        </div>
        <div class="toolbar-btn" style="font-size:22px; line-height: 40px; opacity: 0.5;" id="repeatBtn"
          (click)="toggleRepeat()" *ngIf="!onboardingService.isActive">
          <ion-icon name="repeat-outline"></ion-icon>
        </div>
      </div>
    </div>

    <div *ngIf="isLandscape" #storyPictureContainer class="webapp-bg-img" id="articleHead"
      [ngStyle]="{'background-image': 'url(https://langster.org/image/fetch/f_auto,q_auto/' + 'https://langster-production.s3.eu-central-1.amazonaws.com/' + this.story.picture?.mediumLarge + ')', 'position': 'relative' }">
      <div class="learned-story">
        <ng-container *ngIf="!storageService.sampleMode">
          <div class="btn-mark-as-learned" [ngClass]="{'btn-mark-as-learned-active': isLearned }"
            (click)="toggleLearned()">
            <ion-icon class="icon" name="checkmark"></ion-icon>
            <span class="text">{{this.languageService.label.btn_mark_as_learned}}</span>
          </div>
          <div class="btn-settings" (click)="openSettings()">
            <ion-icon class="icon" name="options-outline"></ion-icon>
          </div>
        </ng-container>
      </div>
    </div>


    <div class="article-upper-header" *ngIf="!isLandscape" [ngClass]="{'overlayTranslation' : selectedWord}">
      <div #storyPictureContainer class="article-upper-header-bg" *ngIf="!tabbarQuizActive"
        [ngClass]="{'background-final': didEnter}" id="articleHead"
        [ngStyle]="{'background-image': 'url(https://langster.org/image/fetch/f_auto,q_auto/' + 'https://langster-production.s3.eu-central-1.amazonaws.com/' + this.story.picture?.mediumLarge + ')', 'background-position': 'center'}">
      </div>
      <div class="memorize-container" *ngIf="tabbarKeywordsActive">
        <div class="btn-memorize" (click)="startFlashcards()"><ion-icon name="file-tray-full"></ion-icon> <span
            class="memorize-text">{{languageService.label.btn_keyword_flashcards_enter}}</span> <ion-icon
            name="arrow-forward-outline"></ion-icon></div>
      </div>
      <div class="tab-banner" *ngIf="tabbarQuizActive" [ngClass]="{'single' : environment.single}">
        <div class="tab-container">
          <div class="quiz-level" *ngIf="tabbarQuizActive"
            [ngClass]="{'shadow-correct': quizInfo.questionState === 'correct', 'shadow-incorrect': quizInfo.questionState === 'incorrect', 'readle': environment.single}">
            <div class="quiz-level-bg"
              [ngClass]="{'state-correct': quizInfo.questionState === 'correct', 'state-incorrect': quizInfo.questionState === 'incorrect', 'readle': environment.single}">
              <div>
                <svg style="position: absolute;top: 20px; left: 27px;" width="101" height="99" viewBox="0 0 101 99"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="quizProgress"
                    d="M51.21,4.34c39.58-1.57,45.3,6.59,45.3,44.5,0,48.53,0,44.83-45.3,44.5-40.15-.3-51-7.89-45.3-44.5C12,10.21,12.44,6.72,51.21,4.34Z"
                    stroke="white" stroke-width="8" />
                </svg>
                <svg style="position: absolute;top: 20px; left: 27px;" width="101" height="99" viewBox="0 0 101 99"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="quizProgressDotted"
                    d="M51.21,4.34c39.58-1.57,45.3,6.59,45.3,44.5,0,48.53,0,44.83-45.3,44.5-40.15-.3-51-7.89-45.3-44.5C12,10.21,12.44,6.72,51.21,4.34Z"
                    stroke="white" stroke-width="2" />
                </svg>
                <div class="quiz-level-result">
                  <ng-container *ngIf=" quizInfo.questionsAmount >= quizInfo.currentLevel">
                    {{quizInfo.currentLevel}}/{{quizInfo.questionsAmount}}
                  </ng-container>
                  <ng-container *ngIf=" quizInfo.currentLevel > quizInfo.questionsAmount">
                    <i class="quiz-check"></i>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <story-word-detail [word]="selectedWord" (viewFullWord)="viewFullWord()"
        (stopAudioReading)="pauseAudioReading($event)" (addToTraining)="addWordToTraining()"
        style="position: absolute; width: 100%; height: 100%"
        [ngClass]="{'hide-word-detail': !tabbarStoryActive}"></story-word-detail>
    </div>
    <div [ngClass]="{'webapp-story-tabbar': isLandscape,'story-tabbar-container': !isLandscape }">
      <div class="story-tabbar-wrapper">
        <div (click)="changeTabbar('story')" #tabbarStory [ngClass]="{'active': tabbarStoryActive}">
          {{this.languageService?.label.txt_article}}
        </div>
        <div (click)="changeTabbar('quiz')" #tabbarQuiz [ngClass]="{'active': tabbarQuizActive}">
          {{this.languageService?.label.txt_quiz}}
          <img *ngIf="!story.free && !purchaseService.userPremium" style="width:11px;"
            src="../../../assets/icon/premium.png" alt="premium_lock" />
        </div>
        <div (click)="changeTabbar('keywords')" #tabbarKeywords [ngClass]="{'active': tabbarKeywordsActive}">
          {{this.languageService?.label.btn_keywords}}
          <img *ngIf="!story.free && !purchaseService.userPremium" style="width:11px;"
            src="../../../assets/icon/premium.png" alt="premium_lock" />
        </div>
        <div *ngIf=" (story | storyShowGrammar)" (click)="changeTabbar('grammar')" #tabbarGrammar
          [ngClass]="{'active': tabbarGrammarActive}">
          {{this.languageService?.label.btn_grammar}}
          <img *ngIf="!story.free && !purchaseService.userPremium" style="width:11px;"
            src="../../../assets/icon/premium.png" alt="premium_lock" />
        </div>
      </div>
    </div>

    <div class="article-lower" id="article-lower-element"
      [ngClass]="{'story-view-container' : tabbarStoryActive, 'quiz-view-container' : tabbarQuizActive, 'webapp-bg': isLandscape}">
      <div style="height: 100%;">
        <div class="content-text" id="content-text" [ngClass]="{'hide': !tabbarStoryActive}">
          <div class="article-info">
            <ion-grid [ngClass]="{'webapp-left': isLandscape}">
              <ion-row>
                <ion-col>
                  <div *ngIf="UI_show_translation" class="title"
                    [ngClass]="{'biggerFont': this.wordService.enlargeFont}" (click)="toggleTitle()">{{ story |
                    storyTitle: "origin": false }}</div>
                  <div *ngIf="!UI_show_translation" class="title"
                    [ngClass]="{'biggerFont': this.wordService.enlargeFont}" (click)="toggleTitle()">{{ story |
                    storyTitle: "target": false }}</div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="date-icons-container">
                  <div class="icons-container">
                    <div class="single-element-level" [ngClass]="story.level">{{story.level}}</div>
                    <div class="translate-btn-icon fav-button" id="translateIcon" *ngIf="isTranslated"
                      (click)="toggleSentenceTranslate()">
                      <img *ngIf="!translationActive && !utilsService.isDarkMode" class="sentence-icon"
                        src="../../../assets/svg/translate-icon.svg" alt="">
                      <img *ngIf="!translationActive && utilsService.isDarkMode" class="sentence-icon"
                        src="../../../assets/svg/translate-icon-dark.svg" alt="">
                      <img *ngIf="translationActive && !environment.single" class="sentence-icon"
                        src="../../../assets/svg/translate-icon-active.svg" alt="">
                      <img *ngIf="translationActive && environment.single" class="sentence-icon"
                        src="../../../assets/svg/translate-icon-active-readle.svg" alt="">
                    </div>
                    <div *ngIf="!wordService.enlargeFont && onboardingService.isActive"
                      class="font-button translate-btn-icon" (click)="changeFontSize(3)">
                      <ion-icon name="text-outline" slot="start"></ion-icon>
                    </div>
                    <div *ngIf="wordService.enlargeFont && onboardingService.isActive" class=" translate-btn-icon"
                      id="font-active" (click)="changeFontSize(1)">
                      <ion-icon name="text-outline" slot="start"></ion-icon>
                    </div>

                    <!-- <div class="translate-btn-icon font-btn-icon" (click)="changeFontSize()">
                    <ion-icon name="text-outline"></ion-icon>
                  </div> -->
                    <div *ngIf="!isTranslated&& languageService.origin != languageService.target"
                      class="translate-btn-icon" (click)="swapTitle()">
                      <ion-icon name="repeat"></ion-icon>
                    </div>
                    <div class="translate-btn-icon fav-button" *ngIf="!isFavorite" (click)="favCheck()">
                      <ion-icon name="heart-outline"></ion-icon>
                    </div>
                    <div class="translate-btn-icon fav-button" *ngIf="isFavorite" (click)="favCheck()">
                      <ion-icon name="heart" class="heart-selected"></ion-icon>
                    </div>
                    <div class="translate-btn-icon" *ngIf="-1 < currentSelectedWordIndex && this.authService.user"
                      (click)="reportError()">
                      <ion-icon name="flag-outline"
                        [ngClass]="{'heart-selected': this.authService.activeReport}"></ion-icon>
                    </div>
                    <div *ngIf="languageService.origin == 'ja'" class="romaji-toggle"><ion-label>
                        {{languageService.label.txt_japanese_romaji}}
                      </ion-label>
                      <ion-toggle (ngModelChange)="toggleRomaji()" [(ngModel)]="romajiActive" slot="end" color="primary"
                        [ngClass]="{'mobile': (this.platform.is('ios'))}"></ion-toggle>
                    </div>
                    <div *ngIf="languageService.origin == 'zh-hans'" class="romaji-toggle"><ion-label>
                        {{languageService.label.txt_chinese_pinyin}}
                      </ion-label>
                      <ion-toggle (ngModelChange)="togglePinyin()" [(ngModel)]="pinyinActive" slot="end" color="primary"
                        [ngClass]="{'mobile': (this.platform.is('ios'))}"></ion-toggle>
                    </div>
                  </div>
                  <div *ngIf="!storageService.sampleMode && !story.free && story?.level != 'C1' && showDate"
                    class="info-date">
                    {{story.schedule | customDate: "d MMMM y": "UTC": languageService.target}}</div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>

          <div *ngIf="loading" class="render-wrapper-placeholder" [ngClass]="{'webapp-bg': isLandscape}">
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text style="width:60%;" animated></ion-skeleton-text>
          </div>

          <div *ngIf="!loading" #textRenderWrapper id="render-wrapper-words" [ngClass]="{'webapp-left': isLandscape}">
            <ng-container *ngIf="story.free || purchaseService.userPremium">
              <ng-container *ngFor="let word of story.textMapping; let i = index">
                <span class="single-word"
                  [ngClass]="{'wrap-word': word.word.length > 10,'no-space-after': word.noSpaceAfter, 'no-space-before': word.noSpaceBefore,'underline-training': ( word.result?.inTraining&& this.settingsService.underlineTraining), 'currentSelected': i == currentSelectedWordIndex, 'largerFont':(languageService.origin =='ja' || languageService.origin =='zh-hans'), 'biggerFont': this.wordService.enlargeFont, 'show-hiragana': shouldShowHiragana(word)}"
                  (click)="selectSingleWord(word, $event, i)" [attr.data-hiragana]="getHiragana(word)">
                  {{ word.word }}
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!story.free && !purchaseService.userPremium">
              <ng-container *ngFor="let word of story.textMapping; let i = index">
                <span class="single-word" *ngIf="i <= 20"
                  [ngClass]="{'wrap-word': word.word.length > 10,'blurWord': (!isOnboarding && (i > 20) && !story.free && !purchaseService.userPremium), 'no-space-after': word.noSpaceAfter, 'no-space-before': word.noSpaceBefore, 'underline-training': (word.result?.inTraining && this.settingsService.underlineTraining),'currentSelected': i == currentSelectedWordIndex, 'biggerFont': this.wordService.enlargeFont,'show-hiragana': shouldShowHiragana(word)}"
                  (click)="selectSingleWord(word, $event, i)" [attr.data-hiragana]="getHiragana(word)">
                  {{ word.word }}
                </span>
              </ng-container>
              <span class="blurWord">
                <ng-container *ngFor="let word of story.textMapping; let i = index">
                  <span class="single-word" *ngIf="i > 20"
                    [ngClass]="{'wrap-word': word.word.length > 10,'no-space-after': word.noSpaceAfter, 'no-space-before': word.noSpaceBefore, 'underline-training': (word.result?.inTraining && this.settingsService.underlineTraining),'currentSelected': i == currentSelectedWordIndex, 'biggerFont': this.wordService.enlargeFont, 'show-hiragana': shouldShowHiragana(word)}"
                    (click)="selectSingleWord(word, $event, i)" [attr.data-hiragana]="getHiragana(word)">
                    {{ word.word }}
                  </span>
                </ng-container>
              </span>

            </ng-container>

            <!--<span>{{ story.source }}</span>-->
          </div>
          <div class="cta-read-more"
            *ngIf="(!isOnboarding && !story.free && !purchaseService.userPremium && !notificationService.nextStoryFree && !fromDeeplink)"
            [ngClass]="{'webapp-onboard': isLandscape}">
            <div (click)="displayMore()">
              {{ this.languageService?.label.txt_story_cta_read_more }}
            </div>
          </div>
        </div>

        <div class="content-grammar" id="content-grammar"
          [ngClass]="{'hide': !tabbarGrammarActive, 'overlayExtraPadding': !this.userDidOpenArticleBefore, 'web-content' : isLandscape}">
          <grammar *ngFor="let grammar of story.grammar" [grammar]="grammar"></grammar>
          <div style="height: 100px;"></div>
        </div>

        <div *ngIf="!this.memorizeService.back" class="content-keywords" id="content-keywords"
          [ngClass]="{'hide': !tabbarKeywordsActive, 'overlayExtraPadding': !this.userDidOpenArticleBefore, 'web-content' : isLandscape}">
          <keywords *ngIf="story.textMapping" [story]="story" [keywords]="story.textMapping" [storyId]="story._id">
          </keywords>
        </div>
        <div *ngIf="this.memorizeService.back" class="content-keywords" id="content-keywords"
          [ngClass]="{'hide': !tabbarKeywordsActive, 'overlayExtraPadding': !this.userDidOpenArticleBefore}">
          <keywords *ngIf="story.textMapping" [story]="story" [keywords]="story.textMapping" [storyId]="story._id">
          </keywords>
        </div>

        <div [ngClass]="{'hide': !tabbarQuizActive, 'overlayExtraPadding': !this.userDidOpenArticleBefore}"
          style="height: 100%; max-width: 500px;margin:0px auto;">
          <quiz *ngIf="story.quiz" [quiz]="story.quiz" (quizData)="quizData($event)"
            (questionChange)="scrollLowerElementToTop()"></quiz>
        </div>
      </div>
      <div *ngIf="this.authService.activeReport && this.authService.user" class="overlayFinal" (click)="closeReport()">
      </div>
      <div *ngIf="this.authService.activeReport && this.authService.user" id="progressFinal" class="error-modal">
        <div id="modal-report">
          <div>
            <div class="back-btn-modal" (click)="closeReport()">
              <ion-icon name="close"></ion-icon>
            </div>
            <error-report [word]="selectedWord" [storyID]="storyID" [userID]="userID" (viewFullWord)="viewFullWord()"
              (stopAudioReading)="stopAudioReading($event)" style="width: 100%;"></error-report>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{'webapp-right': isLandscape}">
      <translationcn-card [story]="story" [currentSelectedWordIndex]="currentSelectedWordIndex" [word]="selectedWord"
        (stopAudioReading)="stopAudioReading($event)" (addToTraining)="addWordToTraining()"
        style="position: relative; height: 90%; display: flex;"
        *ngIf="isLandscape && languageService.origin === 'zh-hans'"></translationcn-card>
      <translationes-card [story]="story" [currentSelectedWordIndex]="currentSelectedWordIndex" [word]="selectedWord"
        (stopAudioReading)="stopAudioReading($event)" (addToTraining)="addWordToTraining()"
        style="position: relative; height: 90%; display: flex;"
        *ngIf="isLandscape && languageService.origin === 'es'"></translationes-card>
      <translationfr-card [story]="story" [currentSelectedWordIndex]="currentSelectedWordIndex" [word]="selectedWord"
        (stopAudioReading)="stopAudioReading($event)" (addToTraining)="addWordToTraining()"
        style="position: relative; height: 90%; display: flex;"
        *ngIf="isLandscape && languageService.origin === 'fr'"></translationfr-card>
      <translationja-card [story]="story" [currentSelectedWordIndex]="currentSelectedWordIndex" [word]="selectedWord"
        (stopAudioReading)="stopAudioReading($event)" (addToTraining)="addWordToTraining()"
        style="position: relative; height: 90%; display: flex;"
        *ngIf="isLandscape && languageService.origin === 'ja'"></translationja-card>
      <translationde-card [story]="story" [currentSelectedWordIndex]="currentSelectedWordIndex" [word]="selectedWord"
        (stopAudioReading)="stopAudioReading($event)" (addToTraining)="addWordToTraining()"
        style="position: relative; height: 90%; display: flex;"
        *ngIf="isLandscape && languageService.origin === 'de'"></translationde-card>
      <translationen-card [story]="story" [currentSelectedWordIndex]="currentSelectedWordIndex" [word]="selectedWord"
        (stopAudioReading)="stopAudioReading($event)" (addToTraining)="addWordToTraining()"
        style="position: relative; height: 90%; display: flex;"
        *ngIf="isLandscape && languageService.origin === 'en'"></translationen-card>
    </div>
  </div>

  <div *ngIf="this.activeShare" class="overlayFinal" (click)="closeShare()"></div>
  <div *ngIf="this.activeShare" id="progressFinal">
    <div id="modal-share">
      <div>
        <div class="back-btn-modal" (click)="closeShare()">
          <ion-icon name="close"></ion-icon>
        </div>
        <h3 [innerHTML]="languageService.label.txt_share_unlock_title"></h3>
        <div *ngIf="environment.single" [innerHTML]="languageService.label.txt_share_unlock_desc_Readle"></div>
        <div *ngIf="!environment.single" [innerHTML]="languageService.label.txt_share_unlock_desc"></div>
        <button class="share-btn" (click)="share()">{{languageService.label.txt_share_unlock_cta}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="this.showNoteTranslations" class="overlayFinal" (click)="closeNoteTranslations()"></div>
  <div *ngIf="this.showNoteTranslations" id="progressFinal">
    <div id="modal-share">
      <div>
        <h3>We are adding {{TranslationLang}}!</h3>
        <div *ngIf="!this.signedIn">If you’d like to help improve the accessibility of learning in
          {{TranslationLang}}, please
          create a user account & notify translation improvements via <ion-icon name="flag-outline"
            slot="start"></ion-icon>!
        </div>
        <div *ngIf="this.signedIn"> If you’d like to help improve the accessibility of learning in
          {{TranslationLang}}, please
          report translation improvements via <ion-icon name="flag-outline" slot="start"></ion-icon>!
        </div>
        <button class="share-btn" (click)="closeNoteTranslations()">Okay</button>
      </div>
    </div>
  </div>

</ion-content>
<div class="cupertino-pane-story-settings" style="display: none;">
  <h3>{{languageService.label.txt_setting_story_title}}</h3>
  <ng-container>
    <div class="main-container">
      <ion-list class="list-settings">
        <ion-item lines="none">
          <ion-icon name="text-outline" slot="start"></ion-icon>
          <ion-label>
            {{languageService.label.txt_setting_story_font}}
          </ion-label>
          <!-- <ion-toggle (ngModelChange)="changeFontSize(1)" [(ngModel)]="wordService.enlargeFont" slot="end"
            color="primary"></ion-toggle> -->
          <div class="font-container">
            <span class="font-op" [ngClass]="{'active': settingsService.fontSize == 1}"
              (click)="changeFontSize(1)">1x</span>
            <span class="font-op" [ngClass]="{'active': settingsService.fontSize == 2}"
              (click)="changeFontSize(2)">2x</span>
            <span class="font-op" [ngClass]="{'active': settingsService.fontSize == 3}"
              (click)="changeFontSize(3)">3x</span>
          </div>
        </ion-item>

        <ion-item lines="none">
          <ion-icon name="book-outline" slot="start"></ion-icon>
          <ion-label>
            {{languageService.label.txt_settings_highlight_text}}
          </ion-label>
          <ion-toggle (ionChange)="toggleHighlight()" [(ngModel)]="isHighlight" slot="end" color="primary"></ion-toggle>
        </ion-item>

        <ion-item lines="none" *ngIf="isTranslated">
          <img *ngIf="!utilsService.isDarkMode" class="sentence-icon"
            src="../../../assets/svg/translate-icon-setting.svg" alt="" slot="start" width="27px" height="27px">
          <img *ngIf="utilsService.isDarkMode" class="sentence-icon"
            src="../../../assets/svg/translate-icon-dark-setting.svg" alt="" slot="start" width="27px" height="27px">
          <ion-label>
            {{languageService.label.txt_setting_story_sentence}}
          </ion-label>
          <ion-toggle (ngModelChange)="toggleSentenceTranslate()" [(ngModel)]="translationActive" slot="end"
            color="primary"></ion-toggle>
        </ion-item>

        <ion-item lines="none" *ngIf="languageService.origin == 'ja'">
          <img *ngIf="!utilsService.isDarkMode" src="../../../assets/svg/font-a.svg" alt="" slot="start" width="27px"
            height="22px">
          <img *ngIf="utilsService.isDarkMode" src="../../../assets/svg/font-a-dark.svg" alt="" slot="start"
            width="27px" height="22px">
          <ion-label>
            {{languageService.label.txt_japanese_romaji}}
          </ion-label>
          <ion-toggle (ngModelChange)="toggleRomaji()" [(ngModel)]="romajiActive" slot="end"
            color="primary"></ion-toggle>
        </ion-item>
        <ion-item lines="none" *ngIf="languageService.origin == 'ja'">
          <span class="hiragana-icon" slot="start">あ</span>
          <ion-label>
            {{languageService.label.txt_japanese_hiragana}}
          </ion-label>
          <ion-toggle (ngModelChange)="toggleHiragana()" [(ngModel)]="hiraganaActive" slot="end"
            color="primary"></ion-toggle>
        </ion-item>
        <ion-item lines="none" *ngIf="languageService.origin == 'zh-hans'">
          <img *ngIf="!utilsService.isDarkMode" src="../../../assets/svg/font-a.svg" alt="" slot="start" width="27px"
            height="22px">
          <img *ngIf="utilsService.isDarkMode" src="../../../assets/svg/font-a-dark.svg" alt="" slot="start"
            width="27px" height="22px">
          <ion-label>
            {{languageService.label.txt_chinese_pinyin}}
          </ion-label>
          <ion-toggle (ngModelChange)="togglePinyin()" [(ngModel)]="pinyinActive" slot="end"
            color="primary"></ion-toggle>
        </ion-item>

        <ion-item lines="none">
          <ion-icon *ngIf="utilsService.isDarkMode" name="moon-outline" slot="start"></ion-icon>
          <ion-icon *ngIf="!utilsService.isDarkMode" name="sunny-outline" slot="start"></ion-icon>
          <ion-label>
            {{languageService.label.txt_setting_story_dark_mode}}
          </ion-label>
          <ion-toggle (ngModelChange)="utilsService.toggleDarkMode(!utilsService.isDarkMode)"
            [(ngModel)]="this.utilsService.isDarkMode" slot="end" color="primary"></ion-toggle>
        </ion-item>
        <ion-item lines="none">
          <ion-icon name="barbell-outline" slot="start"></ion-icon>
          <ion-label class="label-nowrap">
            {{languageService.label.txt_setting_story_underline_training}}
          </ion-label>
          <ion-toggle (ngModelChange)="underlineWordsInTraining()" [(ngModel)]="this.settingsService.underlineTraining"
            slot="end" color="primary"></ion-toggle>
        </ion-item>

        <ion-item lines="none" *ngIf="-1 < currentSelectedWordIndex && this.authService.user" (click)="reportError()">
          <ion-icon name="flag-outline" slot="start"></ion-icon>
          <ion-label>
            {{languageService.label.txt_report_error_title}}
          </ion-label>
        </ion-item>
      </ion-list>
    </div>
  </ng-container>
</div>