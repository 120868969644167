import { Component, OnDestroy, Input, ViewChild, ElementRef, Renderer2, HostListener, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { StoryService } from '../../service/story-service/story.service';
import { WordService } from './../../service/word-service/word.service';
import { ActivatedRoute } from '@angular/router';
import { ModalController, PopoverController, IonRouterOutlet, AlertController, LoadingController } from '@ionic/angular';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { BackdropModule } from 'cupertino-pane/dist/modules';
import { DomSanitizer } from '@angular/platform-browser';
import { NavController, Platform } from '@ionic/angular';
import { AudioServiceService } from '../../service/audio-service/audio-service.service';
import { RatingService } from '../../service/rating-service/rating.service';
import { PurchaseService } from '../../service/purchase-service/purchase.service';
import { LanguageService } from '../../service/language-service/language.service';
import * as smoothscroll from 'smoothscroll-polyfill';
import { Storage } from '@ionic/storage';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import anime from 'animejs/lib/anime.es.js';
import { StorageService } from '../../service/storage-service/storage.service';
import { QuizComponent } from '../../components/quiz/quiz.component';
import { ShopPage } from '../shop/shop.page';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { ScreensizeService } from '@services/screensize-service/screensize.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

// Firebase
import 'firebase/analytics';
import { TapticEngine } from '@ionic-native/taptic-engine/ngx';
import { WordDetailPage } from '../word-detail/word-detail.page';
// import { TrainingService } from '../../service/training-service/training.service';
import { Story, WordResultMap } from '../../interfaces/story';
import { environment } from '../../../environments/environment';
import Word from '@interfaces/word';
import { TrainingService } from '@services/training-service/training.service';
import { WordCategory } from '@interfaces/word-category.enum';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { UtilsService } from '@services/utils-service/utils.service';
import { NotificationService } from '@services/notification-service/notification.service';
import { timer } from 'rxjs';
import { OnboardingService } from '@services/onboarding-service/onboarding.service';
import { PurchaseOrigin } from 'app/enums/purchase_origin.enum';
import { MemorizeService } from '@services/memorize-service/memorize.service';
import { KeywordsComponent } from '../../components/keywords/keywords.component';
//import { HomeService } from '@services/home-service/home.service';
import { AuthService } from '@services/auth-service/auth.service';
import { LoginPage } from '@pages/login/login.page';
import { SettingsService } from '@services/settings-service/settings.service';
// ###### CONFIG PARAMETER TODO: seperate config-file
const SHOPTIMER = false;
// ######

@Component({
  selector: 'app-article',
  templateUrl: './article.page.html',
  styleUrls: ['./article.page.scss'],
})
export class ArticlePage implements OnDestroy {
  environment = environment;

  @ViewChild(QuizComponent) quizComponent: QuizComponent;
  @ViewChild(KeywordsComponent) keywordsComponent: KeywordsComponent;
  @ViewChild('textRenderWrapper') textRenderWrapper: ElementRef;
  @ViewChild('storyPictureContainer') storyPictureContainer: ElementRef;
  @ViewChild('tabbarStory') tabbarStory: ElementRef;
  @ViewChild('tabbarGrammar') tabbarGrammar: ElementRef;
  @ViewChild('tabbarKeywords') tabbarKeywords: ElementRef;
  @ViewChild('tabbarQuiz') tabbarQuiz: ElementRef;
  @Input() keywords: WordResultMap[];
  story: Story = {} as Story;
  public storyID: string;
  showDate: boolean = false;
  word_data: any;
  user: any;
  readmore = false;
  segment_switch: any;
  segment_switch_slider_position: any;
  big_content_view: boolean;
  word_logs: any;
  audioReading = new Audio();
  audioReadingActive = false;
  audioReadingInterval: any;
  userDidOpenArticleBefore = true; // set false for onboarding
  overlayCurrentElement = 0;
  tooltipNumber = 0;
  totalSteps = 5;
  shopTimer: any;
  isFavorite = false;
  isLearned = false;
  isTranslated = false;
  lang = 'en';
  translationActive = false;
  romajiActive = false;
  hiraganaActive = true;
  pinyinActive = false;
  traslationIndex = 0;
  tutorialStarted = false;
  currentSelectedWordIndex: number = -1;

  // ##### UI VARIABLES
  UI_show_translation = false;
  UI_show_word_translation = false;
  UI_word_german = 'NULL'; // German word from the text
  UI_word_translation = 'NULL'; // translation for target language
  UI_word_plural: string | boolean = 'NULL'; // plural of the word when avaiable, otherwise false when no plural avaiable
  UI_word_sound_url = 'NULL'; // URL to the soundfile of the word
  UI_word_small = false;
  UI_word_is_training = false;
  UI_detail_audio_active: boolean;
  isBackgroundSet = false;
  pictureUrl = '';
  finalTooltip = false;
  firstVerb: any;
  showNoteTranslations = false;
  TranslationLang = '';

  tabbarStoryActive = true;
  tabbarKeywordsActive = false;
  tabbarGrammarActive = false;
  tabbarQuizActive = false;

  quizProgressPathLength: number = 314.0967712402344;
  // ######

  // ###### REQUEST PARAMETER
  DATA_word: any;
  // ######
  audioSelected = 'story';
  webAudioActive = false;

  public selectedWord: Word;

  public audio = new Audio();
  public audioSpeed = 1;

  public userID: string;

  // switches
  public switch_text = true;
  public switch_grammar = false;
  public switch_keywords = false;
  public switch_quiz = false;

  // audioReading
  public index = 0;
  private time = 0;
  private milliseconds = 50;
  private array;
  private elements;
  private currentLastMarkedWordIndex = 0;
  public stopBtnShow = false;

  //Quiz Level
  public quizInfo: any = {
    currentLevel: 1,
    questionsAmount: 0,
    questionState: ''
  }
  isLandscape = false;
  signedIn: boolean;
  repeatToggle = false;
  didEnter = false;
  loading = false;
  audioReady = false;
  sentenceAudio = false;
  isOnboarding = false;
  isStoryAudioActive = false;
  isWordAudioActive = false
  audioPaused = false;
  isShowToolbarElementSentence = false;
  isPlaySentenceActive = false;
  isAudiobarLeft = false;
  progressbarStatus = 0;
  showProgressbar = true;
  fromDeeplink = false;
  isMobile = this.utilsService.isMobile();
  isHighlight: boolean;
  isDark: boolean;
  settingActive: boolean;
  settingsPane;
  activeShare = false;
  blurred = false;
  stopPressed = false;

  constructor(
    public storyService: StoryService,
    public route: ActivatedRoute,
    public modalController: ModalController,
    public _sanitizer: DomSanitizer,
    public wordService: WordService,
    public router: Router,
    public popoverController: PopoverController,
    public audioService: AudioServiceService,
    public ratingService: RatingService,
    private screensizeService: ScreensizeService,
    public navCtrl: NavController,
    public purchaseService: PurchaseService,
    public languageService: LanguageService,
    public memorizeService: MemorizeService,
    private socialSharing: SocialSharing,
    public storage: Storage,
    public platform: Platform,
    public firebaseAnalytics: FirebaseAnalytics,
    public storageService: StorageService,
    public taptic: TapticEngine,
    private routerOutlet: IonRouterOutlet,
    private trainingService: TrainingService,
    private renderer: Renderer2,
    private appsflyer: Appsflyer,
    public authService: AuthService,
    public inAppBrowser: InAppBrowser,
    private alertController: AlertController,
    private launchReview: LaunchReview,
    private loadingController: LoadingController,
    public utilsService: UtilsService,
    public notificationService: NotificationService,
    private ngZone: NgZone,
    public onboardingService: OnboardingService,
    public settingsService: SettingsService,
    //private homeService: HomeService
  ) {

    if (this.storyService.storyPreloaded?._id) {
      this.story = this.storyService.storyPreloaded;
    }

    this.loadData();
    smoothscroll.polyfill();
    this.segment_switch = 'text';
    this.big_content_view = false;

    this.trainingService.toggleTrainingKeywordsObserver.subscribe(index => {
      if (this.selectedWord && this.selectedWord?.inTraining === undefined) this.selectedWord.inTraining = false;
      if (this.selectedWord && this.selectedWord?._id == index) this.selectedWord.inTraining = !this.selectedWord.inTraining;
    })

    if (this.notificationService.nextStoryFree) this.fromDeeplink = true;
    else this.fromDeeplink = false;

    if (utilsService.isWebVersion) {
      this.screensizeService.isLandscapeView().subscribe(isLandscape => {
        if (this.isLandscape && !isLandscape) {
          // reload bc routing is out of place
          window.location.reload();
        }

        this.isLandscape = isLandscape;
      })
    }

    // this.languageService.watchOrigin.subscribe(async (origin) => {
    //   if (origin) {
    //     this.loadData();
    //   }
    // })

    this.route.queryParams.subscribe(async params => {
      if (params.level && params.learning_lang && params.device_lang) {
        this.onboardingService.isActive = false;
        this.utilsService.routingName = 'home';
        if (this.utilsService.isWebVersion) this.utilsService.routingName = 'deeplink';
        const val = await this.storage.get('LangsterUserLevel');
        //if the user has data on web, prevent them from overwriting their current level and language 
        if (val) {
          this.firebaseAnalytics.setUserProperty('Level', val);
        } else { //if the user does not have data on web 
          this.storageService.storeUserLevel(params.level, false);
          await this.languageService.setOrigin(params.learning_lang)
          this.languageService.setCustomTargetLanguage(params.device_lang);
          this.firebaseAnalytics.setUserProperty("Level", params.level)
          this.swapTitle();
          setTimeout(() => {
            this.swapTitle();
          }, 200)
        }
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.storage.get('settingHighlight').then(val => {
      this.isHighlight = val;
    });
    this.storage.get('settingsFont').then(val => {
      this.settingsService.fontSize = val;
      this.changeFontSize(val);
    });
    this.storage.get('underlineTraining').then(val => {
      this.settingsService.underlineTraining = val;
    });
    this.storage.get('audioSpeed').then(val => {
      if (val) {
        this.audioSpeed = val;
        this.audioReading.playbackRate = val;
      }
    });
    this.initPane();
    if (this.onboardingService.isActive) this.onboardingService.isTutorial = true;
  }

  ionViewDidEnter(): void {
    const modal = document.getElementById('progressFinal') as HTMLElement
    if (modal) modal.classList.add('hide');
    this.audioService.stop();
    this.firebaseAnalytics.logEvent('screen_article', {});
    this.firebaseAnalytics.setCurrentScreen('screen_story');
    if (this.onboardingService.isTutorial) {
      this.initShowOverlay();
      localStorage.setItem('tutorial', 'true');
    } else if (!this.onboardingService.isTutorial) {
      this.storage.get('firstStoryAITranslations').then(val => {
        if (!val) {
          this.TranslationLang = this.languageService.target
          switch (this.TranslationLang) {
            case 'it':
              this.TranslationLang = 'Italian';
              this.showNoteTranslations = true;
              break;
            case 'uk':
              this.TranslationLang = 'Ukrainian';
              this.showNoteTranslations = true;
              break;
            case 'pt':
              this.TranslationLang = 'Portugese';
              this.showNoteTranslations = true;
              break;
            case 'pl':
              this.TranslationLang = 'Polish';
              this.showNoteTranslations = true;
              break;
            case 'ru':
              this.TranslationLang = 'Russian';
              this.showNoteTranslations = true;
              break;
            case 'nl':
              this.TranslationLang = 'Dutch';
              this.showNoteTranslations = true;
              break;
            case 'in':
              this.TranslationLang = 'Indonesian';
              this.showNoteTranslations = true;
              break;
            case 'vi':
              this.TranslationLang = 'Vietnamese';
              this.showNoteTranslations = true;
              break;
            case 'hu':
              this.TranslationLang = 'Hungarian';
              this.showNoteTranslations = true;
              break;
            case 'cs':
              this.TranslationLang = 'Czech';
              this.showNoteTranslations = true;
              break;
            case 'ar':
              this.TranslationLang = 'Arabic';
              this.showNoteTranslations = true;
              break;
            case 'no':
              this.TranslationLang = 'Norwegian';
              this.showNoteTranslations = true;
              break;
            case 'fi':
              this.TranslationLang = 'Finnish';
              this.showNoteTranslations = true;
              break;
            case 'sv':
              this.TranslationLang = 'Swedish';
              this.showNoteTranslations = true;
              break;
          }
        }
      }
      );
    }
    this.didEnter = true;
    this.removeOnboardingStoryInstance();
    if (this.onboardingService.isActive) this.simulateProgress();
    if (this.memorizeService.back) {
      this.memorizeService.back = false;
    }
    this.ratingService.ratingEntered = false;


    if (this.authService.token) {
      this.storyService.recordOpenedStory(this.story._id, this.languageService.origin).then((res) => {
        if (res.result) {
          const userDataLanguage = this.authService.user.data.find(el => el.language == this.languageService.origin);
          if (userDataLanguage) {
            userDataLanguage.storiesOpened = res.result;
          } else {
            console.log("ELSE", this.authService.user.data)
            this.authService.user.data.push({
              language: this.languageService.origin,
              storiesOpened: res.result,
              storiesLearned: [],
              storiesFavorite: []
            })
          }
          this.authService.setUser(this.authService.user);
        }
      }).catch((error) => {
        console.log("error recording")
      })
    } else {
      this.authService.addOpenedStory(this.languageService.origin, this.story._id)
    }
  }

  ngOnDestroy(): void {
    this.audio = null;
    clearInterval(this.audioReadingInterval);
    if (!this.onboardingService.isActive) this.handleOpenedFreeArticles();
    this.closeSettingsPane();
    if (this.platform.is('iphone') || this.platform.is('ipad')) {
      window.removeEventListener('keyboardWillHide', () => {
      })
      window.removeEventListener('keyboardWillShow', () => {
      })
    }
    this.authService.activeReport = false;
    const modal = document.getElementById('progressFinal') as HTMLElement
    if (modal) modal.style.opacity = '0';
  }


  /**
   * Updates the onboarding-overlay in case of a resive of the window 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.overlayCurrentElement--;
    this.tooltipNumber--;
    this.initShowOverlay();
  }

  private async loadData() {
    if (!this.onboardingService.isActive) {
      this.loading = true;
      this.utilsService.disconnected = true;
    }
    let paramStoryId = this.route.snapshot.paramMap.get('storyId');
    let paramLanguage = this.route.snapshot.paramMap.get('language');


    let languageFromStorage = await this.languageService.getOriginFromStorage();
    if (paramLanguage === 'language' && languageFromStorage) paramLanguage = languageFromStorage;

    if (paramStoryId && paramLanguage) {
      this.storyService.getStoryByStoryId(paramStoryId, paramLanguage).subscribe(storyData => {
        if (new Date(storyData.schedule) > new Date('2024-01-01T08:00:00')) {
          this.showDate = true;
        }
        if (storyData) this.prepareData(storyData);
        else this.router.navigateByUrl('/home');
      }, (error) => {
        this.router.navigateByUrl('/home');
      });
    } else {
      this.storyService.getStory(this.route.snapshot.paramMap.get('id')).subscribe(storyData => {
        if (new Date(storyData.schedule) > new Date('2024-01-01T08:00:00')) {
          this.showDate = true;
        }
        this.prepareData(storyData);
      });
    }

  }

  private async prepareData(storyData?: any) {
    this.ngZone.run(async () => {

      console.log(storyData)
      let ignoreFirstSession = false;

      // Set story free if coming from notification
      if (this.notificationService.nextStoryFree) {
        if (storyData) {
          storyData.free = true;
          ignoreFirstSession = true;
        }
        this.notificationService.nextStoryFree = false;
      }

      // set story free if web and tag is web_free
      if (this.utilsService.isWebVersion && storyData?.tags?.includes('web_free')) {
        storyData.free = true;
      }

      if (storyData) this.story = storyData;
      if (await this.storageService.getUnlockedStoryByReview() == this.story._id || await this.storageService.getUnlockedStoryByShare() == this.story._id || await this.storageService.getUnlockedStoryByFree() == this.story._id || await this.storageService.getUnlockedStoryByFree() == this.story._id) this.story.free = true;

      this.quizInfo.questionsAmount = this.story.quiz.questions.length;
      this.setBackgroundImage();
      //this.initShowOverlay();
      this.isFavorite = this.storageService.checkFavorite(this.story._id);
      this.checkIfStoryLearned();
      this.loadAudio();

      let firstSession = await this.storageService.isFirstSession();

      /**
       * Set story free if onboarding mode is on
       */
      if (this.onboardingService.isActive) this.story.free = true;

      /**
       * Set stories with first_session tag free, all other stories
       * are being logged
       */
      if (firstSession && !this.onboardingService.isActive) {
        if (this.story.tags?.includes("first_session") || (this.utilsService.isWebVersion && storyData?.tags?.includes('web_free')) || await this.storageService.getUnlockedStoryByFree() == this.story._id) this.story.free = true;
        else {
          if (!ignoreFirstSession) {
            this.story.free = false;
          } else {
            ignoreFirstSession = false;
          }
        }
      }

      setTimeout(() => {
        this.setGrammarFontSize();
        if (this.onboardingService.isTutorial) {
          const toolbar = document.getElementById('toolbar');
          toolbar.classList.remove('hide');
        }
        if (this.signedIn) this.userID = this.user._id;
        this.storyID = storyData._id
      }, 500)
      if (this.languageService.origin == 'ja') {
        this.storage.get('showRomaji').then(val => {
          console.log('romaji', val);
          this.settingsService.showRomaji = val;
          this.romajiActive = !val;
          this.toggleRomaji();
        });
        this.storage.get('showHiragana').then(val => {
          console.log('hiragana', val);

          if (val == undefined || val == null) val = true;
          this.settingsService.showHiragana = val;
          this.hiraganaActive = !val;
          this.toggleHiragana();
        });
      }
      if (this.languageService.origin == 'zh-hans') {
        this.storage.get('showPinyin').then(val => {
          console.log('showPinyin', val);
          this.settingsService.showPinyin = val;
          this.pinyinActive = !val;
          this.togglePinyin();
        });
      }
      this.checkLoggedIn();
      this.addWordBlur();
      this.handleSpacing();
      this.removePhrasesFromKeywords();
      this.addPhrasesToKeywords();
      this.loading = false;
      this.utilsService.disconnected = false;
      if (this.story.features && (this.languageService.origin != this.languageService.target)) this.findTranslation();
      if (this.story.grammar && this.story.grammar.length > 0) this.totalSteps++;
      this.storage.get('sentenceTranslate').then(val => {
        if (val == true && this.isTranslated) {
          this.toggleSentenceTranslate();
        } else if (val == null && this.isTranslated && (this.storageService.userLevel?.toLocaleLowerCase() == 'a1' || this.storageService.userLevel?.toLocaleLowerCase() == 'hsk1' || this.storageService.userLevel?.toLocaleLowerCase() == 'n5')) {
          console.log('sentenceTranslate beginner')
          this.toggleSentenceTranslate();
          this.storage.set('sentenceTranslate', true);
        }
      });
    });
    const modal = document.getElementById('progressFinal') as HTMLElement
    if (this.platform.is('iphone') || this.platform.is('ipad')) {
      window.addEventListener('keyboardDidHide', () => {
        setTimeout(() => {
          modal.style.transform = null;
          modal.style.top = null;
        }, 400)
      });
      window.addEventListener('keyboardWillShow', () => {
        console.log('show')
        const winHeight = window.innerHeight;
        const moveModal = -1 * modal.getBoundingClientRect().bottom - winHeight - 200
        if (modal.getBoundingClientRect().bottom > winHeight - 216) modal.style.transform = 'translateY(0, ' + moveModal + 'px)';
        modal.style.top = 'calc(var(--ion-safe-area-top) + 10px';

      });
    }

  }

  async checkLoggedIn() {
    this.user = await this.authService.loadUserFromStorage();
    const token = await this.authService.loadTokenFromStorage();
    if (!this.user || !token) {
      this.signedIn = false;
    }
    else this.signedIn = true;
  }

  async findTranslation(): Promise<void> {
    this.lang = this.languageService.target;
    if (this.lang == 'eng') this.lang = 'en';
    this.isTranslated = true;
    this.findTranslationIndex();
    if (this.isTranslated && this.onboardingService.isTutorial) this.totalSteps++;
  }

  findTranslationIndex(): void {
    let found = false;
    for (let index = 0; index < this.story.sentencesMeta[0].translations.length; index++) {
      if (this.lang == this.story.sentencesMeta[0].translations[index].language || this.lang.includes("zh")) {
        found = true;
        this.lang = this.lang
        if ((this.lang.includes("zh-hans") || this.lang == 'zh') && (this.story.sentencesMeta[0].translations[index].language == "zh")) {
          this.traslationIndex = index;
          break;
        }
        else if ((this.lang.includes("zh-hant") || this.lang.includes("zh-TW")) && (this.story.sentencesMeta[0].translations[index].language == "zh-TW")) {
          console.log(this.lang, this.story.sentencesMeta[0].translations[index].language)
          this.traslationIndex = index;
          break;
        } else this.traslationIndex = index;
      }
    }
    if (!found) this.isTranslated = false;
  }

  loadAudio(): void {
    this.audioReading.src = `https://langster.org/audio/s3/${this.story.audioFilename}?bucket=${this.purchaseService.serverRegion}`;
    // this.audioReading.src = `https://${this.purchaseService.bucketName}.s3.${this.purchaseService.serverRegion}.amazonaws.com/${this.story.audioFilename}`
    this.audioReading.load();
    this.audioReading.oncanplay = (ev) => {
      this.audioReady = true
    };
    //this.audioReading.oncanplaythrough = (ev) => console.log("canPlayThrough")

    if (true) {
      setTimeout(() => {
        this.audioReading = new Audio();
        this.audioReading.src = `https://langster.org/audio/s3/${this.story.audioFilename}?bucket=${this.purchaseService.serverRegion}`;
        this.audioReading.load();
        this.audioReading.oncanplay = (ev) => {
          this.audioReady = true;
        }
      }, 500);
    }

  }

  play(): void {
    this.audio.play();
  }

  handleOpenedFreeArticles(): void {
    if (this.story.free) {
      this.storyService.freeArticlesOpened++;
      if (!this.purchaseService.userPremium && this.storyService.freeArticlesOpened >= 2) {
        this.storyService.freeArticlesOpened = 0;
        this.purchaseService.setPurchaseOrigin(PurchaseOrigin.POPUP);
        this.readmore = false;
        this.displayShop();
      }
    }
  }

  /**
 * Checks words for specific characters
 * words in the text can have the noSpaceBefore and noSpaceAfter parameter
 */
  handleSpacing(): void {
    for (let index = 0; index < this.story.textMapping.length; index++) {
      const element = this.story.textMapping[index];

      /**
       * Handels xxxx' + s
       */
      if (element.word.charAt(element.word.length - 1) === "'") {
        element.noSpaceAfter = true;
        this.story.textMapping[index + 1].noSpaceBefore = true;
        continue;
      }

      /**
       * Handels xxxx + 's
       */
      if (element.word.charAt(0) === "'") {
        element.noSpaceBefore = true;
        this.story.textMapping[index - 1].noSpaceAfter = true;
        continue;
      }

      /** Handels _'s_ in then middle of a word */
      if (element.word.includes("'")) {
        const pos = element.word.indexOf("'");
        if (element.word.charAt(pos - 1) === " ") {
          element.word = element.word.slice(0, pos - 1) + element.word.slice(pos);
        }
        continue;
      }

      /** Handels _word"_ in attatched to the wwrong word (only closing, no opening ") */
      if (element.word.includes('“') && (this.languageService.origin == 'ja' || this.languageService.origin == 'zh-hans') && this.story.textMapping[index + 1].word.includes('”')) {
        console.log(element.word, this.story.textMapping[index + 1].word)
        const pos = element.word.indexOf('“');
        element.word = element.word.slice(0, pos)
        this.story.textMapping[index + 1].word = "“" + this.story.textMapping[index + 1].word
        continue;
      }
      if (element.word.includes('"') && (this.languageService.origin == 'ja' || this.languageService.origin == 'zh-hans') && this.story.textMapping[index + 1].word.includes('"')) {
        console.log(element.word, this.story.textMapping[index + 1].word)
        const pos = element.word.indexOf('"');
        element.word = element.word.slice(0, pos)
        this.story.textMapping[index + 1].word = '"' + this.story.textMapping[index + 1].word
        continue;
      }

    }
  }

  playWordAudio(): void {
    if (!this.audioReady) return;
    if (!this.story.free) {
      if (!this.purchaseService.userPremium) {
        this.purchaseService.setPurchaseOrigin(PurchaseOrigin.READMORE);
        this.displayShop();
        return;
      }
    }
    if (this.isWordAudioActive) {
      this.isWordAudioActive = false;
      this.audioService.current_audio.pause();
      return;
    }
    if (this.audioPaused || this.isStoryAudioActive) {
      this.pauseAudioReading();
      this.isStoryAudioActive = false;
    }
    else this.stopAudioReading();

    this.audioService.initNewAudio(this.selectedWord?.audiofile, true);
    this.audioService.setAudioSpeed(this.audioSpeed);
    this.isWordAudioActive = true;
    this.audioService.current_audio.addEventListener('ended', (event) => {
      this.audioService.audio_active = false;
      this.isWordAudioActive = false;
    });
  }

  public toggleStoryAudio() {
    if (this.isPlaySentenceActive) return;
    if (this.isStoryAudioActive) {
      this.pauseAudioReading();
      this.isStoryAudioActive = false;
    } else {
      this.playAudioReading();
      this.isStoryAudioActive = true;
    }
  }

  public toggleRepeat() {
    if (this.repeatToggle) {
      document.getElementById('repeatBtn').style.opacity = '0.5';
      this.repeatToggle = false;
    } else {
      document.getElementById('repeatBtn').style.opacity = "1";
      this.repeatToggle = true;
    }
  }

  public playAudioReading(bounding?: { start: number, end: number }, sentenceIndex?: number) {
    if (!this.audioReady) return;
    this.stopPressed = false;
    if (!this.story.free) {
      if (!this.purchaseService.userPremium) {
        this.purchaseService.setPurchaseOrigin(PurchaseOrigin.READMORE);
        this.displayShop();
        return;
      }
    }
    console.log("play audio")

    this.taptic.impact({ style: 'medium' });
    this.audioPaused = false;
    if (!bounding) {
      this.isPlaySentenceActive = false;
    } else {
      this.isStoryAudioActive = false;
      this.milliseconds = 20;
    }

    this.removeOnboardingStoryInstance();

    this.storage.get('settingHighlight').then((setting) => {
      // eslint-disable-next-line no-constant-condition
      if (this.time == 0) {
        if (setting && this.story.level != 'C1') {
          this.removeAllMarkedSingleWords();
          this.elements = this.createElementArray();

          if (this.isSentenceMarking()) {
            // SENTENCE
            //this.markSentence(0, this.story.audioMarksSentence[0][0]);
            this.array = this.story.audioMarksSentence;

          } else {
            // WORDS
            this.array = this.story.audioMarksWords;
            if (!bounding) this.elements[0].classList.add('word-active'); // erste Elemement manuell triggern
            if (this.isLandscape) this.elements[0].classList.add('webapp-color');
          }
        }

        if (bounding) {
          this.audioReading.currentTime = ((this.story.audioMarksWords[bounding.start] as number - 200) / 1000);
        } else {
          this.sentenceAudio = false;
        }

        this.animateStopIn();
      }

      if (this.isSentenceMarking()) {

        if (!this.array) this.array = this.story.audioMarksSentence;
        if (!this.elements) this.elements = this.createElementArray();

        let tTmp = -1;

        // SENTENCE
        this.audioReadingInterval = setInterval(() => {
          this.time = Math.floor(this.audioReading.currentTime * 1000);

          if (bounding) {
            if (setting && this.story.level != 'C1') {
              this.removeAllMarkedSingleWords();
              this.markSentence(bounding.start, bounding.end + 1);
            }
            let t = this.array[sentenceIndex + 1];
            if (!t) {
              t = this.story.audioMarksWords[this.story.audioMarksWords.length - 1] * 1;
              t = t + 1500;
            } else {
              t = t[1]
              if (this.platform.is("android")) t = t - 300
              else t = t - 200
            };

            if (this.time > t || (this.time == tTmp && (this.time > this.story.audioMarksWords[this.story.audioMarksWords.length - 1] - 1000))) {
              this.endIntervall(200);
              this.stopAudioReading();
              this.isPlaySentenceActive = false;
              this.sentenceAudio = false;
              this.showToolbarStoryAudio();
              tTmp = -1;
            }

            // Needed for the last sentence in the text
            // will check if time is equal to previous time (equal stopped)
            if (tTmp == -1) tTmp = this.time - 100;
            else tTmp = this.time;

          } else {
            if (this.index >= this.array.length) {
              this.endIntervall(Math.floor(this.audioReading.duration * 1000) - this.time + 200);
            } else {
              if (this.time >= this.array[this.index][1]) {
                if (setting && this.story.level != 'C1') {
                  this.removeAllMarkedSingleWords();

                  if (this.currentLastMarkedWordIndex < this.elements.length) {
                    let end = this.currentLastMarkedWordIndex + this.array[this.index][0];
                    this.markSentence(
                      this.currentLastMarkedWordIndex,
                      end
                    );
                    this.scrollToMarkedWord(this.elements[this.currentLastMarkedWordIndex - 1]);
                  }
                }

                this.index++;
              }
            }
          }

          if (bounding) {
            if (this.index >= bounding.end) {
              this.endIntervall(200);
              this.stopAudioReading();
              this.isPlaySentenceActive = false;
              this.hideToolbarStoryAudio();
            }
          }

        }, this.milliseconds);
      } else {

        if (!this.array) this.array = this.story.audioMarksWords;
        if (!this.elements) this.elements = this.createElementArray();

        // WORDS
        this.audioReadingInterval = setInterval(() => {

          this.time = Math.floor(this.audioReading.currentTime * 1000);

          let t = this.array[this.index];
          if (bounding) {
            if (this.platform.is("android")) t = t - 300
            else t = t - 200
          };

          if (this.time >= t) {
            this.index++;
            if (setting && this.story.level != 'C1') {
              if (this.elements[this.index - 2]) {
                this.elements[this.index - 2].classList.remove('word-active');
                if (this.isLandscape) this.elements[this.index - 2].classList.remove('webapp-color');
              }

              this.elements[this.index - 1].classList.add('word-active');
              if (this.isLandscape) this.elements[this.index - 1].classList.add('webapp-color');
              this.scrollToMarkedWord(this.elements[this.index - 1]);
            }


            if (bounding) {
              if (this.index >= bounding.end + 2) {
                setTimeout(() => {
                  this.stopAudioReading();
                  this.endIntervall();
                  this.isPlaySentenceActive = false;
                  this.showToolbarStoryAudio();
                }, 0)
              }
            }

            if (this.index >= this.array.length) {
              this.endIntervall(Math.floor(this.audioReading.duration * 1000) - this.time + 200);
              this.isStoryAudioActive = false;
              this.isPlaySentenceActive = false;
            }
          }
        }, this.milliseconds);
      }
    });

    this.audioReading.play();
    this.audioReading.playbackRate = this.audioSpeed;

    this.audioService.audio_active = true;
    this.audioReadingActive = true;


    this.audioReading.addEventListener('ended', (event) => {

      this.audioService.audio_active = false;
      this.audioReadingActive = false;
      if (this.repeatToggle && !this.sentenceAudio) {
        this.audioReading.removeEventListener('ended', (event) => { });
        setTimeout(() => {
          this.isStoryAudioActive = true;
          this.animateStopIn();
          this.playAudioReading();
        }, 2000);
      }
    });

  }

  private isSentenceMarking(): boolean {
    return this.story.level == 'B1' || this.story.level == 'B2' || this.languageService.origin == 'N3' || this.languageService.origin == 'N2' || this.story.level == 'HSK3' || this.story.level == 'HSK4';
  }

  public pauseAudioReading() {
    if (this.audioReadingActive && !this.isPlaySentenceActive) {
      this.taptic.impact({ style: 'medium' });
      this.audioReading.pause();
      this.isStoryAudioActive = false
      this.audioReadingActive = false;
      this.audioPaused = true;
    }
  }

  public storyaudioStop(deselect = true, taptic = false) {
    if (this.isPlaySentenceActive) return;
    this.stopPressed = true;
    this.stopAudioReading(deselect, taptic)
  }

  public stopAudioReading(deselect = true, taptic = false) {
    console.log("stop audio")
    if (taptic) {
      this.taptic.impact({ style: 'medium' });
    }
    this.audioReading.pause();
    this.audioReading.currentTime = 0;
    this.time = 0;
    this.index = 0;
    this.audioService.audio_active = false;
    this.audioReadingActive = false;
    this.isWordAudioActive = false;
    this.isPlaySentenceActive = false;
    this.isStoryAudioActive = false;
    this.currentLastMarkedWordIndex = 0;
    this.animateStopOut();
    clearInterval(this.audioReadingInterval);
    if (this.audioSpeed == 0.75 && this.repeatToggle && !this.sentenceAudio && !this.stopPressed) {
      setTimeout(() => {
        this.isStoryAudioActive = true;
        this.playAudioReading();
        this.animateStopIn();
      }, 2000);
    }
    if (deselect) {
      this.removeAllMarkedSingleWords();
    }
  }

  endIntervall(offset = 0): void {
    clearInterval(this.audioReadingInterval);
    setTimeout(() => {
      this.stopAudioReading();
      this.isStoryAudioActive = false;
    }, offset);
  }

  markSentence(start, end): void {
    this.currentLastMarkedWordIndex = end;
    // const words = this.createElementArray();
    for (let index = start; index < end; index++) {
      const element = this.elements[index];
      element.classList.add('word-active-sentence');
      if (this.isLandscape) element.classList.add('webapp-color');
      // Round corners for 1st element
      if (index == start) {
        element.style.borderRadius = '5px 0px 0px 5px';
      }

      // Round corners for last element
      if (index == end - 1) {
        element.style.borderRadius = '0px 5px 5px 0px';
      }
    }

    // this.currentLastMarkedWordIndex = end;
  }

  private createElementArray() {
    return document.getElementsByClassName('single-word');
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  public back(home = false) {

    console.log("BACK TRIGGERED")
    this.trainingService.getTrainingSummary()
    this.stopShopScreenTimer();

    if (this.fromDeeplink) {
      timer(500).subscribe(() => {
        this.purchaseService.setPurchaseOrigin(PurchaseOrigin.POPUP);
        this.readmore = true;
        this.displayShop();
      });
    }


    if (this.utilsService.routingName == 'library') this.navCtrl.navigateForward('/my-library');
    else if (this.utilsService.routingName == 'deeplink' && this.utilsService.isWebVersion) this.loginPopup();
    else if (this.utilsService.routingName == 'favorite') this.navCtrl.navigateForward('/my-library/favorites');
    else if (this.utilsService.routingName == 'home') this.navCtrl.navigateForward('/home');
    else if (this.utilsService.routingName == 'learned') this.navCtrl.navigateForward('/my-library/learned-stories');
    else if (this.utilsService.routingName == this.story.level) this.navCtrl.navigateBack(`/level`, {
      queryParams: {
        level: this.story.level
      }
    });
    else if (home || this.fromDeeplink || !this.utilsService.routingName) {
      this.navCtrl.navigateBack('/home');
    }
    else {
      this.navCtrl.pop();
    }

    if (!this.fromDeeplink) {
      timer(150).subscribe(() => {
        this.ratingService.increaseClickedArticles();
      });
    }
    this.stopAudioReading();
    this.isStoryAudioActive = false;
  }

  loginPopup() {
    if (this.signedIn) {
      this.navCtrl.navigateBack('/home');
    } else {
      this.modalController.create({
        component: LoginPage,
        cssClass: 'login-modal',
        componentProps: {
          'disableRegister': false,
          'isModal': true
        }
      }).then((modal) => {
        modal.present();
      });
    }
  }

  ionViewDidLeave() {
    this.stopAudioReading();
  }

  private addWordBlur(): void {
    if (!this.story.free) {
      if (!this.purchaseService.userPremium) {
        this.blurred = true;
      }
    }
  }

  private setBackgroundImage(): void {
    // @ts-ignore
    this.pictureUrl = `${environment.caching.cloudinary}https://dewenbao.com/wp-content/uploads/${this.story.picture?.mediumLarge}`; // TODO: change story interface to new one
    this.isBackgroundSet = true;
  }

  changeTabbar(tab: string): void {

    this.ngZone.run(() => {
      if (!tab) return;
      this.stopAudioReading();
      this.isStoryAudioActive = false;
      this.isPlaySentenceActive = false;
      if (!this.story.free && tab != 'story') {
        if (!this.purchaseService.userPremium) {
          this.purchaseService.setPurchaseOrigin(PurchaseOrigin.READMORE);
          this.displayShop();
          return;
        }
      }

      (tab == 'story') ? this.tabbarStoryActive = true : this.tabbarStoryActive = false;
      (tab == 'keywords') ? this.tabbarKeywordsActive = true : this.tabbarKeywordsActive = false;
      (tab == 'grammar') ? this.tabbarGrammarActive = true : this.tabbarGrammarActive = false;
      (tab == 'quiz') ? this.tabbarQuizActive = true : this.tabbarQuizActive = false;


      if (tab == 'story') this.firebaseAnalytics.setCurrentScreen('screen_story');
      if (tab == 'keywords') this.firebaseAnalytics.setCurrentScreen('screen_keywords');
      if (tab == 'grammar') this.firebaseAnalytics.setCurrentScreen('screen_grammar');
      if (tab == 'quiz') {
        this.firebaseAnalytics.setCurrentScreen('screen_quiz');
        this.storyService.quizTab = this.storyService.quizLast;
      } else {
        this.storyService.quizTab = false;
      }

      this.scrollLowerElementToTop();
    })

  }

  private scrollLowerElementToTop() {
    document.getElementById('article-lower-element').scrollTo(0, 0);
  }

  public quizData(data: any): void {
    this.quizInfo.currentLevel = data.currentLevel;
    this.quizInfo.questionState = data.questionState;
    if (this.quizInfo.currentLevel <= this.quizInfo.questionsAmount) this.renderQuizProgressAnimation(1 - (this.quizInfo.currentLevel / this.quizInfo.questionsAmount));
  }

  renderQuizProgressAnimation(amount: number) {
    const x = document.getElementById('quizProgress');
    const t = this.quizProgressPathLength * (amount);
    this.renderer.setStyle(x, "stroke-dashoffset", `${t}%`)
  }

  public placeSegmentSlider(target: HTMLElement, slider: HTMLElement): void {
    const left = target.getBoundingClientRect().left + target.offsetWidth / 2 - slider.clientWidth / 2;
    this.segment_switch_slider_position = left + 'px';
  }

  public toggleSegmentBtns(element: HTMLElement): void {
    element.classList.add('segment_active');
  }

  public selectSingleWord(wordResultMap: WordResultMap, ev: Event, index: number): void {
    console.log(wordResultMap, this.settingsService.showPinyin, wordResultMap.word.length)
    if (index == this.currentSelectedWordIndex) {
      this.currentSelectedWordIndex = -1;
      this.selectedWord = undefined;
      if (this.story.sentencesMeta) {
        setTimeout(() => {
          const toolbarElementSentence = document.getElementsByClassName('toolbar-element-sentence')[0];
          if (toolbarElementSentence) toolbarElementSentence.classList.remove('show');
        }, 100);

        setTimeout(() => {
          const toolbarElementSentence = document.getElementsByClassName('toolbar-element-sentence')[0];
          if (toolbarElementSentence) toolbarElementSentence.classList.remove('active');
        }, 300);

        if (this.isLandscape) {
          setTimeout(() => {
            this.isShowToolbarElementSentence = false;
            document.getElementById('audioSentence').style.animationName = 'AudioOut';
            document.getElementById('audioWord').style.animationName = 'AudioOut';
          }, 400);
        }
      }
      return;
    }

    if (this.story.sentencesMeta) {
      setTimeout(() => {
        const toolbarElementSentence = document.getElementsByClassName('toolbar-element-sentence')[0];
        if (toolbarElementSentence) toolbarElementSentence.classList.add('active');
      }, 100);

      setTimeout(() => {
        const toolbarElementSentence = document.getElementsByClassName('toolbar-element-sentence')[0];
        if (toolbarElementSentence) toolbarElementSentence.classList.add('show');
      }, 300);
    }

    this.currentSelectedWordIndex = index;
    // const el = <HTMLSelectElement>ev.target;
    if (!(this.blurred && this.currentSelectedWordIndex > 20)) {
      this.removeAllMarkedSingleWords();
      //this.hightlightSingleWord(el);
      // if (this.trainingService.existsInStack(wordResultMap.result._id)) {
      //   wordResultMap.result.inTraining = true;
      // } else {
      //   wordResultMap.result.inTraining = false;
      // }
      if (wordResultMap.result) {
        this.selectedWord = wordResultMap.result;
        this.isShowToolbarElementSentence = true;
        if (this.isLandscape) {
          document.getElementById('audioSentence').style.animationName = 'AudioIn';
          document.getElementById('audioWord').style.animationName = 'AudioIn';
        }
      }
      else this.selectedWord = {} as Word;
    } else {
      this.purchaseService.setPurchaseOrigin(PurchaseOrigin.READMORE);
      this.showShopScreen();
    }
  }

  // private hightlightSingleWord(el: HTMLSelectElement): void {
  //   this.removeAllMarkedSingleWords();
  //   el.classList.add('word-active');
  // }

  private removeAllMarkedSingleWords(): void {
    let list;

    list = Array.from(document.getElementsByClassName('word-active-sentence'));
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      element.classList.remove('word-active-sentence');
      if (this.isLandscape) element.classList.remove('webapp-color');
    }

    list = Array.from(document.getElementsByClassName('word-active'));
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      element.classList.remove('word-active');
      if (this.isLandscape) element.classList.remove('webapp-color');
    }
  }



  scrollToMarkedWord(element: Element): void {
    const x = 200;
    const elementPositionY = window.innerHeight - element.getBoundingClientRect().bottom;
    if (elementPositionY < x) {
      const div = document.getElementById('article-lower-element');
      const diff = x - elementPositionY;
      div.scrollBy({
        top: diff,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  async viewFullWord(): Promise<void> {
    const modal = await this.modalController.create({
      component: WordDetailPage,
      cssClass: 'my-custom-class',
      mode: this.platform.is('ios') ? 'ios' : 'md',
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        data: this.selectedWord
      }
    });
    return await modal.present();
  }

  startFlashcards(): void {
    this.memorizeService.hideTrainingBtn = false;
    this.navCtrl.navigateForward('article/' + this.memorizeService.storyID + '/memorize-keywords', { animated: this.isMobile });
    this.keywordsComponent.startFlashcards();
  }

  private addKeywordsToTraining(): void {
    const batchAddList = [];
    if (this.storyService.isOnboardingKeywordAdded) return;
    for (let index = 0; index < this.story.textMapping.length; index++) {
      const element = this.story.textMapping[index];
      // @ts-ignore
      if (element.keyword) {
        const sentenceData = this.storyService.findSentence(this.story.textMapping, index, this.story);
        let isInStack = this.trainingService.existsInStack(element.result?._id);
        // last parameter is false, because we want to manually collect the data and send it to the server as a batch/list
        // to imporve performance and reduce the amount of requests
        if (!isInStack) {
          // if (this.authService.user) this.trainingService.createStoryDeckCard(element._id, this.story._id, this.languageService.origin, sentenceData.position, sentenceData.totalPosition);
          let t = this.trainingService.addToStack(element.result?._id, sentenceData.sentence, sentenceData.position, this.story._id, element.result, sentenceData.totalPosition, false);
          if (t) batchAddList.push(t);
        }
      }
    }
    this.storyService.isOnboardingKeywordAdded = true;

    if (batchAddList.length > 0) {
      this.trainingService.addTrainingBatchDataToServer(batchAddList);
    }
  }

  /**
   * OVERLAY CONTROLLS
   * TODO: outsource to single component
   */

  private initShowOverlay() {
    if (!this.onboardingService.isActive) return;
    this.isOnboarding = true;
    if (this.authService.user) this.trainingService.createTrainingDeck(this.languageService.origin);
    this.showOverlay();
    setTimeout(() => {
      this.overlayController(false, false);
    }, 2000);
    if (!this.isLandscape) {
      const skipBtn = document.getElementsByClassName('overlay-skip-btn')[0] as HTMLElement;
      const steps = document.getElementsByClassName('overlay-steps')[0];
      if (skipBtn.getBoundingClientRect().left < steps.getBoundingClientRect().right) {
        skipBtn.style.width = skipBtn.getBoundingClientRect().width - (steps.getBoundingClientRect().right - skipBtn.getBoundingClientRect().left) - 20 + 'px';
      }
    }
  }

  private resetHiddenElems(): void {
    // document.getElementsByClassName('overlay-marked-word')[0].classList.add('hide');
    if (this.isLandscape) document.getElementsByClassName('webapp-toolbar')[0].classList.remove('webapp-show');
    document.getElementById('semgent-quiz-label-overlay').classList.add('hide');
    document.getElementById('semgent-keyword-label-overlay').classList.add('hide');
    // document.getElementById('overlay-word-training').classList.add('hide');
    document.getElementById('semgent-grammar-label-overlay').classList.add('hide');
  }

  public overlayController(event, prev: boolean): void {
    if (event) {
      event.stopPropagation();
    }
    if (prev) {
      this.overlayCurrentElement = this.overlayCurrentElement - 2;
      this.tooltipNumber = this.tooltipNumber - 2;
      this.resetHiddenElems();
    }
    switch (this.overlayCurrentElement) {
      case -1:
        if (!this.utilsService.seenReview) {
          this.navCtrl.back();
          this.utilsService.seenReview = true;
        }
        this.navCtrl.back();
        break;
      case 0:
        if (prev) {
          if (this.isTranslated) {
            const translateSentence = document.getElementsByClassName('overlay-sentence')[0] as HTMLElement;
            translateSentence.classList.add('hide');
          }
        }
        setTimeout(() => {
          this.renderOverlayWord();
        }, 0);
        break;
      case 1:
        if (this.isTranslated) {
          this.changeTabbar('story');
          this.renderOverlayTranslate();
          break;
        } else {
          if (prev) {
            this.overlayCurrentElement--;
            setTimeout(() => {
              this.renderOverlayWord();
            }, 0);
            break;
          }
          else this.overlayCurrentElement++;
        }
      case 2:
        if (prev) {
          this.changeTabbar('story');
          setTimeout(() => {
            this.renderOverlayPlayAudio();
          }, 0);
        } else {
          this.renderOverlayPlayAudio();
        }
        break;
      case 3:
        this.renderOverlayQuiz();
        break;
      case 4:
        this.renderOverlayKeyword();
        break;
      case 5:
        if (prev) {
          const grammarCard = document.getElementsByClassName('article-lower')[0] as HTMLElement;
          grammarCard.style.zIndex = 'unset';
          this.changeTabbar('keywords');
          setTimeout(() => {
            this.renderOverlayTraining();
          }, 0);
        } else {
          this.renderOverlayTraining();
        }
        break;
      case 6:
        if (this.story.grammar && this.story.grammar.length > 0 && this.story.grammar[0].grammarId?.content) {
          const find = this.story.grammar[0].grammarId.content?.find(g => g.language === this.languageService.target);
          if (!find || find.name == '' || (find.description == "" && (find.contentShort.length == 0 || find.contentLong.length == 0))) {
            this.changeTabbar('story');
            this.hideOverlay();
            break;
          }
          else {
            this.renderOverlayGrammar();
            break;
          }
        }
      default:
        this.changeTabbar('story');
        this.hideOverlay();
        break;
    }
    this.overlayCurrentElement++;
    this.tooltipNumber++
  }


  private tryStory(): void {
    this.onboardingService.isTutorial = false;
    timer(300).subscribe(() => {
      document.getElementsByClassName('overlayFinal')[0].classList.remove('hide');
      document.getElementById('progress-success-content-final').classList.remove('hide');
      timer(300).subscribe(() => {
        document.getElementById('progress-success-content-final').classList.add('visible');
      })
    })
    const overlay2 = document.getElementsByClassName('overlay-footer')[0];
    this.changeTabbar('story');
    overlay2.classList.add('hide');
    if (this.isTranslated && (this.story.level.toLocaleLowerCase() == 'a1' || this.story.level.toLocaleLowerCase() == 'hsk1' || this.story.level.toLocaleLowerCase() == 'n5')) {
      this.toggleSentenceTranslate();
      this.storage.set('sentenceTranslate', true);
    }
  }

  private renderFinalTooltip(): void {
    this.finalTooltip = true;
  }

  private showOverlay(): void {
    const overlay = document.getElementsByClassName('overlay')[0];
    const overlay2 = document.getElementsByClassName('overlay-footer')[0];
    overlay.classList.remove('hide');
    overlay2.classList.remove('hide');
    const skipbtn = document.getElementsByClassName('overlay-skip-btn')[0];
    const stepsOverlay = document.getElementsByClassName('overlay-steps')[0];
    skipbtn.classList.remove('hide');
    stepsOverlay.classList.remove('hide');
    this.firebaseAnalytics.setCurrentScreen("screen_onboarding_story_first_tooltip");
    if (this.isLandscape) {
      const playBtnOverlay = document.getElementsByClassName('webapp-toolbar')[0] as HTMLElement;
      playBtnOverlay.style.left = '25%';
    }
  }

  /**
   * Renders quiz label in the overlay
   * (Rendering in this context means: set display-style to show
   * and set it's position based on it's target language in the article
   * screen.)
   * [This also applys also for the following render methods]
   *
   * TODO Spelling-error in word "semgent" used as identifiers
   */
  private renderOverlayQuiz() {
    let playBtnOverlay;
    const label = this.tabbarQuiz.nativeElement; // normal article quiz label
    const labelOverlay = document.getElementById('semgent-quiz-label-overlay') as HTMLElement;
    if (this.isLandscape) {
      playBtnOverlay = document.getElementsByClassName('webapp-toolbar')[0];
    } else {
      playBtnOverlay = document.getElementById('toolbar');
    }
    const triangle = document.getElementsByClassName("triangle")[0] as HTMLElement;
    const bubble = document.getElementsByClassName('introduction-bubble')[0];
    triangle.classList.remove('rotate');
    playBtnOverlay.style.zIndex = '3';
    this.changeTabbar('quiz');
    const slides = document.getElementsByClassName('quiz-wrapper')[0] as HTMLElement;

    setTimeout(() => {
      slides.style.zIndex = '7';
      slides.style.position = 'relative'
    }, 700);


    labelOverlay.classList.remove('hide');
    if (this.isLandscape) document.getElementsByClassName('webapp-toolbar')[0].classList.remove('webapp-show');
    let topValue = label.getBoundingClientRect().top;
    if (this.isLandscape) {
      const bannerImg = this.storyPictureContainer.nativeElement;
      topValue = bannerImg.getBoundingClientRect().bottom + 10;
    }
    labelOverlay.style.top = topValue + 'px';
    labelOverlay.style.left = label.getBoundingClientRect().left - 0 + 'px';
    labelOverlay.style.width = label.getBoundingClientRect().width + 'px'; // set overlay label to the width of the target-element
    this.changeOverlayBubbleText(this.languageService.label.txt_introduction_select_quiz, 250);
    setTimeout(() => {
      this.moveBubbleToPoint(
        labelOverlay.getBoundingClientRect().top,
        labelOverlay.getBoundingClientRect().left - 45,
        200,
      );
      triangle.style.top = bubble.getBoundingClientRect().height - 17 + 'px';
      this.moveBubbleTriangle(60, 200);
    }, 250);
  }

  /**
   * Renders keyword label in the overlay
   */
  private renderOverlayKeyword(): void {
    const bubble = document.getElementsByClassName('introduction-bubble')[0];
    const label = this.tabbarKeywords.nativeElement;
    const labelOverlay = document.getElementById('semgent-keyword-label-overlay') as HTMLElement;
    this.changeTabbar('keywords');
    const keywordBlock = document.getElementsByClassName('karte karte-keyword')[0] as HTMLElement;
    keywordBlock.style.zIndex = '8';

    labelOverlay.classList.remove('hide');
    document.getElementById('semgent-quiz-label-overlay').classList.add('hide');
    let topValue = label.getBoundingClientRect().top;
    if (this.isLandscape) {
      const bannerImg = this.storyPictureContainer.nativeElement;
      topValue = bannerImg.getBoundingClientRect().bottom + 10;
    }
    labelOverlay.style.top = topValue + 'px';
    labelOverlay.style.left = label.getBoundingClientRect().left + 'px';
    labelOverlay.style.width = label.getBoundingClientRect().width + 'px'; // set overlay label to the width of the target-element
    this.changeOverlayBubbleText(this.languageService.label.txt_introduction_select_keyword, 250);
    const triangle = document.getElementsByClassName('triangle')[0] as HTMLElement;
    setTimeout(() => {
      this.moveBubbleToPoint(
        labelOverlay.getBoundingClientRect().top,
        labelOverlay.getBoundingClientRect().left - 35,
        200,
      );
      triangle.style.top = bubble.getBoundingClientRect().height - 17 + 'px';
      this.moveBubbleTriangle(60, 200);
    }, 250);


  }

  private renderOverlayTraining(): void {
    const bubble = document.getElementsByClassName('introduction-bubble')[0];
    const trainingBtn = document.getElementsByClassName('keyword-btn-icon')[0] as HTMLElement;
    if (!trainingBtn.classList.contains('keyword-training-active')) trainingBtn.click();

    this.changeOverlayBubbleText(this.languageService.label.txt_introduction_select_training, 250);
    const triangle = document.getElementsByClassName('triangle')[0] as HTMLElement;
    setTimeout(() => {
      this.moveBubbleToPoint(
        trainingBtn.getBoundingClientRect().top,
        trainingBtn.getBoundingClientRect().left - 75,
        250,
      );
      triangle.style.top = bubble.getBoundingClientRect().height - 17 + 'px';
      this.moveBubbleTriangle(104, 200);
    }, 250);



    return;
  }

  private renderOverlayGrammar(): void {
    const label = this.tabbarGrammar.nativeElement;
    const labelOverlay = document.getElementById('semgent-grammar-label-overlay') as HTMLElement;
    const grammarCard = document.getElementsByClassName('article-lower')[0] as HTMLElement;
    grammarCard.style.position = 'relative';
    setTimeout(() => {
      grammarCard.style.zIndex = '6';
    }, 200);

    const bubble = document.getElementsByClassName('introduction-bubble')[0];
    const triangle = document.getElementsByClassName('triangle')[0] as HTMLElement;

    document.getElementById('semgent-keyword-label-overlay').classList.add('hide');
    // document.getElementById('overlay-word-training').classList.add('hide');
    this.changeTabbar('grammar');
    labelOverlay.classList.remove('hide');
    document.getElementById('semgent-keyword-label-overlay').classList.add('hide');
    let topValue = label.getBoundingClientRect().top;
    if (this.isLandscape) {
      const bannerImg = this.storyPictureContainer.nativeElement;
      topValue = bannerImg.getBoundingClientRect().bottom + 10;
    }

    labelOverlay.style.top = topValue + 'px';
    labelOverlay.style.width = label.getBoundingClientRect().width + 'px';
    labelOverlay.style.left = label.getBoundingClientRect().left + 'px';

    this.changeOverlayBubbleText(this.languageService.label.txt_introduction_select_grammar, 250);
    setTimeout(() => {

      this.moveBubbleToPoint(
        labelOverlay.getBoundingClientRect().top,
        labelOverlay.getBoundingClientRect().right - bubble.getBoundingClientRect().width, 200);
      triangle.style.top = bubble.getBoundingClientRect().height - 17 + 'px';
      this.moveBubbleTriangle(
        bubble.getBoundingClientRect().width -
        labelOverlay.getBoundingClientRect().width / 2 -
        triangle.getBoundingClientRect().width / 2,
        200,
      );
    }, 250);
  }

  private renderOverlayWord(): void {
    this.changeOverlayBubbleText(this.languageService.label.txt_introduction_select_word, 250);

    let playBtnOverlay, translation;
    if (this.isLandscape) {
      playBtnOverlay = document.getElementsByClassName('webapp-toolbar')[0];
      translation = document.getElementsByClassName('webapp-right')[0] as HTMLElement;
      if (playBtnOverlay.classList.contains('webapp-show')) playBtnOverlay.classList.remove('webapp-show');
    } else {
      playBtnOverlay = document.getElementById('toolbar');
      translation = document.getElementsByClassName('article-upper-header')[0] as HTMLElement;
    }
    playBtnOverlay.style.zIndex = '3';

    this.changeTabbar('story');
    if (!this.story.textMapping[0]?.result?.category) {
      setTimeout(() => {
        this.renderOverlayWord();
        return;
      }, 50);
    } else {
      let wordIndex = 0;
      let verbLength = 0;
      if (this.languageService.origin == 'ja') verbLength = 1;
      else verbLength = 4;
      // Search first verb and save index
      for (let index = 0; index < this.story.textMapping?.length; index++) {
        const element = this.story.textMapping[index];
        if (element.result.category === 'verb' && (element.result.display.length > verbLength)) {
          wordIndex = index;
          break;
        }
      }
      const triangle = document.getElementsByClassName('triangle')[0] as HTMLElement;
      const element = document.getElementsByClassName('single-word')[wordIndex] as HTMLElement;
      this.firstVerb = element;
      const bubble = document.getElementsByClassName('introduction-bubble')[0];


      element.style.position = 'relative';
      element.style.zIndex = '6';
      element.style.background = 'var(--story-background-main)';
      element.style.borderRadius = '8px';

      if (!element) {
        setTimeout(() => {
          this.renderOverlayWord();
          return;
        }, 200);
      }

      // const style_bubble = 'top:' + top_bubble + 'px;left:' + left_bubble + 'px;';
      setTimeout(() => {
        if (this.isTranslated) {
          const translateIcon = document.getElementById('translateIcon') as HTMLElement;
          translateIcon.style.zIndex = '1';
          if (this.translationActive) translateIcon.click();
        }
        let left_bubble = element.getBoundingClientRect().left - 10;
        if (left_bubble + 170 >= window.innerWidth) {
          let adjust;
          left_bubble = left_bubble - ((left_bubble + 170) - window.innerWidth);
          if (element.getBoundingClientRect().left > triangle.getBoundingClientRect().left) adjust = element.getBoundingClientRect().left - left_bubble;
          else adjust = left_bubble - element.getBoundingClientRect().left;
          this.moveBubbleTriangle(adjust, 100);
        } else {
          this.moveBubbleTriangle(15, 100);
        }
        const top_bubble = element.getBoundingClientRect().top;
        if (element.classList.contains('currentSelected')) translation.classList.add('overlayTranslation');

        this.moveBubbleToPoint(
          top_bubble,
          left_bubble,
          200,
        );
        triangle.style.top = bubble.getBoundingClientRect().height - 17 + 'px';
      }, 250);
      // bubble.setAttribute('style', style_bubble);
      bubble.classList.remove('hide');

      if (this.isLandscape && this.tutorialStarted) {
        setTimeout(() => {
          const transCard = <HTMLScriptElement>document.getElementsByClassName('translate-wrapper')[0];
          if (!transCard) {
            setTimeout(() => {
              this.renderOverlayWord();
              return;
            }, 200);
          }
        }, 100);
      }
    }
  }
  private renderOverlayTranslate(): void {
    let playBtnOverlay, translation;
    if (this.isLandscape) {
      playBtnOverlay = document.getElementsByClassName('webapp-toolbar')[0];
      translation = document.getElementsByClassName('webapp-right')[0] as HTMLElement;
      if (playBtnOverlay.classList.contains('webapp-show')) playBtnOverlay.classList.remove('webapp-show');
    } else {
      playBtnOverlay = document.getElementById('toolbar');
      translation = document.getElementsByClassName('article-upper-header')[0] as HTMLElement;
    }
    playBtnOverlay.style.zIndex = '3';
    this.changeOverlayBubbleText(this.languageService.label.txt_introduction_translate, 250);
    const translateIcon = document.getElementById('translateIcon') as HTMLElement;
    const overlaySentence = document.getElementsByClassName('overlay-sentence')[0] as HTMLElement;
    if (!this.firstVerb.classList.contains('currentSelected')) this.firstVerb.click();
    const triangle = document.getElementsByClassName('triangle')[0] as HTMLElement;
    const bubble = document.getElementsByClassName('introduction-bubble')[0];
    this.firstVerb.style.zIndex = '1';
    translateIcon.click();
    const translateSentence = document.getElementsByClassName('translation-sentence')[0] as HTMLElement;
    setTimeout(() => {
      overlaySentence.innerHTML = translateSentence.innerHTML;
      translateIcon.style.zIndex = '6';
      overlaySentence.classList.remove('hide');
      overlaySentence.style.left = translateSentence.getBoundingClientRect().left - 8 + 'px';
      overlaySentence.style.top = translateSentence.getBoundingClientRect().top - 5 + 'px';


      this.moveBubbleToPoint(
        translateIcon.getBoundingClientRect().top,
        translateIcon.getBoundingClientRect().left - 10,
        100,
      );
      triangle.style.top = bubble.getBoundingClientRect().height - 17 + 'px';
      this.moveBubbleTriangle(15, 100)
      translation.classList.remove('overlayTranslation');
    }, 250);

  }

  endTutorial() {
    this.taptic.impact({ style: 'medium' });
    document.getElementsByClassName('overlayFinal')[0].classList.add('hide');
    document.getElementById('progressFinal').classList.add('hide');
    const slides = document.getElementsByClassName('quiz-wrapper')[0] as HTMLElement;
    if (this.firstVerb) this.firstVerb.style.background = null;
    if (this.firstVerb) this.firstVerb.style.borderRadius = null;
    if (this.firstVerb) this.firstVerb.click();
    if (this.isLandscape) {
      const playBtnOverlay = document.getElementsByClassName('webapp-toolbar')[0] as HTMLElement;
      playBtnOverlay.style.left = '';
    }
    setTimeout(() => {
      slides.style.zIndex = '1';
      slides.style.position = 'relative';
      this.renderFinalTooltip();
    }, 300);

    this.startShopScreenTimer();
    this.renderFinalTooltip();
  }

  private renderOverlayPlayAudio() {
    //if there is translations avaliable, reset that step

    //if word was not tapped by the user we set it as clicked for the user to see
    if (!this.firstVerb.classList.contains('currentSelected')) this.firstVerb.click();


    const slides = document.getElementsByClassName('quiz-wrapper')[0] as HTMLElement;//for the tooltip to not go above the quiz if user goes back and forth again
    slides.style.zIndex = '1';
    slides.style.position = 'relative'

    this.firstVerb.style.zIndex = '1';
    let playBtnOverlay, translation;
    if (this.isLandscape) {
      playBtnOverlay = document.getElementsByClassName('webapp-toolbar')[0];
      translation = document.getElementsByClassName('webapp-right')[0] as HTMLElement;
    } else {
      playBtnOverlay = document.getElementById('toolbar');
      translation = document.getElementsByClassName('article-upper-header')[0] as HTMLElement;
    }
    const triangle = document.getElementsByClassName("triangle")[0] as HTMLElement;
    const bubble = document.getElementsByClassName('introduction-bubble')[0] as HTMLElement;

    if (this.isLandscape) {
      playBtnOverlay.classList.add('webapp-show');
    } else {
      playBtnOverlay.classList.remove('hide');
    }
    playBtnOverlay.style.zIndex = '7';
    bubble.style.animation = 'none';
    this.changeOverlayBubbleText(this.languageService.label.txt_introduction_select_audio, 250);

    setTimeout(() => {
      if (this.isTranslated) {
        const translateIcon = document.getElementById('translateIcon') as HTMLElement;
        translateIcon.style.zIndex = '1';
        if (this.translationActive) translateIcon.click();
        const translateSentence = document.getElementsByClassName('overlay-sentence')[0] as HTMLElement;
        translateSentence.classList.add('hide');
      }
      translation.classList.remove('overlayTranslation');
      this.moveBubbleToPoint(
        playBtnOverlay.getBoundingClientRect().top,
        playBtnOverlay.getBoundingClientRect().left - 50,
        200,
      );
      triangle.style.top = bubble.getBoundingClientRect().height - 17 + 'px'
      this.moveBubbleTriangle(60, 200);
    }, 250);



    triangle.classList.add('rotate');
  }

  private moveBubbleToPoint(top: number, left: number, duration: number) {
    const bubble = document.getElementsByClassName('introduction-bubble')[0];
    if (top == 0) {
      top = bubble.getBoundingClientRect().top;
    } else {
      top = top - bubble.getBoundingClientRect().height - 20;
    }
    if (left == 0) {
      left = bubble.getBoundingClientRect().left;
    }

    anime({
      targets: '.introduction-bubble',
      top: top + 'px',
      left: left + 'px',
      easing: 'cubicBezier(.5, .05, .1, .3)',
      duration: duration,
    });
  }

  private moveBubbleTriangle(left: number, duration: number) {
    anime({
      targets: '.triangle',
      left: left + 'px',
      easing: 'linear',
      duration: duration,
    });
  }

  private startShopScreenTimer(): void {
    if (!SHOPTIMER) {
      return;
    }
    this.shopTimer = setTimeout(() => {
      this.displayShop();
    }, 50000);
  }

  private stopShopScreenTimer(): void {
    if (!SHOPTIMER) {
      return;
    }
    if (this.shopTimer) {
      clearTimeout(this.shopTimer);
    }
  }

  public hideOverlay(): void {
    this.tryStory();
    const grammarCard = document.getElementsByClassName('article-lower')[0] as HTMLElement;
    grammarCard.style.zIndex = '1';
    this.firebaseAnalytics.setCurrentScreen("screen_onboarding_try_story");
    const overlay = document.getElementsByClassName('overlay')[0];
    const skipbtn = document.getElementsByClassName('overlay-skip-btn')[0];
    const stepsOverlay = document.getElementsByClassName('overlay-steps')[0];
    const overlay2 = document.getElementsByClassName('overlay-footer')[0];
    const element: HTMLElement = document.getElementsByClassName('single-word')[1] as HTMLElement;
    this.changeTabbar('story');
    overlay.classList.add('hide');
    overlay2.classList.add('hide');
    skipbtn.classList.add('hide');
    stepsOverlay.classList.add('hide');
    element.click();

    const toolbar = document.getElementById('toolbar');
    toolbar.classList.add('active');
    toolbar.style.zIndex = '7';
    this.isAudiobarLeft = true;
    this.firebaseAnalytics.logEvent('button_skip_onboarding', {});
  }

  public showShopScreen(): void {
    this.stopShopScreenTimer();
    this.displayShop();
  }

  private removePhrasesFromKeywords() {
    for (let index = 0; index < this.story.textMapping.length; index++) {
      const element = this.story.textMapping[index];
      if (element.result?.category && element.result?.category === WordCategory.PHRASE) {
        element.keyword = false;
      }
    }
  }

  private addPhrasesToKeywords() {
    const addedIds = [];
    for (let index = 0; index < this.story.textMapping.length; index++) {
      const element = this.story.textMapping[index];
      if (element.result?.category && element.result?.category == WordCategory.PHRASE && !addedIds.includes(element.result._id)) {
        element.keyword = true;
        addedIds.push(element.result._id);
      }
    }
  }

  goToNextScreen() {
    this.onboardingService.isActive = true;
    // this.showProgressbar = false;
    if (this.utilsService.isWebVersion) {
      if (this.onboardingService.didRegister) this.navCtrl.navigateRoot('/select-your-plan');
      else this.navCtrl.navigateForward('/editor-review');
    } else {

      if (this.purchaseService.user_China && !environment.single) {
        this.navCtrl.navigateRoot('/onboarding-register');
        this.addKeywordsToTraining();
        return
      }
      else if (!this.utilsService.showReview) {
        this.navCtrl.navigateForward('/onboarding-shop');
      } else {
        this.navCtrl.navigateForward('/editor-review');
      }

    }
    this.addKeywordsToTraining();
  }

  private changeOverlayBubbleText(text: string, delay: number) {
    setTimeout(() => {
      document.getElementById('introduction-bubble-text').innerHTML = text;
    }, delay);
  }

  /**
   * Takes controll of changing speed
   */
  public audioSpeedToggle(): void {
    if (this.isPlaySentenceActive && !this.isLandscape) return;
    //const uiElement = document.getElementsByClassName('audioSpeedBtn')[0];
    if (this.audioSpeed == 1) {
      console.log(0.75)
      this.audioSpeed = 0.75;
      this.storage.set('audioSpeed', 0.75)
      this.audioReading.playbackRate = this.audioSpeed;
      return;

    }

    if (this.audioSpeed == 0.75) {
      console.log(1.25)
      this.audioSpeed = 1.25;
      this.storage.set('audioSpeed', 1.25)
      this.audioReading.playbackRate = this.audioSpeed;
      return;
    }

    if (this.audioSpeed == 1.25) {
      console.log(1)
      this.audioSpeed = 1;
      this.storage.set('audioSpeed', 1)
      this.audioReading.playbackRate = this.audioSpeed;
      return;
    }
  }

  private animateStopIn() {
    if (this.isPlaySentenceActive) return;

    const tl = anime.timeline({
      easing: 'easeOutCubic',
    });

    tl.add(
      {
        targets: '#audioStoryStop',
        width: '34px',
        marginLeft: "5px",
        duration: 200,
      },
      0,
    ).add(
      {
        targets: '#audioStoryStop',
        opacity: '1',
        duration: 200,
      },
      200,
    );
  }

  private animateStopOut() {

    if (this.isPlaySentenceActive) return;

    const tl = anime.timeline({
      easing: 'easeOutCubic',
    });

    tl.add(
      {
        targets: '#audioStoryStop',
        opacity: '0',
        duration: 200,
      },
      0,
    ).add(
      {
        targets: '#audioStoryStop',
        width: '0px',
        // marginRight: "0px",
        marginLeft: "0px",
        duration: 200,
      },
      200,
    );
  }

  underlineWordsInTraining() {
    if (this.settingsService.underlineTraining) this.settingsService.underlineTraining = false;
    else this.settingsService.underlineTraining = true;
    this.storage.set('underlineTraining', this.settingsService.underlineTraining);
  }

  // Change font size of grammar sentence as the html
  // is inside the label and cannot be changed with angular-css
  // it has to be changes via vanilla javascript
  changeFontSize(num: number): void {
    if (this.onboardingService.isActive) {
      this.firebaseAnalytics.logEvent(`font_size_changed_onboarding`, {});
    } else {
      this.firebaseAnalytics.logEvent(`font_size_changed_story`, {});
    }
    this.storage.set('settingsFont', num);
    this.settingsService.fontSize = num;
    let r = document.querySelector(':root') as HTMLElement;
    const temp = this.wordService.enlargeFont;
    if (num == 3) {
      r.style.setProperty('--font-size-mult', '4px');
      this.wordService.enlargeFont = true;
    } else if (num == 2) {
      this.wordService.enlargeFont = true;
      r.style.setProperty('--font-size-mult', '2px');
    } else {
      this.wordService.enlargeFont = false;
    }
    if (temp != this.wordService.enlargeFont) {
      this.setGrammarFontSize();
      this.setTranslationFontSize();
      this.setRomajiFontSize();
      this.setHiraganaFontSize();
    }
    this.taptic.impact({ style: 'medium' });
  }

  private setRomajiFontSize() {
    if (this.languageService.origin != 'ja') return
    if (this.wordService.enlargeFont) {
      const elements = document.getElementsByClassName('romaji-sentence');
      for (let index = 0; index < elements.length; index++) {
        const element: HTMLElement = elements[index] as HTMLElement;
        // set element font size to 18px
        element.style.fontSize = "calc(18px + var(--font-size-mult))";
      }
    }
    else {
      const elements = document.getElementsByClassName('romaji-sentence');
      for (let index = 0; index < elements.length; index++) {
        const element: HTMLElement = elements[index] as HTMLElement;
        element.style.fontSize = "16px";
      }
    }
  }
  private setHiraganaFontSize() {
    if (this.languageService.origin != 'ja') return
    if (this.wordService.enlargeFont) {
      const elements = document.getElementsByClassName('hiragana-words');
      for (let index = 0; index < elements.length; index++) {
        const element: HTMLElement = elements[index] as HTMLElement;
        // set element font size to 18px
        element.style.fontSize = "calc(12px + var(--font-size-mult))";
      }
    }
    else {
      const elements = document.getElementsByClassName('hiragana-words');
      for (let index = 0; index < elements.length; index++) {
        const element: HTMLElement = elements[index] as HTMLElement;
        element.style.fontSize = "12px";
      }
    }
  }

  private setTranslationFontSize() {
    if (this.wordService.enlargeFont) this.setTranslationFontSizeBig();
    else this.setTranslationFontSizeSmall();
  }

  private setTranslationFontSizeBig() {
    const elements = document.getElementsByClassName('translation-sentence');
    for (let index = 0; index < elements.length; index++) {
      const element: HTMLElement = elements[index] as HTMLElement;
      // set element font size to 18px
      element.style.fontSize = "calc(18px + var(--font-size-mult))";
    }
  }

  private setTranslationFontSizeSmall() {
    const elements = document.getElementsByClassName('translation-sentence');
    for (let index = 0; index < elements.length; index++) {
      const element: HTMLElement = elements[index] as HTMLElement;
      element.style.fontSize = "17px";
    }
  }

  private setGrammarFontSize() {
    if (this.wordService.enlargeFont) this.setGrammarFontSizeBig();
    else this.setGrammarFontSizeSmall();
  }

  private setGrammarFontSizeBig() {
    const elements = document.getElementsByClassName('grammar-example');
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      // set element font size to 18px
      element.setAttribute('style', 'font-size:calc(18px + var(--font-size-mult))');
    }
  }

  private setGrammarFontSizeSmall() {
    const elements = document.getElementsByClassName('grammar-example');
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.setAttribute('style', 'font-size: 17px');
    }
  }

  favCheck(): void {
    this.taptic.impact({ style: 'medium' });
    if (this.isFavorite) {
      this.storageService.removeFavorite(this.story._id);
      this.isFavorite = false;
      this.storyService.updateArticleFavorites(this.story, false);
    } else {
      this.isFavorite = true;
      this.storageService.addFavorite(this.story._id);
      this.storyService.updateArticleFavorites(this.story, true);
    }
  }

  reportError() {
    if (this.settingActive) {
      this.settingsPane.destroy({ animate: true });
      this.settingActive = false;
    }
    this.authService.activeReport = true;
    setTimeout(() => {
      const modal = document.getElementById('progressFinal') as HTMLElement
      modal.style.opacity = '1';
      if (this.platform.is('ipad') || this.platform.is('iphone')) {
        modal.style.transform = 'translate(-50%, 0px)';
        modal.style.top = 'calc(var(--ion-safe-area-top) + 10px';
      }
    }, 200);
  }

  closeReport() {
    this.authService.activeReport = false;
    const modal = document.getElementById('progressFinal') as HTMLElement
    modal.style.opacity = '0';
  }

  shareModal() {
    this.activeShare = true;
  }

  closeShare() {
    this.activeShare = false;
  }
  closeNoteTranslations() {
    this.showNoteTranslations = false;
    this.storage.set('firstStoryAITranslations', true);
  }

  toggleHiragana(): void {
    if (this.hiraganaActive) {
      this.settingsService.showHiragana = false;
      this.storage.set('showHiragana', false)
      this.hiraganaActive = false;
    } else {
      this.settingsService.showHiragana = true;
      this.storage.set('showHiragana', true)
      this.hiraganaActive = true;
    }
    console.log(this.hiraganaActive)
  }

  toggleRomaji(): void {
    if (this.romajiActive) {
      this.settingsService.showRomaji = false;
      this.storage.set('showRomaji', false)
      this.romajiActive = false;
      const sentences = document.getElementsByClassName('romaji-sentence');
      for (let index = 0; index < sentences.length; index++) {
        sentences[index].classList.add('hide');
      }
    } else {
      this.settingsService.showRomaji = true;
      this.storage.set('showRomaji', true)
      this.romajiActive = true;
      const sentences = document.getElementsByClassName('romaji-sentence');
      if (sentences.length > 0) {
        for (let index = 0; index < sentences.length; index++) {
          sentences[index].classList.remove('hide');
        }
      } else {

        for (let index = 0; index < this.story.sentencesMeta.length; index++) {
          const lastWordIndex = this.story.sentencesMeta[index].end;
          const element: HTMLElement = document.getElementsByClassName('single-word')[lastWordIndex] as HTMLElement;

          const text = document.createElement("div");
          text.innerHTML = this.story.sentencesMeta[index].romanji;
          text.classList.add("romaji-sentence");
          if ((!this.purchaseService.userPremium && !this.story.free) && index > 1) text.classList.add('blurWord');
          if (this.wordService.enlargeFont) text.style.fontSize = "calc(18px + var(--font-size-mult))";
          text.style.margin = "10px 0";
          text.style.color = "#696870";
          element.parentNode.insertBefore(text, element.nextSibling)
        }
      }
    }
  }

  togglePinyin() {
    if (this.pinyinActive) {
      this.pinyinActive = false;
      this.storage.set('showPinyin', false)
      this.settingsService.showPinyin = false;
      const sentences = document.getElementsByClassName('romaji-sentence');
      for (let index = 0; index < sentences.length; index++) {
        sentences[index].classList.add('hide');
      }
    } else {
      this.pinyinActive = true;
      this.settingsService.showPinyin = true;
      this.storage.set('showPinyin', true)
      const sentences = document.getElementsByClassName('romaji-sentence');
      if (sentences.length > 0) {
        for (let index = 0; index < sentences.length; index++) {
          sentences[index].classList.remove('hide');
        }
      } else {

        for (let index = 0; index < this.story.sentencesMeta.length; index++) {
          const lastWordIndex = this.story.sentencesMeta[index].end;
          const element: HTMLElement = document.getElementsByClassName('single-word')[lastWordIndex] as HTMLElement;

          const text = document.createElement("div");
          text.innerHTML = this.story.sentencesMeta[index].pinyin;
          text.classList.add("romaji-sentence");
          if ((!this.purchaseService.userPremium && !this.story.free) && index > 1) text.classList.add('blurWord');
          if (this.wordService.enlargeFont) text.style.fontSize = "calc(18px + var(--font-size-mult))";
          text.style.margin = "10px 0";
          text.style.color = "#696870";
          element.parentNode.insertBefore(text, element.nextSibling)
        }
      }
    }
  }

  toggleSentenceTranslate(): void {
    const overlaySentence = document.getElementsByClassName('overlay-sentence')[0] as HTMLElement;
    if (this.translationActive) {
      if (this.onboardingService.isTutorial) overlaySentence.classList.add('hide');
      this.translationActive = false;
      const sentences = document.getElementsByClassName('translation-sentence');
      for (let index = 0; index < sentences.length; index++) {
        sentences[index].classList.add('hide');
      }
    } else {
      if (this.onboardingService.isTutorial) overlaySentence.classList.remove('hide');
      this.translationActive = true;
      const sentences = document.getElementsByClassName('translation-sentence');
      if (sentences.length > 0) {
        for (let index = 0; index < sentences.length; index++) {
          sentences[index].classList.remove('hide');
        }
      } else {

        for (let index = 0; index < this.story.sentencesMeta.length; index++) {
          const lastWordIndex = this.story.sentencesMeta[index].end;
          let element: HTMLElement = document.getElementsByClassName('single-word')[lastWordIndex] as HTMLElement;
          const sentences = document.getElementsByClassName('romaji-sentence');
          if (sentences.length > 0) element = document.getElementsByClassName('romaji-sentence')[index] as HTMLElement
          const text = document.createElement("div");
          text.innerHTML = this.story.sentencesMeta[index].translations[this.traslationIndex].text;
          text.classList.add("translation-sentence");
          if ((!this.purchaseService.userPremium && !this.story.free) && index > 1) text.classList.add('blurWord');
          if (this.utilsService.isDarkMode && !environment.single) text.style.color = "#8F92CF";
          else if (!environment.single) text.style.color = "#777BC5";
          else if (this.utilsService.isDarkMode && environment.single) text.style.color = "#1abc9c";
          else text.style.color = "#17a589";
          if (this.wordService.enlargeFont) text.style.fontSize = "calc(18px + var(--font-size-mult))";
          text.style.margin = "10px 0";
          element.parentNode.insertBefore(text, element.nextSibling)
        }
      }
    }
    this.storage.set('sentenceTranslate', this.translationActive);
  }

  toggleTitle(): void {
    if (this.isTranslated) {
      this.UI_show_translation = !this.UI_show_translation;
    }
  }

  async displayShop(): Promise<void> {
    if (this.purchaseService.userPremium) return;
    if (this.utilsService.isWebVersion) {
      const mode = 'md';
      const modal = await this.modalController.create({
        component: ShopPage,
        cssClass: 'shop-modal-web',
        mode: mode,
        swipeToClose: true,
        presentingElement: this.routerOutlet.nativeEl,
      });
      return await modal.present();
    }
    else if (this.purchaseService.user_China && this.readmore && !environment.single) {
      const notify = await this.alertController.create({
        header: this.languageService.label.txt_direct_to_web_purchase_title,
        message: this.languageService.label.txt_direct_to_web_purchase_msg,
        buttons: [
          {
            text: this.languageService.label.txt_direct_to_web_purchase_cta,
            role: 'confirm',
            cssClass: 'secondary',
            handler: () => {
              this.inAppBrowser.create('https://learn.langster.org/login', '_system', 'hidden=yes,location=yes');
            }
          },
          {
            text: this.languageService.label.txt_direct_to_web_purchase_cancel,
            role: 'cancel',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }
        ]
      });
      await notify.present();
      return
    }
    this.navCtrl.navigateForward('/onboarding-shop');
  }

  async toggleLearned(): Promise<void> {
    if (this.isLearned) {
      this.isLearned = false;
      this.storageService.removeLearnedStory(this.story._id);
      this.storyService.updateArticleLearned(this.story, false);
    } else {
      this.isLearned = true;
      this.storageService.addLearnedStory(this.story._id);
      this.storyService.updateArticleLearned(this.story, true);
      const learnedStories = await this.storageService.getLearnedStories();
      if (learnedStories && learnedStories.length == 1) {
        if (this.launchReview.isRatingSupported()) {
          this.launchReview.rating().toPromise().then(res => console.log(res));
        }
      }

      try {
        this.firebaseAnalytics.logEvent(`click_story_is_learned`, {});
        if (this.languageService.origin) {
          this.firebaseAnalytics.logEvent(`click_story_is_learned_${this.languageService.origin}`, {});
          this.appsflyer.logEvent(`click_story_is_learned_${this.languageService.origin}`, {});
        } else {
          const languageFromStorage = await this.languageService.getOriginFromStorage();
          this.firebaseAnalytics.logEvent(`click_story_is_learned_${languageFromStorage}`, {});
          this.appsflyer.logEvent(`click_story_is_learned_${languageFromStorage}`, {});
        }

      } catch (err) {
        console.log("ERROR; ", err);
      }
    }
    this.storageService.learnedStoriesChanged.next({ id: this.story._id, added: this.isLearned });
    this.storyService.updateHomeSlider();
  }

  getisLearned(): boolean {
    if (this.storageService.learnedStories.includes(this.story._id)) return true;
    else return false;
  }

  checkIfStoryLearned(): void {
    if (this.storageService.learnedStories.includes(this.story._id)) this.isLearned = true;
  }

  addWordToTraining(): void {
    let observerCalled = false;
    for (let index = 0; index < this.story.textMapping.length; index++) {
      const element = this.story.textMapping[index];
      if (element.result) {
        if (element.result._id == this.selectedWord._id) {
          element.result.inTraining = !element.result.inTraining;
          if (!observerCalled) {
            this.trainingService.addTrainingObserver.next({ id: element.result?._id, target: element.result.inTraining });
            observerCalled = true;
          }
        }
      }
    }

    const sentenceData = this.storyService.findSentence(this.story.textMapping, this.currentSelectedWordIndex, this.story);

    this.UI_word_is_training = !this.UI_word_is_training;
    this.trainingService.addToStack(this.selectedWord._id, sentenceData.sentence, sentenceData.position, this.story._id, this.story.textMapping[this.currentSelectedWordIndex].result, sentenceData.totalPosition,);
    this.taptic.impact({ style: 'light' });

  }

  playSentence() {
    if (!this.audioReady) return;
    if (!this.story.free) {
      if (!this.purchaseService.userPremium) {
        this.purchaseService.setPurchaseOrigin(PurchaseOrigin.READMORE);
        this.displayShop();
        return;
      }
    }
    if (this.isPlaySentenceActive) {
      this.stopAudioReading();
      this.isPlaySentenceActive = false;
      this.showToolbarStoryAudio();
      return;
    }
    if (this.isStoryAudioActive) {
      this.stopAudioReading();
      this.isStoryAudioActive = false;
    }

    let sMeta;
    let senteneceIndex = -1;

    if (!this.story.sentencesMeta || this.story.sentencesMeta.length == 0) return;
    for (let index = 0; index < this.story.sentencesMeta.length; index++) {
      const element = this.story.sentencesMeta[index];
      if (this.currentSelectedWordIndex >= element.start && this.currentSelectedWordIndex <= element.end) {
        sMeta = element;
        senteneceIndex = index;
        break;
      }
    }

    if (sMeta) {
      this.stopAudioReading();
      this.index = sMeta.start;
      this.audioReading.currentTime = ((this.story.audioMarksWords[sMeta.start] as number - 200) / 1000);
      this.playAudioReading(sMeta, senteneceIndex);
      this.isPlaySentenceActive = true;
      this.sentenceAudio = true;
      this.hideToolbarStoryAudio();
      console.log(((this.story.audioMarksWords[sMeta.start] as number - 200) / 1000))
      console.log(this.audioReading.currentTime);
    }
  }

  showToolbarStoryAudio() {

  }

  hideToolbarStoryAudio() {

  }

  removeOnboardingStoryInstance() {
    // After onboarding there are 2 app-article elements in the dom
    // if 2 remove the first one from the dom 
    const x = document.getElementsByTagName('app-article');
    if (x.length > 1) x[0].remove();
  }

  async unlockStoryForRating() {
    this.ratingService.ratingEntered = true;
    const alert = await this.alertController.create({
      header: this.languageService.label.txt_review_to_unlock_title,
      message: this.languageService.label.txt_review_to_unlock_text,
      buttons: [
        {
          text: this.languageService.label.txt_review_to_unlock_cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: this.languageService.label.txt_review_to_unlock_confirm,
          handler: async () => {

            const loading = await this.loadingController.create({
              message: 'Loading ...',
            });

            loading.present();

            this.launchReview.launch().then(res => {
              loading.dismiss();

              setTimeout(() => {
                this.storageService.setUnlockedStoryByReview(this.story._id);
                this.story.free = true;
              }, 3000)

            }).catch(error => {
              console.log(error);
              loading.dismiss();
            })
          },
        },
      ],
    });
    const alert2 = await this.alertController.create({
      header: this.languageService.label.txt_review_to_unlock_title,
      message: this.languageService.label.txt_review_to_unlock_text_readle,
      buttons: [
        {
          text: this.languageService.label.txt_review_to_unlock_cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: this.languageService.label.txt_review_to_unlock_confirm_readle,
          handler: async () => {

            const loading = await this.loadingController.create({
              message: 'Loading ...',
            });

            loading.present();

            this.launchReview.launch().then(res => {
              loading.dismiss();

              setTimeout(() => {
                this.storageService.setUnlockedStoryByReview(this.story._id);
                this.story.free = true;
              }, 3000)

            }).catch(error => {
              console.log(error);
              loading.dismiss();
            })
          },
        },
      ],
    });

    if (!environment.single) await alert.present();
    else await alert2.present();
  }

  toRatePage() {
    this.ratingService.unlockStory = this.story;
    this.navCtrl.navigateForward('/rate-to-unlock-story');
  }

  async displayMore() {
    const unlockedStoryId = await this.storageService.getUnlockedStoryByReview();
    const unlockedStoryId2 = await this.storageService.getUnlockedStoryByShare();
    if ((!this.purchaseService.sharedUnlock || !this.ratingService.userHasRated) && !this.utilsService.isWebVersion) {
      if (this.platform.is('ios') || (this.platform.is('desktop') && this.platform.is('cordova'))) {//ios app or the desktop app
        if (!environment.single) {
          if (!this.ratingService.userHasRated && this.languageService.label.rateToUnlock_ios && !(this.languageService.label.rateToUnlock_ios == "")) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.label.shareToUnlock_ios && !(this.languageService.label.shareToUnlock_ios == '')) {
            this.shareModal();
            return
          }
        } else {
          if (!this.ratingService.userHasRated && this.languageService.origin == 'en' && this.languageService.label.rateToUnlock_ios_readleEN && !(this.languageService.label.rateToUnlock_ios_readleEN == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'en' && this.languageService.label.shareToUnlock_ios_readleEN && !(this.languageService.label.shareToUnlock_ios_readleEN == '')) {
            this.shareModal();
            return
          }

          if (!this.ratingService.userHasRated && this.languageService.origin == 'fr' && this.languageService.label.rateToUnlock_ios_readleFR && !(this.languageService.label.rateToUnlock_ios_readleFR == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'fr' && this.languageService.label.shareToUnlock_ios_readleFR && !(this.languageService.label.shareToUnlock_ios_readleFR == '')) {
            this.shareModal();
            return
          }

          if (!this.ratingService.userHasRated && this.languageService.origin == 'de' && this.languageService.label.rateToUnlock_ios_readleDE && !(this.languageService.label.rateToUnlock_ios_readleDE == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'de' && this.languageService.label.shareToUnlock_ios_readleDE && !(this.languageService.label.shareToUnlock_ios_readleDE == '')) {
            this.shareModal();
            return
          }


          if (!this.ratingService.userHasRated && this.languageService.origin == 'es' && this.languageService.label.rateToUnlock_ios_readleES && !(this.languageService.label.rateToUnlock_ios_readleES == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'es' && this.languageService.label.shareToUnlock_ios_readleES && !(this.languageService.label.shareToUnlock_ios_readleES == '')) {
            this.shareModal();
            return
          }

          if (!this.ratingService.userHasRated && this.languageService.origin == 'ja' && this.languageService.label.rateToUnlock_ios_readleJA && !(this.languageService.label.rateToUnlock_ios_readleJA == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'ja' && this.languageService.label.shareToUnlock_ios_readleJA && !(this.languageService.label.shareToUnlock_ios_readleJA == '')) {
            this.shareModal();
            return
          }
        }
      } else if (this.platform.is('android')) {
        if (!environment.single) {
          if (!this.ratingService.userHasRated && this.languageService.label.rateToUnlock_android && !(this.languageService.label.rateToUnlock_android == "")) {
            this.toRatePage();
            return
          }
          else if (!this.purchaseService.sharedUnlock && this.languageService.label.shareToUnlock_android && !(this.languageService.label.shareToUnlock_android == '')) {
            this.shareModal();
            return
          }
        } else {

          if (!this.ratingService.userHasRated && this.languageService.origin == 'en' && this.languageService.label.rateToUnlock_android_readleEN && !(this.languageService.label.rateToUnlock_android_readleEN == "")) {
            this.toRatePage();
            return
          }
          else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'en' && this.languageService.label.shareToUnlock_android_readleEN && !(this.languageService.label.shareToUnlock_android_readleEN == '')) {
            this.shareModal();
            return
          }

          if (!this.ratingService.userHasRated && this.languageService.origin == 'fr' && this.languageService.label.rateToUnlock_android_readleFR && !(this.languageService.label.rateToUnlock_android_readleFR == '')) {
            this.toRatePage();
            return
          }
          else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'fr' && this.languageService.label.shareToUnlock_android_readleFR && !(this.languageService.label.shareToUnlock_android_readleFR == '')) {
            this.shareModal();
            return
          }

          if (!this.ratingService.userHasRated && this.languageService.origin == 'de' && this.languageService.label.rateToUnlock_android_readleDE && !(this.languageService.label.rateToUnlock_android_readleDE == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'de' && this.languageService.label.shareToUnlock_android_readleDE && !(this.languageService.label.shareToUnlock_android_readleDE == '')) {
            this.shareModal();
            return
          }

          if (!this.ratingService.userHasRated && this.languageService.origin == 'es' && this.languageService.label.rateToUnlock_android_readleES && !(this.languageService.label.rateToUnlock_android_readleES == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'es' && this.languageService.label.shareToUnlock_android_readleES && !(this.languageService.label.shareToUnlock_android_readleES == '')) {
            this.shareModal();
            return
          }

          if (!this.ratingService.userHasRated && this.languageService.origin == 'ja' && this.languageService.label.rateToUnlock_android_readleJA && !(this.languageService.label.rateToUnlock_android_readleJA == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'ja' && this.languageService.label.shareToUnlock_android_readleJA && !(this.languageService.label.shareToUnlock_android_readleJA == '')) {
            this.shareModal();
            return
          }

          if (!this.ratingService.userHasRated && this.languageService.origin == 'zh-hans' && this.languageService.label.rateToUnlock_android_readleCN && !(this.languageService.label.rateToUnlock_android_readleCN == '')) {
            this.toRatePage();
            return
          } else if (!this.purchaseService.sharedUnlock && this.languageService.origin == 'zh-hans' && this.languageService.label.shareToUnlock_android_readleCN && !(this.languageService.label.shareToUnlock_android_readleCN == '')) {
            this.shareModal();
            return
          }
        }
      }
    }
    if (unlockedStoryId || unlockedStoryId2 || (this.ratingService.userHasRated && this.purchaseService.sharedUnlock) || !this.ratingService.ratingEntered) {
      this.purchaseService.setPurchaseOrigin(PurchaseOrigin.READMORE);
      this.readmore = true;
      this.displayShop();
    }
  }

  share() {
    this.firebaseAnalytics.logEvent('click_share_app_unlock_story', {});
    this.appsflyer.logEvent(`click_share_app_unlock_story`, {});
    let rect = document.getElementsByClassName('cta-read-more')[0].getBoundingClientRect();
    let options, appUrl;
    if (environment.single) {
      switch (this.languageService.origin) {
        case 'de':
          appUrl = "https://readle.onelink.me/Pa93/0r4iiya4"
          break;
        case 'ja':
          appUrl = "https://readle-ja.onelink.me/gWBC/p3cdkdmc"
          break;
        case 'fr':
          appUrl = "https://readle-fr.onelink.me/4h6x/xqhlm3p0"
          break;
        case 'en':
          appUrl = "https://readle-en.onelink.me/35um/jjpl4mhz"
          break;
        case 'es':
          appUrl = "https://readle-es.onelink.me/qJGV/bf05xqa1"
          break;
        case 'zh-hans':
          appUrl = "https://readle-cn.onelink.me/ezMy/noq6yxq9"
          break;
        default:
          appUrl = "https://readle-app.com/"
          break;
      }
      // this is the complete list of currently supported params you can pass to the plugin (all optional)
      options = {
        message: this.languageService.label.txt_purchase_share_text, // not supported on some apps (Facebook, Instagram)
        url: appUrl,
        // files: "assets/icon/favicon-logo.png",
        iPadCoordinates: (rect.width / 2) + "," + rect.top + ",0,0" //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
      };
    } else {
      options = {
        message: this.languageService.label.txt_purchase_share_text, // not supported on some apps (Facebook, Instagram)
        url: 'https://langster.onelink.me/2w7B/xryf1rhn',
        // files: "assets/icon/favicon-logo.png",
        iPadCoordinates: (rect.width / 2) + "," + rect.top + ",0,0" //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
      };
    }

    this.socialSharing.shareWithOptions(options).then((res) => {
      this.purchaseService.sharedUnlock = true;
      setTimeout(() => {
        this.storageService.setUnlockedStoryByShare(this.story._id);
        this.purchaseService.markUserAsSharedUnlock();
        this.story.free = true;
        this.closeShare()
      }, 500)
      console.log("Share completed? " + res.completed); // On Android apps mostly return false even while it's true
      console.log("Shared to app: " + res.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    }).catch((error => {
      console.log("Sharing failed with message: " + error);
    }));

  }

  simulateProgress() {
    let interval;
    const updateTime = 150;
    interval = setInterval(() => {
      let x;
      x = this.progressbarStatus + (Math.floor(Math.random() * 5) + 1);
      if (x > 100) x = 100;
      this.progressbarStatus = x;
      if (this.progressbarStatus >= 100) {
        // dismiss (animate out) the overlay
        timer(300).subscribe(() => {
          document.getElementById('progress-content').classList.add('hide');
          if (!this.isLandscape) document.getElementById('progress-content-review').classList.add('hide');
          document.getElementById('progress-success-content').classList.remove('hide');
          document.getElementById('progress').classList.add('modal');
          timer(300).subscribe(() => {
            document.getElementById('progress-success-content').classList.add('visible');
          })
          clearInterval(interval);
        })
      }
    }, updateTime);
  }

  dissmissProgressOverlay() {
    this.taptic.impact({ style: 'medium' });
    document.getElementById('progress').classList.add('hide');
    document.getElementById('progress-overlay').classList.add('hide');
    if (this.isLandscape) {
      let wordIndex;
      for (let index = 0; index < this.story.textMapping?.length; index++) {
        const element = this.story.textMapping[index];
        if (element.result.category === 'verb' && (element.result.display.length > 4)) {
          wordIndex = index;
          break;
        }
      }
      const egWord: HTMLElement = document.getElementsByClassName('single-word')[wordIndex] as HTMLElement;
      egWord.click();
      this.tutorialStarted = true;
      this.renderOverlayWord();
    }

  }

  swapTitle() {
    this.ngZone.run(() => {
      this.UI_show_translation = !this.UI_show_translation;
    })
  }

  get stepLabelReplacement(): string {

    let label = this.languageService.label.txt_introduction_step_total;

    label = label?.replace("[CurrentStep]", this.tooltipNumber);
    label = label?.replace("[TotalStep]", this.totalSteps);
    return label;
  }

  toggleHighlight(): void {
    this.storage.set('settingHighlight', this.isHighlight);
  }

  initPane() {
    let settings: CupertinoSettings = {
      modules: [BackdropModule],
      backdrop: true,
      fitHeight: true,
      simulateTouch: false,
      showDraggable: false,
      cssClass: 'my-custom-class storySettings',
      bottomClose: true,
      handleKeyboard: false,
      freeMode: false,
      topperOverflow: false,
      parentElement: 'body', // Parent container
      breaks: {
        top: { enabled: true, height: this.platform.is('ios') ? window.innerHeight - (300 * 0.35) : window.innerHeight - (350 * 0.25) },
        middle: { enabled: true, height: (this.platform.is('ios') ? 400 : 430), bounce: false },
        bottom: { enabled: false, height: 80 },
      },
      initialBreak: (this.languageService.origin == 'ja' || this.languageService.origin == 'zh-hans') ? 'top' : 'middle',
      events: {
        onDidDismiss: (ev) => {
          this.settingActive = false;
        }
      }
    };
    this.settingsPane = new CupertinoPane('.cupertino-pane-story-settings', settings);
  }

  async openSettings() {
    await this.settingsPane.present({
      animate: true,
    });
    this.settingActive = true;
  }

  closeSettingsPane() {
    if (this.settingsPane) {
      this.settingsPane.destroy({ animate: true });
      this.settingActive = false;
    }
  }

  getHiragana(word: WordResultMap): string {
    return this.shouldShowHiragana(word) ? word.result.hiragana : '';
  }
  shouldShowHiragana(word: WordResultMap): boolean {
    // Assuming languageService and wordService are available in your component
    // Return true if the conditions match, similar to your *ngIf condition
    return this.languageService.origin == 'ja' && word.result?.hiragana != word.word && this.hiraganaActive;
  }

}
