import { Component } from '@angular/core';
import { LanguageService } from '@services/language-service/language.service';
import { SettingsService } from '@services/settings-service/settings.service';
import { TrainingService } from '@services/training-service/training.service';
import { UtilsService } from '@services/utils-service/utils.service';
import { environment } from 'environments/environment';
import { Storage } from '@ionic/storage';


@Component({
  selector: 'deck-settings',
  templateUrl: './deck-settings.component.html',
  styleUrls: ['./deck-settings.component.scss'],
})
export class DeckSettingsComponent {
  environment = environment
  public generalSetting = true;

  constructor(
    public languageService: LanguageService,
    public trainingService: TrainingService,
    public utilsService: UtilsService,
    private storage: Storage,
    public settingsService: SettingsService,

  ) {
  }

  toggleGeneral() {
    this.generalSetting = true;
  }
  toggleAdvanced() {
    this.generalSetting = false;
  }

  toggleShowAnswer() {
    if (this.settingsService.showAnswer) {
      this.storage.set('showAnswer', false)
      this.settingsService.showAnswer = false;
    } else {
      this.storage.set('showAnswer', true)
      this.settingsService.showAnswer = true;
    }
  }

  toggleSwapSides() {
    if (this.settingsService.swapSidesCard) {
      this.storage.set('swapSidesCard', false)
      this.settingsService.swapSidesCard = false;
    } else {
      this.storage.set('swapSidesCard', true)
      this.settingsService.swapSidesCard = true;
    }
  }

  togglePlayAudio() {
    if (this.settingsService.playAudioAuto) {
      this.storage.set('playAudioAuto', false)
      this.settingsService.playAudioAuto = false;
    } else {
      this.storage.set('playAudioAuto', true)
      this.settingsService.playAudioAuto = true;
    }
  }

  toggleRomaji() {
    if (this.settingsService.showRomajiInTraining) {
      this.storage.set('showRomajiInTraining', false)
      this.settingsService.showRomajiInTraining = false;
    } else {
      this.storage.set('showRomajiInTraining', true)
      this.settingsService.showRomajiInTraining = true;
    }
  }

  toggleHiragana() {
    if (this.settingsService.showHiraganaInTraining) {
      this.storage.set('showHiraganaInTraining', false)
      this.settingsService.showHiraganaInTraining = false;
    } else {
      this.storage.set('showHiraganaInTraining', true)
      this.settingsService.showHiraganaInTraining = true;
    }
  }

  togglePinyin() {
    if (this.settingsService.showPinyinInTraining) {
      this.storage.set('showPinyinInTraining', false)
      this.settingsService.showPinyinInTraining = false;
    } else {
      this.storage.set('showPinyinInTraining', true)
      this.settingsService.showPinyinInTraining = true;
    }
  }

  close(): void {
    this.utilsService.deckSettings = false;
  }
  get SwapSidesLabel(): string {
    if (!this.languageService.label.txt_training_settings_swap_sides) return ''
    let label = this.languageService.label.txt_training_settings_swap_sides;
    let lang = this.languageService.origin;
    if (lang == 'zh-hans') lang = 'zh-Hans';
    if (lang == 'zh-hant') lang = 'zh-Hant';
    let learningLang: string = "txt_language_select_" + lang;
    label = label.replace("<learning language>", this.languageService.label[learningLang]);
    return label;
  }
}
