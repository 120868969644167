import { Component } from '@angular/core';
import { NavController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { UtilsService } from '../../service/utils-service/utils.service';
import { LanguageService } from '../../service/language-service/language.service';
import { TrainingService } from '../../service/training-service/training.service';
import { Storage } from '@ionic/storage';
import { TabsService } from '@services/tabs-service/tabs.service';
import { environment } from 'environments/environment';
import { AlertController } from '@ionic/angular';
import { AuthService } from '@services/auth-service/auth.service';
import { LoginPage } from '@pages/login/login.page';
import { TrainingDifficulty } from '@interfaces/training';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { BackdropModule } from 'cupertino-pane/dist/modules';
import { timer } from 'rxjs';
import { SettingsService } from '@services/settings-service/settings.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { StorageService } from '@services/storage-service/storage.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import OneSignal from 'onesignal-cordova-plugin';
@Component({
  selector: 'app-training',
  templateUrl: './training.page.html',
  styleUrls: ['./training.page.scss'],
})
export class TrainingPage {
  environment = environment;

  constructor(
    public navCtrl: NavController,
    private utilsService: UtilsService,
    public loadingController: LoadingController,
    public languageService: LanguageService,
    public trainingService: TrainingService,
    private storage: Storage,
    public modalController: ModalController,
    private tabsService: TabsService,
    private authService: AuthService,
    public alertController: AlertController,
    public utilService: UtilsService,
    private storageService: StorageService,
    private purchaseService: PurchaseService,
    private platform: Platform,
    private firebaseAnalytics: FirebaseAnalytics,
    private settingsService: SettingsService,
  ) {
    this.storage.get('playAudioAuto').then(val => {
      if (val == undefined || val == null) val = false;
      this.settingsService.playAudioAuto = val;
    });
    this.storage.get('showAnswer').then(val => {
      if (val == undefined || val == null) val = false;
      this.settingsService.showAnswer = val;
    });
    this.storage.get('swapSidesCard').then(val => {
      if (val == undefined || val == null) val = true;
      this.settingsService.swapSidesCard = val;
    });
    if (this.languageService.origin == 'ja') {
      this.storage.get('showRomajiInTraining').then(val => {
        if (val == undefined || val == null) val = true;
        this.settingsService.showRomaji = val;
      });
      this.storage.get('showHiraganaInTraining').then(val => {
        if (val == undefined || val == null) val = true;
        this.settingsService.showHiragana = val;
      });
    }
    if (this.languageService.origin == 'zh-hans') {
      this.storage.get('showPinyinInTraining').then(val => {
        if (val == undefined || val == null) val = true;
        this.settingsService.showPinyin = val;
      });
    }
  }

  askClear = false;
  isMobile = this.utilsService.isMobile();
  pane;
  async ionViewWillEnter() {
    this.trainingService.getStack();
    if (this.authService.user) {
      this.trainingService.summaryTimeStamp = null;//bypass the cooldown to fetch newest summary
      this.trainingService.getTrainingSummary()
      if (this.trainingService.deckID) this.trainingService.getTrainingDeckToday();
    }
    this.tabsService.updateTabUi('training');
    this.utilsService.routingName = 'training';

    // if (this.authService.user) {
    //   this.trainingService.getTrainingDeckAll()
    // }
    window.addEventListener('keyboardWillShow', () => {
      if (this.pane) {
        this.pane.moveToBreak('top');
      }
    })

    window.addEventListener('keyboardWillHide', () => {
      if (this.pane) {
        this.pane.moveToBreak('middle');
      }
    })
  }


  ionViewDidEnter(): void {
    this.storage.get('openedTraining').then(res => {
      if (!res) {
        setTimeout(() => {
          this.trainingService.showTrainingHelp = true;
        }, 500);
      }
      this.storage.set('openedTraining', true);
    });

    this.storage.get('SRSTraining').then(res => {
      if (!this.authService.user) return;
      const langData = this.authService.user.data.find(el => el.language == this.languageService.origin);
      if ((!res || res == null) && (this.authService.user.trainingMigrated || langData.training?.length > 0)) {
        setTimeout(() => {
          const checkAndExecute = () => {
            if (!this.trainingService.showTrainingHelp) {
              this.utilsService.newTraining = true;
              this.storage.set('SRSTraining', true);
            } else {
              setTimeout(checkAndExecute, 500); // Check again after 1 second
            }
          };
          checkAndExecute();
        }, 1000);
      }
    });
  }

  async goToExercise(): Promise<void> {
    if (!this.authService.user) {
      this.alertController.create({
        header: this.languageService.label.txt_training_modal_log_in,
        buttons: [
          {
            text: this.languageService.label.txt_pwa_modal_later,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {

            }
          },
          {
            text: this.languageService.label.txt_pwa_modal_okay,
            role: 'confirm',

            handler: () => {
              this.showLoginModal()
            }
          }

        ]
      }).then(alert => {
        alert.present();
      });

    } else {
      if (this.trainingService.deckSummary.due > 0) {
        if (this.trainingService.DeckDue.length == 0) {
          this.trainingService.getTrainingDeckToday().then(() => {
            console.log("DeckDue", this.trainingService.DeckDue)
            if (this.trainingService.DeckDue.length > 0) this.navCtrl.navigateForward('/training/training-exercise', { animated: this.isMobile });
          });
        }
        const intervalId = setInterval(() => {
          console.log("loadingDue", this.trainingService.loadingDue)
          if (!this.trainingService.loadingDue) {
            this.purchaseService.loading = false;
            this.navCtrl.navigateForward('/training/training-exercise', { animated: this.isMobile });
            clearInterval(intervalId); // Stop checking once the condition is met
          }
        }, 300);
        this.purchaseService.loading = this.trainingService.loadingDue;
      } else if (this.trainingService.deckSummary.due == 0) {
        console.log(this.trainingService.deckSummary)
        return;
      }
    }
  }


  async showLoginModal() {
    console.log(this.utilService.isWebVersion)
    if (this.utilService.isWebVersion) {

      this.modalController.create({
        component: LoginPage,
        cssClass: 'login-modal',
        componentProps: {
          'disableRegister': false,
          'isModal': true
        }
      }).then((modal) => {
        modal.present();
      });

      return;
    }


    let settings: CupertinoSettings = {
      modules: [BackdropModule],
      backdrop: true,
      fitHeight: true,
      showDraggable: false,
      bottomClose: true,
      freeMode: false,
      handleKeyboard: false,
      topperOverflow: false,
      parentElement: 'body', // Parent container
      breaks: {
        top: { enabled: true, height: this.platform.is('ios') ? window.innerHeight - (135 * 0.35) : window.innerHeight - (250 * 0.35) },
        // middle: { enabled: true, height: 380, bounce: false },
        middle: { enabled: true, height: 400, bounce: false },
        bottom: { enabled: false, height: 80 },
      },
    };
    let myPane = new CupertinoPane('.cupertino-pane', settings);
    this.pane = await myPane.present({
      animate: true,
    });
    this.pane.on('onDidDismiss', (ev) => {
      this.settingsService.isLoginWithMail = false;
    });
  }

  closeLoginModal() {
    this.trainingService.summaryTimeStamp = null;//bypass the cooldown to fetch newest summary
    this.trainingService.getTrainingSummary();
    this.pane.hide();
    timer(1500).subscribe(() => {
      this.pane.destroy();
    })
  }

  async successLogin(data: any) {

    // Switch RevenueCat User & load purchases of new user
    this.purchaseService.switchUserAccount().then(() => {
      this.storageService.sendRevenueCatAttributes();
    }).catch(error => {
      console.error("There was an error configuring purchasing", error);
    })
    const user = data.user;

    if (!user.userId) {
      await this.authService.storeUserData({ userId: this.authService.anonymousUserId });
      const u = user;
      u.userId = this.authService.anonymousUserId;
      user.userId = u.userId;
      this.authService.setUser(u);
    }

    if (this.platform.is('cordova')) {
      if (user.email) OneSignal.User.addEmail(user.email);
      if (user.userId) OneSignal.login(user.userId);
    }

    if (user) {

      if (!user.selectedLanguage && !user.selectedLevel) {
        const selectedLanguage = this.languageService.origin;
        const selectedLevel = this.storageService.userLevel;
        this.authService.storeUserData({ selectedLanguage, selectedLevel }).then((res => {
          console.log("Stored local data into database");
        }), (err) => {
          console.log("Error storing local data into database", err);
        });
      } else {

        const selectedLanguage = this.languageService.origin;
        const selectedLevel = this.storageService.userLevel;

        if (selectedLanguage != user.selectedLanguage) {
          const loading = await this.loadingController.create({
            spinner: "lines-small",
            duration: 3000,
            message: this.languageService.label.change_lang_loading_message,
            translucent: true,
            cssClass: 'custom-class custom-loading',
            backdropDismiss: false,
          });
          await loading.present();
          this.languageService.setOrigin(user.selectedLanguage, false);
        }

        if (selectedLevel != user.selectedLevel) {
          this.storageService.storeUserLevel(user.selectedLevel, false);
          this.firebaseAnalytics.setUserProperty("level", user.selectedLevel);
        }

        try {
          OneSignal.User.addTag('language', user.selectedLanguage);
          OneSignal.User.addTag('level', user.selectedLevel);
        } catch (error) {
          console.log("Cordova possibly not available")
        }
      }

      // Load complete user data after successful login
      this.authService.loadCompleteUserData(this.languageService.origin).then(async user => {
        this.authService.setUser(user);
        this.purchaseService.checkStripeSubscription(user);

        const convertedTrainingData = await this.storageService.getConvertedTrainingData();
        if (convertedTrainingData.length > 0) {
          if (!user.data || user.data?.length == 0 || (user.data && (!user.data[0].training || user.data[0].training?.length == 0))) {
            if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
          }
        }

        if (user.data?.length == 0 && (this.storageService.favorites?.length > 0 || this.storageService.learnedStories?.length > 0)) {
          if (!this.storageService.importLoading) await this.storageService.importTrainingDataToServer();
        }
        this.authService.storeUserData({
          selectedLanguage: this.languageService.origin,
          selectedLevel: this.storageService.userLevel,
          userLanguage: this.languageService.target,
        });
        this.storageService.initUserDataFromServer();
      })

    }
    this.firebaseAnalytics.setUserProperty("loggedin", 'true');
    this.authService.trackAccountCreated();
    setTimeout(async () => {
      if (this.authService.newAccount) {
        console.log("apple settings ", this.authService.newAccount, this.authService.signupMethod, JSON.stringify(this.authService.user))
        if (this.authService.signupMethod == 'apple-sso' && this.authService.user.email.includes('privaterelay')) this.navCtrl.navigateRoot('/complete-profile');
      } this.authService.newAccount = false;
    }, 500);
    if (this.authService.newAccount) {
      setTimeout(() => {
        this.closeLoginModal
      }, 8000);

    } else this.closeLoginModal();
  }


  goToAllKeywords(): void {
    this.navCtrl.navigateForward('/training/training-keywords', { animated: this.isMobile });
  }

  showOverviewEasy() {
    this.trainingService.overviewTitle = this.languageService.label.txt_training_page_easy_title;
    this.trainingService.overviewDifficulty = 'easy';
    this.loadWordData();
    this.goToOverview();
  }

  showOverviewOkay() {
    this.trainingService.overviewTitle = this.languageService.label.txt_training_page_good_title_new;
    this.trainingService.overviewDifficulty = 'good';
    this.loadWordData();
    this.goToOverview();
  }

  showOverviewHard() {
    this.trainingService.overviewTitle = this.languageService.label.txt_training_page_hard_title;
    this.trainingService.overviewDifficulty = 'hard';
    this.loadWordData();
    this.goToOverview();
  }

  showOverviewAgain() {
    this.trainingService.overviewTitle = this.languageService.label.txt_training_page_again_title;
    this.trainingService.overviewDifficulty = 'again';
    this.loadWordData();
    this.goToOverview();
  }


  goToOverview(): void {
    this.navCtrl.navigateForward('/training/training-overview', { animated: this.isMobile });
  }


  async loadWordData(): Promise<any> {

    return new Promise(async (resolve, reject) => {
      this.trainingService.overviewWords = [];
      this.trainingService.page = 1;
      this.trainingService.lastPage = false;
      await this.trainingService.getTrainingDeckByCategory(this.trainingService.overviewDifficulty)
      console.log("loading stackOverview:", this.trainingService.overviewDifficulty, this.trainingService.deckOverview)
      return resolve({});
    })
  }

  get countHard(): number {
    if (!this.authService.user) {
      if (this.trainingService.stack.words?.length == 0) return 0
      return this.trainingService.stack.words.filter((word) => word.difficulty == TrainingDifficulty.good).length;
    }
    if (!this.trainingService.deckSummary) return 0
    else return this.trainingService.deckSummary.hard
  }

  get countAgain(): number {
    if (!this.authService.user) {
      if (this.trainingService.stack.words?.length == 0) return 0
      return this.trainingService.stack.words.filter((word) => word.difficulty == TrainingDifficulty.hard).length;
    }
    if (!this.trainingService.deckSummary) return 0
    else return this.trainingService.deckSummary.again
  }

  get countEasy(): number {
    if (!this.authService.user) {
      if (this.trainingService.stack.words?.length == 0) return 0
      return this.trainingService.stack.words.filter((word) => word.difficulty == TrainingDifficulty.done).length;
    }
    if (!this.trainingService.deckSummary) return 0
    else return this.trainingService.deckSummary.easy
  }

  get countGood(): number {
    if (!this.authService.user) {
      if (this.trainingService.stack.words?.length == 0) return 0
      return this.trainingService.stack.words.filter((word) => word.difficulty == TrainingDifficulty.easy).length;
    }
    if (!this.trainingService.deckSummary) return 0
    else return this.trainingService.deckSummary.good
  }

  get countNew(): number {
    if (!this.authService.user) return this.trainingService.stackToday.length

    if (!this.trainingService.deckSummary) return 0
    else return this.trainingService.deckSummary.new
  }
  get countDue(): number {
    if (!this.authService.user) return this.trainingService.stackToday.length
    if (!this.trainingService.deckSummary) return 0
    else return this.trainingService.deckSummary.due
  }

}
